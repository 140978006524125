<div *ngFor="let result of cookieToSave"
     class="recent-frame">
  <div class="recent-item">
    <div class="recent-info">
      <div class="result-text-frame">
        <p>{{ result }}</p>
      </div>
    </div>
    <button class="add-button"
    (click)="handleCookieSelected(result);">
      <fly-icon [icon]="icons.plus"></fly-icon>
    </button>
  </div>
</div>