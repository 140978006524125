/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The list of filing messages (FPL, ACK, REJ, etc.) exchanged between FlightPlanFiling and any filing providers (NADIN, LEIDOS, etc.) for the currently filed flight plan.
 */
export interface Message { 
    /**
     * Primary key
     */
    id?: string;
    /**
     * Flight plan UUID this route point belongs to
     */
    flightPlanPrimaryKeyId?: string;
    /**
     *  References the \"id\" field of an issued message that this message is in response of
     */
    issuedMessageId?: string;
    /**
     * The date the flight plan was deleted
     */
    deletedAt?: string;
    /**
     * The date the flight plan was created
     */
    createdAt?: string;
    /**
     * The date the flight plan was last updated
     */
    updatedAt?: string;
    /**
     * If this is populated, it means this is a message that was sent to providers
     */
    issueTime?: string;
    /**
     * If this is populated, it means this is a message that was received from providers
     */
    receiveTime?: string;
    /**
     * The type of response from the filing provider.
     */
    responseType?: MessageResponseType;
    /**
     * Enum name of the provider this message relates to
     */
    providerName?: MessageProviderName;
    /**
     * The message header.
     */
    header?: string;
    /**
     * The message content.
     */
    message?: string;
    /**
     * A description provided by the filing provider, typically detailing the reasoning for a response type.
     */
    responseDescription?: string;
    /**
     * 3-character flight plan related message type.
     */
    item3MessageType?: MessageItem3MessageType;
    /**
     * The FPF supplied message number (optional)
     */
    item3MessageNumber?: string;
    /**
     * TODO
     */
    item7AircraftTail?: string;
    /**
     * TODO
     */
    item8FlightRules?: string;
    /**
     * TODO
     */
    item8FlightType?: string;
    /**
     * TODO
     */
    item9AircraftCount?: string;
    /**
     * TODO
     */
    item9AircraftType?: string;
    /**
     * TODO
     */
    item9AircraftWakeTurbCat?: string;
    /**
     * TODO
     */
    item10AircraftEquipment?: string;
    /**
     * TODO
     */
    item10AircraftSurveillance?: string;
    /**
     * TODO
     */
    item13Departure?: string;
    /**
     * TODO
     */
    item13Etd?: string;
    /**
     * TODO
     */
    item15CruisingSpeed?: string;
    /**
     * TODO
     */
    item15CruisingLevel?: string;
    /**
     * TODO
     */
    item15Route?: string;
    /**
     * TODO
     */
    item16Destination?: string;
    /**
     * TODO
     */
    item16Eet?: string;
    /**
     * TODO
     */
    item16Alternates?: string;
    /**
     * The 4-letters ICAO code of the arrival aerodrome.
     */
    item17ArrivalAerodromeIcao?: string;
    /**
     * 4 numerics providing the time of arrival.
     */
    item17TimeOfArrival?: string;
    /**
     * Custom name of the arrival aerodrome.
     */
    item17ArrivalAerodromeName?: string;
    /**
     * Additional FPL information.
     */
    item18OtherInfo?: string;
    /**
     * Supplemental information (for SPL only).
     */
    item19SupplementalInfo?: string;
    /**
     * Hyphen delimited list.
     */
    item22Amendment?: string;
    /**
     * Space-delimited list of AFTN addresses this message was sent to.
     */
    aftnAddresses?: string;
    /**
     * The origin AFTN address where this message was sent from.
     */
    originAftnAddress?: string;
    /**
     * The response input message included with a rejection
     */
    responseInputMessage?: string;
    /**
     * The origin ARTCC of the message
     */
    responseArtcc?: string;
    /**
     * A JSON text blob holding error data related to this message. Typically for rejections.
     */
    filingErrorsJson?: string;
    /**
     * Whether or not this is a message to be issued instead of a received message.
     */
    isIssuedMessage?: boolean;
    /**
     * The ADB3 version this message was created with.
     */
    adb3CycleVersion?: string;
}
export enum MessageResponseType {
    ACK = 'ACK',
    REJ = 'REJ',
    RQP = 'RQP',
    RQS = 'RQS',
    ACC = 'ACC'
};
export enum MessageProviderName {
    NADIN = 'NADIN',
    LEIDOS = 'LEIDOS',
    EFPL = 'EFPL',
    ATRT = 'ATRT'
};
export enum MessageItem3MessageType {
    FPL = 'FPL',
    SPL = 'SPL',
    DLA = 'DLA',
    CHG = 'CHG',
    CNL = 'CNL',
    DEP = 'DEP',
    ARR = 'ARR',
    RQP = 'RQP',
    RQS = 'RQS'
};



