/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RouteLegNavData } from './route-leg-nav-data.model';
import { RouteLegPerfData } from './route-leg-perf-data.model';
import { Frequency } from './frequency.model';


/**
 * List of child legs associated with this Route Leg. Child legs only apply to legs that make up an Airway, Procedure, or an Approach. REQUESTS - For Airways, only one child is expected, which marks the RouteLeg that is the end of the airway.  For Departure procedures of an airport, there are exactly 3 children: The first marks the procedure name, the second is the runway transition, and the 3rd is the common/enroute Transition.  For arrival procedures, the format is the same, except runway  and common/enroute are reversed. RESPONSES - For both Airways and Procedures, child legs of a Route Leg consist of all intermediate waypoints that make up the airway or procedure.  Each leg is a leaf, and must not have any child legs.
 */
export interface RouteComputedLeg { 
    /**
     * ICAO or other type of waypoint identifier. When the Location Type is FIX_RADIAL_CLASS, the identifier is used as the reference point from which the bearing angle and the distance define the location (Lat/Lon) of the waypoint for this RouteLeg
     */
    identifier: string;
    /**
     * 2 Character Country Code
     */
    countryCode?: string;
    /**
     * country description
     */
    countryDesc?: string;
    /**
     * String representation of the route leg. Can be identifier, or procedures/airways with a dot notation
     */
    displayString?: string;
    /**
     * one of the different types of waypoint AIRPORT, INTERSECTION, VOR, AIRWAY, etc
     */
    locationType?: RouteComputedLegLocationType;
    /**
     * Latitude in degrees
     */
    lat?: number;
    /**
     * Longitude in degrees
     */
    lon?: number;
    /**
     * Unique Identifier for the route Leg
     */
    routeLegUUID?: string;
    /**
     * altitude in feet. For airports, this represents the Above Sea Level altitude at the airport
     */
    altitude?: number;
    /**
     * second altitude value in feet, used only when type is BETWEEN
     */
    secondAltitude?: number;
    /**
     * UTC offset hours
     */
    utcOffsetHours?: number;
    /**
     * Distance from parent Waypoint object to child Waypoint object in nautical miles. This is Required as an input when using a LocationType of FIX_RADIAL_CLASS.  As an input the distance represents a distance from the FIX specified as the Identifier in this RouteLeg
     */
    distance?: number;
    /**
     * The angle is supposed to represent is the bearing from the user input waypoint and a given exit airway. (0-360 degrees).  This is also a Required input when using LocationType = FIX_RADIAL_CLASS.  As an input, this represents the bearing angle off true North from the FIX specified as the Identifier in this RouteLeg
     */
    angle?: number;
    navData?: RouteLegNavData;
    perfData?: RouteLegPerfData;
    frequency?: Frequency;
    /**
     * Display name for this leg
     */
    displayName?: string;
    /**
     * Morse code for the identifier of this leg
     */
    morseCode?: string;
    /**
     * City name of the waypoint
     */
    city?: string;
    /**
     * State abbreviation of the waypoint. Only applicable for US and Canada locations
     */
    state?: string;
    /**
     * VHF navigational aid type
     */
    navaidType?: RouteComputedLegNavaidType;
    /**
     * VOR waypoint class type abbreviation. Only populated for VOR location type
     */
    vorClassAbbr?: string;
    /**
     * Magnetic variation in degree. Only populated for VOR or NDB location type
     */
    magVar?: number;
    /**
     * NDB marker flag
     */
    ndbMarker?: RouteComputedLegNdbMarker;
    rnav?: boolean;
    lowEnrouteAirway?: boolean;
    highEnrouteAirway?: boolean;
}
export enum RouteComputedLegLocationType {
    AIRPORT = 'AIRPORT',
    INTERSECTION = 'INTERSECTION',
    NDB = 'NDB',
    VOR = 'VOR',
    USER_WAYPOINT = 'USER_WAYPOINT',
    RUNWAY = 'RUNWAY',
    AIRPORT_INTERSECTION = 'AIRPORT_INTERSECTION',
    AIRPORT_NDB = 'AIRPORT_NDB',
    AIRPORT_ILS = 'AIRPORT_ILS',
    GLS_MARKER = 'GLS_MARKER',
    UNIDENTIFIED = 'UNIDENTIFIED',
    INVALID = 'INVALID',
    LOCALIZER_MARKER = 'LOCALIZER_MARKER',
    CHART_OBSTACLE = 'CHART_OBSTACLE',
    CHART_TERRAIN = 'CHART_TERRAIN',
    VOR_CLASS = 'VOR_CLASS',
    FIX_REFERENCE_CLASS = 'FIX_REFERENCE_CLASS',
    ROUTE_WAYPOINT = 'ROUTE_WAYPOINT',
    LAT_LON = 'LAT_LON',
    ALONG_TRACK = 'ALONG_TRACK',
    PARALLEL_TRACK = 'PARALLEL_TRACK',
    VNAV_WAYPOINT = 'VNAV_WAYPOINT',
    DEPARTURE_PROCEDURE = 'DEPARTURE_PROCEDURE',
    ARRIVAL_PROCEDURE = 'ARRIVAL_PROCEDURE',
    ENROUTE_TRANSITION = 'ENROUTE_TRANSITION',
    APPROACH_PROCEDURE = 'APPROACH_PROCEDURE',
    AIRWAY = 'AIRWAY',
    AIRWAY_ENTRY = 'AIRWAY_ENTRY',
    AIRWAY_EXIT = 'AIRWAY_EXIT',
    VRP = 'VRP',
    EMPTY = ''
};
export enum RouteComputedLegNavaidType {
    VOR_DEFAULT = 'VOR_DEFAULT',
    VOR_ONLY = 'VOR_ONLY',
    DME_ONLY = 'DME_ONLY',
    TACAN_ONLY = 'TACAN_ONLY',
    VOR_DME = 'VOR_DME',
    VOR_TAC = 'VOR_TAC',
    ILS_DME = 'ILS_DME',
    VOR_EXPAND = 'VOR_EXPAND'
};
export enum RouteComputedLegNdbMarker {
    NONE = 'NONE',
    INNER = 'INNER',
    MID = 'MID',
    OUTER = 'OUTER'
};



