import { HttpClient } from '@angular/common/http';
import { inject, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map, Observable } from 'rxjs';

/**
   A pipe which forwards a URL through the HttpClient, in order to provide
   the necessary headers on the request for that resource.
*/
@Pipe({
  name: 'flySecure'
})
export class SecurePipe implements PipeTransform {
  private readonly httpClient = inject(HttpClient);
  private readonly domSanitizer = inject(DomSanitizer);

  transform(url: string): Observable<string | null> {
    return this.httpClient
      .get(url, { responseType: 'blob' })
      .pipe(map((data) => this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL,
        this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(data)))));
  }
}
