/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdminPdcFlightSearchParams { 
    customerGuid?: string;
    aircraftTail?: string;
    departureAfter?: string;
    departureBefore?: string;
    includeDeleted?: boolean;
    flightResultSetLimit?: number;
    offsetIndexForDataServiceSearchRoutesCall?: number;
    pdcAcceptanceStatus?: AdminPdcFlightSearchParamsPdcAcceptanceStatus;
}
export enum AdminPdcFlightSearchParamsPdcAcceptanceStatus {
    ACCEPTED = 'ACCEPTED',
    NOT_ACCEPTED = 'NOT_ACCEPTED'
};



