import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { LocalStorageKey, LocalStorageService, OAuthTokenResponse, TypeFromCreatePayload } from '@garmin-avcloud/avcloud-web-utils';
import { SettingsCrewPilotModel } from '@shared/models/settings/crew/settings-crew-pilot.model';
import { SettingsWaypointsWaypointModel } from '@shared/models/settings/waypoints/settings-waypoints-waypoint.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FlightOrchestratorUserSettingsService {
  customerId: string | null;

  SERVICE_URL: string = `${environment.garmin.aviation.servicesHost}/flightOrchestratorService/v1`;

  constructor(
    private readonly http: HttpClient,
    private readonly localStorageService: LocalStorageService
  ) {}

  // ** CREW **

  getCrew(): Observable<SettingsCrewPilotModel[] | null> {
    this.getCustomerId();
    return this.http.get<SettingsCrewPilotModel[]>(`${this.SERVICE_URL}/users/${this.customerId}/pilots`);
  }

  postCrew(crew: SettingsCrewPilotModel): Observable<TypeFromCreatePayload<SettingsCrewPilotModel>> {
    this.getCustomerId();
    crew.filingUserName = null;
    return this.http.post<SettingsCrewPilotModel>(`${this.SERVICE_URL}/users/${this.customerId}/pilots`, crew);
  }

  putCrew(crew: SettingsCrewPilotModel): Observable<SettingsCrewPilotModel> {
    this.getCustomerId();
    return this.http.put<SettingsCrewPilotModel>(`${this.SERVICE_URL}/users/${this.customerId}/pilots`, crew);
  }

  deleteCrew(crewIds: string[]): Observable<null> {
    this.getCustomerId();
    return this.http.delete<null>(`${this.SERVICE_URL}/users/${this.customerId}/pilots?pilot-ids=${crewIds}`);
  }

  // ** WAYPOINTS **

  getWaypoints(): Observable<SettingsWaypointsWaypointModel[] | null> {
    this.getCustomerId();
    return this.http.get<SettingsWaypointsWaypointModel[]>(`${this.SERVICE_URL}/users/${this.customerId}/waypoints`);
  }

  getWaypointByName(name: string): Observable<SettingsWaypointsWaypointModel[] | null> {
    this.getCustomerId();
    return this.http.get<SettingsWaypointsWaypointModel[]>(`${this.SERVICE_URL}/users/${this.customerId}/waypoints`, { params: { 'name-contains': name } });
  }

  postWaypoints(waypoints: SettingsWaypointsWaypointModel): Observable<SettingsWaypointsWaypointModel> {
    this.getCustomerId();
    return this.http.post<SettingsWaypointsWaypointModel>(`${this.SERVICE_URL}/users/${this.customerId}/waypoints`, waypoints);
  }

  putWaypoints(waypoints: SettingsWaypointsWaypointModel): Observable<SettingsWaypointsWaypointModel> {
    this.getCustomerId();
    return this.http.put<SettingsWaypointsWaypointModel>(`${this.SERVICE_URL}/users/${this.customerId}/waypoints/${waypoints.id}`, waypoints);
  }

  deleteWaypoints(waypointIds: string[]): Observable<null> {
    this.getCustomerId();
    return this.http.delete<null>(`${this.SERVICE_URL}/users/${this.customerId}/waypoints?waypoint-ids=${waypointIds}`);
  }

  getCustomerId(): void {
    if (this.customerId == null) {
      this.customerId = this.localStorageService.get<OAuthTokenResponse>(LocalStorageKey.OAuthTokenResponse)?.customerId ?? null;
    }
  }
}
