import {  Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Icons, FlightIcons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { RouteComputedLeg, RouteLeg } from '@generated/flight-route-service';

@Component({
  selector: 'pilot-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit {
  @Input() initialView: TemplateRef<any>;
  @Input() searchOrigin: RouteLeg | RouteComputedLeg | undefined;
  @Input() viewMode: boolean = false;
  @Output() readonly dismiss: EventEmitter<void> = new EventEmitter();
  @Output() readonly searchEvent: EventEmitter<RouteComputedLeg> = new EventEmitter();

  currentTab = 0;
  searchTerm: string | undefined;

  readonly tabs = [
    {
      label: 'Search',
      disabled: false
    },
    {
      label: 'Recent',
      disabled: false
    }
  ];

  readonly icons = {
    plus: Icons.Plus,
    search: Icons.Search,
    close: Icons.Close,
    airportIcon: FlightIcons.Airports.Towered
  };

  constructor(readonly fb: FormBuilder) {}

  ngOnInit(): void {
    this.currentTab = 0;
  }

  dismissal(): void {
    this.dismiss.emit();
  }

  handleRecentSelected(selectedString: string): void {
    this.currentTab = 0;
    this.searchTerm = selectedString;
  }

  handleTokenSelected(token: RouteComputedLeg): void {
    this.searchEvent.emit(token);
  }
}
