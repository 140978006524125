export const STORAGE_NAME = 'RECENT_WAYPOINTS'; // Keep track of airports and other waypoints.

export const AIRPORT_SIZE_LIMIT = 20;
export const WAYPOINT_SIZE_LIMIT = 40;

export interface StoredWaypoint extends RecentWaypoint {
  time: number; // time this waypoint was added
}

export interface RecentWaypoint {
  identifier: string;
  countryCode?: string; // the country code of the waypoint; not necessary for airports
  type: string; // the locationType of the waypoint
  icon?: string; // optional icon string (for b64 encoded icons)
  navaidType?: string; // optional navaid type to distinguish between different types of 'VOR' waypoints
}
