import { DestroyRef, Injectable, effect, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  AuthService,
  CustomerAccount,
  CustomerFreeformSetting,
  CustomerFreeformSettingService,
  CustomerService,
  SettingService,
  SettingType
} from '@garmin-avcloud/avcloud-web-utils';
import { catchError, forkJoin, of, switchMap, takeWhile } from 'rxjs';

export interface FavoriteWaypoint {
  id?: string; // identifier
  cc?: string; // country code
}

const FAVORITE_WPT_DELIMITER = '/';
const SETTING_CATEGORY = 'FAVORITES';

@Injectable({
  providedIn: 'root'
})
export class FavoriteWaypointService {

  private readonly _favoriteWaypoints = signal<CustomerFreeformSetting[]>([]);
  readonly favoriteWaypoints = this._favoriteWaypoints.asReadonly();

  private favoriteWaypointsSettingUuid?: string;
  private customerGuid?: string;

  private readonly authService = inject(AuthService);
  private readonly customerFreeformSettingService = inject(CustomerFreeformSettingService);
  private readonly customerService = inject(CustomerService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly settingService = inject(SettingService);

  readonly isAuthenticated = toSignal(this.authService.isAuthenticated(), { initialValue: false });

  constructor() {
    effect(() => {
      if (this.isAuthenticated()) {
        this.load();
      }
    });

  }

  addFavorite(waypoint: FavoriteWaypoint): void {
    const setting = this.createSetting(waypoint);
    this.internalAdd(setting);
  }

  getFavorites(): FavoriteWaypoint[] {
    const results: FavoriteWaypoint[] = [];
    for (const setting of this._favoriteWaypoints()) {
      results.push(this.parse(setting.value));
    }
    return results;
  }

  isFavorite(waypoint: FavoriteWaypoint): boolean {
    if (this.customerGuid == null || this.favoriteWaypointsSettingUuid == null) {
      return false;
    }
    return this._favoriteWaypoints().some((favorite) => favorite.value === this.stringify(waypoint));
  }

  removeFavorite(waypoint: FavoriteWaypoint): void {
    const setting = this._favoriteWaypoints().find((s) => s.value === this.stringify(waypoint));
    if (setting != null) this.internalRemove(setting);
  }

  private createSetting(waypoint: FavoriteWaypoint): CustomerFreeformSetting {
    if (this.customerGuid == null || this.favoriteWaypointsSettingUuid == null) {
      throw new Error('favorites were not loaded');
    }
    return {
      customerGuid: this.customerGuid,
      settingUuid: this.favoriteWaypointsSettingUuid,
      value: this.stringify(waypoint)
    } as CustomerFreeformSetting; // FIXME: this cast is to get around the fact that `uuid` is not optional, which it should be
  }

  private internalAdd(setting: CustomerFreeformSetting): void {
    this.customerFreeformSettingService.create(setting)
      .pipe(
        catchError((e) => {
          console.error(e);
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((newFavorite) => {
        if (newFavorite != null) {
          this._favoriteWaypoints.update((favorites) => {
            favorites.push(newFavorite);
            return [...favorites];
          });
        }
      });
  }

  private internalRemove(setting: CustomerFreeformSetting): void {
    this.customerFreeformSettingService.delete(setting.uuid)
      .pipe(
        switchMap(() => of(true)),
        catchError((e) => {
          console.error(e);
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((resp) => {
        if (resp != null) {
          this._favoriteWaypoints.update((favorites) => {
            const index = favorites.indexOf(setting);
            if (index >= 0) {
              favorites.splice(index, 1);
            }
            return [...favorites];
          });
        }
      });
  }

  private load(): void {
    this.customerService.getCustomerAccount()
      .pipe(
        takeWhile((customerAccount): customerAccount is CustomerAccount => customerAccount != null),
        switchMap((customerAccount) => {
          this.customerGuid = customerAccount.id;
          return forkJoin([
            this.settingService.getSettingSummaries(SETTING_CATEGORY),
            this.customerFreeformSettingService.getAll(this.customerGuid)
          ]);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([summaries, freeFormSettings]) => {
        this.favoriteWaypointsSettingUuid = summaries.find((summary) => summary.setting.type === SettingType.SETTING_VALUE_WAYPOINT_MULTI)?.setting.uuid;
        this._favoriteWaypoints.set(freeFormSettings.filter((setting) => setting.settingUuid === this.favoriteWaypointsSettingUuid));
      });
  }

  private parse(text: string): FavoriteWaypoint {
    const values = text.split(FAVORITE_WPT_DELIMITER);
    return {
      id: values[0],
      cc: values[1]
    };
  }

  private stringify(waypoint: FavoriteWaypoint): string {
    return [
      waypoint.id,
      waypoint.cc
    ].join(FAVORITE_WPT_DELIMITER);
  }

}
