<fly-modal
  #modal
  [modalType]="ModalType.Custom">
  <div
    class="modal-title h6"
    slot="header">
    Subscription Required
  </div>
  <div class="modal-content">{{ modalText() }}</div>
  <div class="modal-buttons">
    <button flyButton
      [options]="{ style: isAuthenticated() ? 'primary' : 'secondary' }"
      (click)="learnAboutPilotWeb()"> Subscribe Now</button>
    @if (!isAuthenticated()) {
      <button flyButton
        (click)="signIn()">Sign In</button>
    }
  </div>
</fly-modal>
