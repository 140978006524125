import { Component, DestroyRef, inject, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { differenceInDays, differenceInHours, differenceInMinutes, fromUnixTime } from 'date-fns';
import { interval } from 'rxjs';

export interface TimeElapsed {
  days: number;
  hours: number;
  mins: number;
}
@Component({
  selector: 'pilot-time-ago',
  standalone: true,
  templateUrl: './time-ago.component.html'
})
export class TimeAgoComponent implements OnInit {
  @Input() time: number;
  timeElapsed: TimeElapsed = {days: 0, hours: 0, mins: 0};
  private readonly destroyRef = inject(DestroyRef);

  ngOnInit():void {
    this.timeElapsed = TimeAgoComponent.getTimeElapsed(this.time, new Date());
    interval(1000*10).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.timeElapsed = TimeAgoComponent.getTimeElapsed(this.time, new Date());
    });
  }

  static getTimeElapsed(time: number, now: Date): TimeElapsed {
    const days = differenceInDays(now, fromUnixTime(time));
    const hours = differenceInHours(now, fromUnixTime(time)) -  days * 24;
    const mins = differenceInMinutes(now, fromUnixTime(time)) -  days * 24 * 60 -  hours * 60;
    return {days: days, hours: hours, mins: mins};
  }
}
