import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flyDirection'
})
export class DirectionPipe implements PipeTransform {
  readonly dirs = [[],
    ['N', 'E', 'S', 'W'],
    ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'],
    ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW']
  ];

  transform(value: number, precision: 1|2|3 = 2): string {
    const normalizedValue = value % 360;
    const interval = 360/(2**(precision+1));
    const adjustedValue = (normalizedValue + interval/2) % 360;
    const dir = this.dirs[precision][Math.floor(adjustedValue / interval)];
    return dir.slice(Math.max(0, dir.length - precision), dir.length);
  }
}
