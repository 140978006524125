<div class="not-configured">
  <img
  alt="Performance Aircraft"
  src="assets/illustrations/enhanced-performance.svg">
  <p>Need higher fidelity performance models?</p>
  <!--
    TODO: where should the button navigate
    -->
  <button
    class="not-configured-button"
    role="button"
    routerLink="/aircraft"
    flyButton>
    Add Premium Subscription
  </button>
</div>
