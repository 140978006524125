/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ARTCCQuery { 
    /**
     * Enum defining the input types for ARTCC info queries
     */
    queryType: ARTCCQueryQueryType;
    /**
     * The Identifier associated with the request.  For ARTCC, this will be an ARTCC Id. For Airports, it is the airport ICAO Id.  For FIR/UIR it is the region identifier.
     */
    identifier: string;
}
export enum ARTCCQueryQueryType {
    UNDEFINED = 'UNDEFINED',
    ARTCC = 'ARTCC',
    FIR = 'FIR',
    UIR = 'UIR',
    AIRPORT = 'AIRPORT'
};



