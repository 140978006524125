import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flyExtendedTitleCase',
  standalone: true
})
export class ExtendedTitleCasePipe implements PipeTransform {
  transform(value?: string): string {
    if (value != null) {
      return value.toLowerCase().replace(/(?:^|\s|\/|\-)\w/g, function (match) {
        return match.toUpperCase();
      });
    }
    return '';
  }
}
