import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flyPrefixUrl'
})
export class PrefixUrlPipe implements PipeTransform {

  transform(url: string | null | undefined, prefix: string = 'http://'): unknown {
    if (url != null) {
      if (!/^http[s]?:\/\//.test(url)) {
        return `${ prefix }${ url }`;
      }
    }
    return url;
  }

}
