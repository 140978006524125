<fly-modal
  [modalType]="ModalType.Alert"
  [displayCloseButton]="false"
  [confirmButtonText]="'OK'"
  (confirm)="modal().hide()">
  @if (title() !== '') {
    <h6 class="modal-header">{{ title() }}</h6>
  }
  <div class="modal-content">
    We are currently working on making this feature available.<br>Please revisit this page later
  </div>
</fly-modal>
