/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RouteRequest } from './route-request.model';


export interface AltitudeSurveyUnifiedRequest { 
    routeRequest: RouteRequest;
    /**
     * initial altitude to start the survey, defaults to ground level if absent
     */
    initialAltitude?: number;
    /**
     * maximum altitude to end the survey, defaults to 42,000 FT if absent
     */
    finalAltitude?: number;
    /**
     * Altitude survey mode
     */
    surveyMode?: AltitudeSurveyUnifiedRequestSurveyMode;
}
export enum AltitudeSurveyUnifiedRequestSurveyMode {
    IFR_EAST = 'IFR_EAST',
    IFR_WEST = 'IFR_WEST',
    VFR_EAST = 'VFR_EAST',
    VFR_WEST = 'VFR_WEST',
    ALL = 'ALL'
};



