import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const makeDecimalValidator = (precision: number): [ValidatorFn, { decimal: string }] => {
  let DECIMAL_ERROR = { decimal: `Value must be a number with at most ${precision} decimal place` };
  if (precision > 1) {
    DECIMAL_ERROR = { decimal: `Value must be a number with at most ${precision} decimal places` };
  }
  return [
    (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value == null || value === '') return null;
      if (!(new RegExp(`^\\-?\\d*(\\.\\d{0,${precision}})?$`)).test(value)) return DECIMAL_ERROR;
      return null;
    },
    DECIMAL_ERROR
  ];
};

export function decimalSnapValidator(decimalPrecision: number, min: number, max: number, required: boolean=false): ValidatorFn {
  if (!Number.isInteger(decimalPrecision) || decimalPrecision < 0) {
    throw new Error('decimal precision must be a positive integer');
  }
  return (control: AbstractControl): ValidationErrors | null => {
    if (control?.value == null || control?.value.toString() === '') {
      if (required) {
        return {decimal: 'required'};
      } else {
        return null;
      }
    }
    let oldValue = control.value;
    if (isNaN(oldValue)) {
      oldValue = null;
      if (required) {
        control.setValue(oldValue, { emitEvent: false, onlySelf: true});
        return {decimal: 'required'};
      } else {
        return {decimal: 'required'};
      }
    }
    if (!Number.isInteger(oldValue)) {
      const offset = Math.pow(10,decimalPrecision);
      let newValue = Math.round(oldValue*offset)/offset;
      if (newValue < min) {
        newValue = min;
      }
      if (newValue > max) {
        newValue = max;
      }
      if (oldValue !== newValue) {
        control.setValue(newValue, { emitEvent: false, onlySelf: true });
      }
    }
    return null;
  };
}
