/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * PERF Data computed for a given route.  Not required for Requests.
 */
export interface RoutePerf { 
    /**
     * Fuel endurance stored in minutes
     */
    fuelEndurance: number;
    /**
     * Fuel burned while at cruising altitude, in gallons/hr
     */
    cruiseFuelBurn: number;
    /**
     * Cruising true air speed, specified in knots
     */
    cruiseTas: number;
    /**
     * Estimated Time En route in minutes
     */
    ete: number;
    /**
     * Cruising indicated air speed, specified in knots
     */
    speed: number;
    /**
     * Altitude, specified in feet
     */
    altitude: number;
    /**
     * Distance of the entire route, in meters
     */
    distance: number;
    /**
     * Fuel required to reach the destination (in gallons)
     */
    fuelReq: number;
    /**
     * Average headwind for the duration of the cruise portion of the route, (m/s).  A positive value represents a headwind, while a negative value represents a tailwind.
     */
    avgHeadwind: number;
    /**
     * Flight direction
     */
    flightDirection?: RoutePerfFlightDirection;
    /**
     * The amount of CO2 produced for the entire route, in pounds
     */
    co2Output?: number;
}
export enum RoutePerfFlightDirection {
    EAST = 'EAST',
    WEST = 'WEST'
};



