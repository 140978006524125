<fly-modal [modalType]="ModalType.Map">
  <div
    slot="header"
    class="airway-modal-header">
    <div class="back-button-container header-button-container">
      <button
        class="back-button"
        flyButton
        [options]="{ style: 'ghost' }"
        [class.hidden]="!hasBackButton"
        (click)="goBack()">
        <fly-icon [icon]="Icons.ArrowLeft"></fly-icon>
        <p>Back</p>
      </button>
    </div>
    <div class="h5">{{ title }}</div>
    <div class="close-button-container header-button-container">
      <button
        class="close-button"
        (click)="hide()">
        <fly-icon [icon]="Icons.Close"></fly-icon>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div *ngTemplateOutlet="currentView"></div>
  </div>
</fly-modal>

<ng-template #selectAirwayRef>
  <div
    *ngFor="let airway of airways"
    class="menu-button"
    (click)="selectAirway(airway)"
    (keypress)="selectAirway(airway)"
    tabindex="0">
    <p>{{ airway.identifier }}</p>
    <fly-icon [icon]="Icons.ArrowRight"></fly-icon>
  </div>
</ng-template>

<ng-template #selectExitRef>
  <div
    *ngFor="let exit of exits"
    class="menu-button exit-menu-button"
    (click)="selectExit(exit)"
    (keypress)="selectExit(exit)"
    tabindex="0">
    <div class="exit-info">
      <pilot-waypoint-icon [type]="exit.locationType"></pilot-waypoint-icon>
      <div class="exit-info-text">
        <p>{{ exit.identifier }}</p>
        <p class="exit-subtitle">{{ exit.locationType }}</p>
      </div>
    </div>
    <div *ngIf="exit.distance">
      <p>{{ exit.distance | number: '.1-1' }}NM</p>
    </div>
    <div
      *ngIf="exit.angle"
      class="exit-info">
      <p>{{ exit.angle | number: '.1-1' }}&deg;</p>
      <fly-icon
        [icon]="Icons.ArrowBlock"
        flyHeadingArrow
        [angle]="exit.angle"></fly-icon>
    </div>
    <fly-icon [icon]="Icons.ArrowRight"></fly-icon>
  </div>
</ng-template>
