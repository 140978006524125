import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flyEnum'
})
export class EnumPipe implements PipeTransform {

  transform(value: any, enumType: any): string {
    if (typeof value === 'string') {
      const keys = Object.keys(enumType);
      const foundKey = keys.find((key) => { return key === value; });
      return foundKey != null ? enumType[foundKey].toString() : value.toString();
    } else {
      const retVal = enumType[value];
      return retVal?.toString() ?? value.toString();
    }
  }
}
