import { Component, DestroyRef, ElementRef, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Icons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { ProcedurePickerType } from '@shared/enums/flights/procedure-picker-type.enum';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'pilot-procedure-options',
  templateUrl: './procedure-options.component.html',
  styleUrls: ['./procedure-options.component.scss']
})
export class ProcedureOptionsComponent implements OnInit {

  @Input() allowInput: boolean;
  @Input() button: Element;
  @Input() type: ProcedurePickerType;
  @Input() exitName: string;
  @Input() hasAirways: boolean;
  @Input() nextLegIsAirway: boolean = false;
  @Output() readonly changeProcedure: EventEmitter<ProcedurePickerType> = new EventEmitter();
  @Output() readonly removeProcedure: EventEmitter<ProcedurePickerType> = new EventEmitter();
  @Output() readonly loadAirway: EventEmitter<void> = new EventEmitter();

  readonly ProcedurePickerType = ProcedurePickerType;

  readonly Icons = Icons;

  showPopup: boolean = false;

  private readonly destroyRef = inject(DestroyRef);
  private readonly elementRef = inject(ElementRef);

  ngOnInit(): void {
    fromEvent(document, 'click')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: any) => {
        if (this.elementRef.nativeElement.contains(event.target) === false
            && !this.button.contains(event.target)) {
          this.hide();
        }
        event.stopPropagation();
      });
  }

  toggleVisibility(): void {
    this.showPopup = this.allowInput && !this.showPopup;
  }

  hide(): void {
    this.showPopup = false;
  }

  hideAndEmit(emitter: EventEmitter<any>): void {
    this.hide();
    emitter.emit(this.type);
  }
}
