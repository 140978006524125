/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RouteComputedLeg } from './route-computed-leg.model';


/**
 * List of transitions
 */
export interface Transition { 
    /**
     * identifier
     */
    identifier: string;
    /**
     * one of the different types of waypoint AIRPORT, INTERSECTION, VOR, AIRWAY, etc
     */
    locationType: TransitionLocationType;
    /**
     * List of transitions
     */
    transitions: Array<Transition>;
    /**
     * List of legs
     */
    legs: Array<RouteComputedLeg>;
}
export enum TransitionLocationType {
    AIRPORT = 'AIRPORT',
    INTERSECTION = 'INTERSECTION',
    NDB = 'NDB',
    VOR = 'VOR',
    USER_WAYPOINT = 'USER_WAYPOINT',
    RUNWAY = 'RUNWAY',
    AIRPORT_INTERSECTION = 'AIRPORT_INTERSECTION',
    AIRPORT_NDB = 'AIRPORT_NDB',
    AIRPORT_ILS = 'AIRPORT_ILS',
    GLS_MARKER = 'GLS_MARKER',
    UNIDENTIFIED = 'UNIDENTIFIED',
    INVALID = 'INVALID',
    LOCALIZER_MARKER = 'LOCALIZER_MARKER',
    CHART_OBSTACLE = 'CHART_OBSTACLE',
    CHART_TERRAIN = 'CHART_TERRAIN',
    VOR_CLASS = 'VOR_CLASS',
    FIX_REFERENCE_CLASS = 'FIX_REFERENCE_CLASS',
    ROUTE_WAYPOINT = 'ROUTE_WAYPOINT',
    LAT_LON = 'LAT_LON',
    ALONG_TRACK = 'ALONG_TRACK',
    PARALLEL_TRACK = 'PARALLEL_TRACK',
    VNAV_WAYPOINT = 'VNAV_WAYPOINT',
    DEPARTURE_PROCEDURE = 'DEPARTURE_PROCEDURE',
    ARRIVAL_PROCEDURE = 'ARRIVAL_PROCEDURE',
    ENROUTE_TRANSITION = 'ENROUTE_TRANSITION',
    APPROACH_PROCEDURE = 'APPROACH_PROCEDURE',
    AIRWAY = 'AIRWAY',
    AIRWAY_ENTRY = 'AIRWAY_ENTRY',
    AIRWAY_EXIT = 'AIRWAY_EXIT',
    VRP = 'VRP',
    EMPTY = ''
};



