import { AirportDataSection } from './airport-data-section.enum';


export enum AirportDataSubset {
  ALL = 'ALL',
  WEATHER = 'WEATHER'
}

export const AirportDataSubsetSectionMap = new Map<AirportDataSubset, AirportDataSection[]>([
  [AirportDataSubset.ALL, [...Object.values(AirportDataSection)]],
  [AirportDataSubset.WEATHER, [AirportDataSection.AIRSIGRESPONSE, AirportDataSection.FORECASTDISCUSSION, AirportDataSection.METAR,
    AirportDataSection.MOS, AirportDataSection.TAF, AirportDataSection.WIND]]

]);
