import { Component, inject, input } from '@angular/core';
import { Router } from '@angular/router';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';

@Component({
  selector: 'pilot-aircraft-not-configured',
  standalone: true,
  imports: [FlyButtonModule],
  templateUrl: './aircraft-not-configured.component.html',
  styleUrl: './aircraft-not-configured.component.scss'
})
export class AircraftNotConfiguredComponent {
  readonly aircraftTailNumber = input<string | undefined>(undefined);
  private readonly router = inject(Router);

  configureAircraft(): void {
    if (this.aircraftTailNumber() !== '') {
      this.router.navigate(['/aircraft/edit/', this.aircraftTailNumber()]);
    } else {
      this.router.navigate(['/aircraft']);
    }
  }
}
