import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Icons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { FlyModalComponent } from '@garmin-avcloud/avcloud-ui-common/modal';


@Component({
  selector: 'pilot-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  @Input({ required: true }) title: string;
  @Output() readonly confirm: EventEmitter<void> = new EventEmitter();
  @ViewChild(FlyModalComponent) flyModal: FlyModalComponent;

  readonly close = Icons.Close;

  hide(): void {
    this.flyModal.hide();
  }

  show(): void {
    this.flyModal.show();
  }
}
