/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Coordinate } from './coordinate.model';
import { FirWaypoint } from './fir-waypoint.model';


/**
 * Specifies a FIR or other type of ACC Polygon crossing.  Provides information sufficient to file the crossings with the flight plan.
 */
export interface AccFirCrossing { 
    /**
     * Specifies a type of ACC boundary in results for an FIR/ACC crossing search
     */
    boundaryType: AccFirCrossingBoundaryType;
    /**
     * 2 character country code
     */
    countryCode: string;
    /**
     * Location name
     */
    locationName?: string;
    /**
     * Leg index of the intermediate waypoints list of the route where this Crossing occurs.  Relative to the computed route. 
     */
    legIndex: number;
    /**
     * Distance from the start of the route where this crossing occurs, in meters
     */
    alongTrackDistance: number;
    /**
     * estimated time en-route to this crossing, in seconds
     */
    ete: number;
    nearestWaypoint?: FirWaypoint;
    position: Coordinate;
}
export enum AccFirCrossingBoundaryType {
    INVALID = 'INVALID',
    FIR = 'FIR',
    UIR = 'UIR',
    FIR_UIR = 'FIR_UIR',
    ARTCC = 'ARTCC',
    ACC = 'ACC',
    AOR = 'AOR',
    CTA = 'CTA',
    UNKNOWN = 'UNKNOWN'
};



