import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: 'fly-icon[flyHeadingArrow]'
})
export class HeadingArrowDirective implements OnInit {
  @Input() angle?: number;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) { }

  ngOnInit(): void {
    if (this.angle === undefined) {
      this.angle = 0;
    }
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'transform',
      `rotate(${180 + this.angle}deg)`);
  }
}
