<fly-card class="search-card"
  [selected]="isSelected"
  (selectedChange)="onSelect()"
  [padding]="'reduced'">
  <div class="wrapper">
    <!-- No unique airport icon -->
    <fly-icon *ngIf="airport.icon == null || airport.icon.length === 0"
      [icon]="FlightIcons.Airports.ToweredServices"></fly-icon>
    <!-- Unique airport icon -->
    <div class="airport-icon"
      *ngIf="airport.icon != null && airport.icon.length > 0">
      <img alt="runway diagram"
        src="data:image/svg+xml;base64, {{airport.icon}}">
    </div>
    <div class="airport-data">
      <div class="airport-row">
        <span>{{ airport.icao }}</span>
        <div class="airport-row-right">
          <svg xmlns="http://www.w3.org/2000/svg"
            height="15"
            width="14"
            viewBox="0 0 14 15"
            fill="none">
            <circle cx="7"
              cy="7.5"
              r="7"
              [ngClass]="'condition-' + airport.category | flyReplace:' ':'-'"
              fill="currentColor"></circle>
          </svg>
          <span>{{ airport.category }}</span>
        </div>
      </div>
      <div class="airport-name body-2">
        <!-- TODO figure out titlecase -->
        {{ airport.name | flyExtendedTitleCase }}
      </div>
    </div>
  </div>
</fly-card>
