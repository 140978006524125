<form [formGroup]="formGroup">
  <div class="content-frame">
    <div class="search-frame">
      <fly-input
        formControlName="searchText"
        placeholder="Search">
        <fly-icon [icon]="icons.search"
          slot="icon-left">
        </fly-icon>
      </fly-input>
    </div>
    <p
    *ngIf="errorMsg != null"
    class="error-display"
    >
    {{ errorMsg }}
    </p>
    <div *ngFor="let result of tokenSearchResults"
         class="results-frame">
      <div class="result-item">
        <div class="result-info">
          <pilot-waypoint-icon
            [type]="result.locationType"
            [navaidType]="result.navaidType"></pilot-waypoint-icon>
          <div class="result-text-frame">
            <p>{{ result.identifier }}</p>
            <p class="result-type-text">{{ result.locationType }}</p>
            <p
            *ngIf="result.countryDesc"
            class="result-type-text">{{ result.countryDesc }}</p>
          </div>
        </div>
        <button class="result-button"
          type="button"
          (click)="selectResult(result);
          createCookie(result.identifier)">
          <fly-icon [icon]="viewMode ? icons.crosshair : icons.plus"></fly-icon>
        </button>
      </div>
    </div>
  </div>
</form>
