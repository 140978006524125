import * as i3 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { ElementRef, Injectable, Inject, EventEmitter, Component, Input, Output, ViewChild, NgModule } from '@angular/core';
import * as i4 from '@garmin-avcloud/avcloud-ui-common/button';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import * as i5 from '@garmin-avcloud/avcloud-ui-common/icon';
import { Icons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import * as i1 from '@garmin-avcloud/avcloud-ui-common/shared';
import { FlySharedModule } from '@garmin-avcloud/avcloud-ui-common/shared';
import { Breakpoints } from '@garmin-avcloud/avcloud-ui-common/style-variables';
import { distinctUntilChanged } from 'rxjs';
import * as i2 from '@angular/cdk/layout';
const _c0 = ["rootRef"];
const _c1 = [[["", "slot", "header"]], "*", [["", "slot", "image"]]];
const _c2 = ["[slot=header]", "*", "[slot=image]"];
const _c3 = () => ({
  style: "secondary"
});
function FlyModalComponent_div_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵlistener("click", function FlyModalComponent_div_2_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dismiss());
    });
    i0.ɵɵelementEnd();
  }
}
function FlyModalComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementEnd();
  }
}
function FlyModalComponent_button_9_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 13);
    i0.ɵɵlistener("click", function FlyModalComponent_button_9_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dismiss());
    });
    i0.ɵɵelement(1, "fly-icon", 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("icon", ctx_r1.close);
  }
}
function FlyModalComponent_div_12_button_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 18);
    i0.ɵɵlistener("click", function FlyModalComponent_div_12_button_1_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.cancel.emit());
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("options", i0.ɵɵpureFunction0(2, _c3));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.cancelButtonText, " ");
  }
}
function FlyModalComponent_div_12_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 15);
    i0.ɵɵtemplate(1, FlyModalComponent_div_12_button_1_Template, 2, 3, "button", 16);
    i0.ɵɵelementStart(2, "button", 17);
    i0.ɵɵlistener("click", function FlyModalComponent_div_12_Template_button_click_2_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.confirm.emit());
    });
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("centered", ctx_r1.centerButtons);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.modalType === ctx_r1.ModalType.Confirmation);
    i0.ɵɵadvance();
    i0.ɵɵproperty("options", ctx_r1.confirmButtonOptions)("disabled", ctx_r1.confirmButtonDisabled);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.confirmButtonText, " ");
  }
}
var ModalOpenBehaviorEnum;
(function (ModalOpenBehaviorEnum) {
  ModalOpenBehaviorEnum["Replace"] = "replace";
  ModalOpenBehaviorEnum["Stack"] = "stack";
})(ModalOpenBehaviorEnum || (ModalOpenBehaviorEnum = {}));
class FlyModalModel {
  constructor(id, containingElement, content, target, renderer, options) {
    this.id = id;
    this.homeElement = containingElement;
    this.element = content;
    this.target = target;
    this.renderer = renderer;
    this.options = options;
  }
}
class ModalService {
  constructor(document, rendererFactory2, zIndexer) {
    this.document = document;
    this.rendererFactory2 = rendererFactory2;
    this.zIndexer = zIndexer;
    this.modalStack = [];
    this.modals = new Map();
    const renderer = rendererFactory2.createRenderer(null, null);
    renderer.listen('window', 'keyup.esc', () => {
      this.onEscape();
    });
  }
  onEscape() {
    if (this.modalStack.length > 0) {
      const topModal = this.modalStack[this.modalStack.length - 1];
      if (topModal.options.escapable === true) {
        this.closeDialog(topModal.id);
      }
    }
  }
  registerDialog(id, containingElement, content, renderer, options) {
    let nativeContentElement;
    if (content instanceof ElementRef) {
      nativeContentElement = content.nativeElement;
    } else {
      nativeContentElement = content;
    }
    this.modals.set(id, new FlyModalModel(id, containingElement.nativeElement, nativeContentElement, this.document.querySelector(options.targetSelector ?? '') ?? this.document.body, renderer, options));
  }
  unregisterDialog(id) {
    this.modals.delete(id);
  }
  openDialog(id) {
    const modal = this.modals.get(id);
    if (modal == null) {
      throw new Error(`Invalid modal id: ${id}`);
    }
    this.closeDialog(id);
    this.modalStack.push(modal);
    modal.renderer.appendChild(modal.target, modal.element);
    modal.renderer.addClass(modal.element, 'fly-modal-open');
    modal.renderer.setStyle(modal.element, 'z-index', this.zIndexer.nextZIndex());
  }
  closeDialog(id) {
    const modal = this.modals.get(id);
    if (modal == null) {
      throw new Error(`Invalid modal id: ${id}`);
    }
    const index = this.modalStack.indexOf(modal);
    if (index >= 0) {
      this.modalStack.splice(index, 1);
      modal.renderer.removeClass(modal.element, 'fly-modal-open');
      modal.renderer.appendChild(modal.homeElement, modal.element);
    }
  }
  static {
    this.ɵfac = function ModalService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ModalService)(i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(i1.ZIndexService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ModalService,
      factory: ModalService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalService, [{
    type: Injectable
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: i0.RendererFactory2
  }, {
    type: i1.ZIndexService
  }], null);
})();
var ModalType;
(function (ModalType) {
  ModalType[ModalType["Alert"] = 0] = "Alert";
  ModalType[ModalType["Confirmation"] = 1] = "Confirmation";
  ModalType[ModalType["Custom"] = 2] = "Custom";
  ModalType[ModalType["Map"] = 3] = "Map";
})(ModalType || (ModalType = {}));
class FlyModalComponent {
  static {
    this.counter = 0;
  }
  constructor(renderer, inlineContainer, modalService, breakpointObserver) {
    this.renderer = renderer;
    this.inlineContainer = inlineContainer;
    this.modalService = modalService;
    this.breakpointObserver = breakpointObserver;
    this.closable = false;
    this.confirmButtonOptions = {
      style: 'primary'
    };
    this.displayOverlay = true;
    this.breakpoint = Breakpoints.SmallScreenMaxWidth;
    this.modalType = ModalType.Confirmation;
    this.cancelButtonText = 'Cancel';
    this.confirmButtonDisabled = false;
    this.confirmButtonText = 'Confirm';
    this.centerButtons = true;
    this.cancel = new EventEmitter();
    this.confirm = new EventEmitter();
    this.ModalType = ModalType;
    this.isMobile = false;
    this.close = Icons.Close;
    this._defaultInput = {
      escapable: true,
      targetSelector: 'body',
      position: 'centered',
      customClass: '',
      openBehavior: ModalOpenBehaviorEnum.Stack
    };
    FlyModalComponent.counter += 1;
    this.modalId = `FlyModal-${FlyModalComponent.counter}`;
  }
  ngOnInit() {
    if (this.dismissible === undefined) {
      this.dismissible = this.modalType !== ModalType.Confirmation;
    }
    if (this.displayCloseButton === undefined) {
      this.displayCloseButton = this.modalType !== ModalType.Confirmation && this.modalType !== ModalType.Map;
    }
    const modalTypeOptions = {
      escapable: this.modalType !== ModalType.Confirmation
    };
    this.options = {
      ...this._defaultInput,
      ...modalTypeOptions,
      ...this.options
    };
    this.breakpointSubscription = this.breakpointObserver.observe([this.breakpoint]).pipe(distinctUntilChanged()).subscribe(({
      matches
    }) => this.isMobile = matches);
  }
  ngAfterViewInit() {
    this.modalService.registerDialog(this.modalId, this.inlineContainer, this.rootElem, this.renderer, this.options);
  }
  ngOnDestroy() {
    this.hide();
    this.modalService.unregisterDialog(this.modalId);
    this.breakpointSubscription.unsubscribe();
  }
  show() {
    this.modalService.openDialog(this.modalId);
  }
  hide() {
    this.modalService.closeDialog(this.modalId);
  }
  dismiss() {
    if (this.dismissible) {
      this.cancel.emit();
      this.hide();
    }
    return false;
  }
  static {
    this.ɵfac = function FlyModalComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyModalComponent)(i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(ModalService), i0.ɵɵdirectiveInject(i2.BreakpointObserver));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FlyModalComponent,
      selectors: [["fly-modal"]],
      viewQuery: function FlyModalComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.rootElem = _t.first);
        }
      },
      inputs: {
        options: "options",
        closable: "closable",
        confirmButtonOptions: "confirmButtonOptions",
        displayCloseButton: "displayCloseButton",
        displayOverlay: "displayOverlay",
        breakpoint: "breakpoint",
        dismissible: "dismissible",
        modalTitle: "modalTitle",
        modalType: "modalType",
        cancelButtonText: "cancelButtonText",
        confirmButtonDisabled: "confirmButtonDisabled",
        confirmButtonText: "confirmButtonText",
        centerButtons: "centerButtons"
      },
      outputs: {
        cancel: "cancel",
        confirm: "confirm"
      },
      ngContentSelectors: _c2,
      decls: 13,
      vars: 6,
      consts: [["rootRef", ""], [1, "fly-modal-wrapper"], ["class", "fly-modal-overlay", 3, "click", 4, "ngIf"], [1, "fly-modal"], ["class", "fly-modal-image-pane", 4, "ngIf"], [1, "fly-modal-content-pane"], [1, "fly-modal-content"], [1, "fly-modal-header"], ["class", "fly-modal-close-button", "aria-label", "Close", 3, "click", 4, "ngIf"], [1, "fly-modal-user-content"], ["class", "fly-modal-button-container", 3, "centered", 4, "ngIf"], [1, "fly-modal-overlay", 3, "click"], [1, "fly-modal-image-pane"], ["aria-label", "Close", 1, "fly-modal-close-button", 3, "click"], ["aria-hidden", "true", 3, "icon"], [1, "fly-modal-button-container"], ["flyButton", "", 3, "options", "click", 4, "ngIf"], ["flyButton", "", 3, "click", "options", "disabled"], ["flyButton", "", 3, "click", "options"]],
      template: function FlyModalComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵelementStart(0, "div", 1, 0);
          i0.ɵɵtemplate(2, FlyModalComponent_div_2_Template, 1, 0, "div", 2);
          i0.ɵɵelementStart(3, "div", 3);
          i0.ɵɵtemplate(4, FlyModalComponent_div_4_Template, 2, 0, "div", 4);
          i0.ɵɵelementStart(5, "div", 5)(6, "div", 6)(7, "div", 7);
          i0.ɵɵprojection(8);
          i0.ɵɵtemplate(9, FlyModalComponent_button_9_Template, 2, 1, "button", 8);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(10, "div", 9);
          i0.ɵɵprojection(11, 1);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(12, FlyModalComponent_div_12_Template, 4, 6, "div", 10);
          i0.ɵɵelementEnd()()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("ngIf", ctx.displayOverlay);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("mobile", ctx.isMobile);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.isMobile);
          i0.ɵɵadvance(5);
          i0.ɵɵproperty("ngIf", ctx.displayCloseButton);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngIf", ctx.modalType !== ctx.ModalType.Custom && ctx.modalType !== ctx.ModalType.Map);
        }
      },
      dependencies: [i3.NgIf, i4.FlyButtonDirective, i5.IconComponent],
      styles: [".fly-modal-wrapper[_ngcontent-%COMP%]{position:fixed;top:0;left:0;width:100vw;height:100vh;align-items:center;justify-content:center;display:none}.fly-modal-wrapper.fly-modal-open[_ngcontent-%COMP%]{display:flex}.fly-modal[_ngcontent-%COMP%]{position:fixed;z-index:inherit;background-color:var(--ui-section);padding:0;display:none;max-width:100%;max-height:100%;overflow:hidden;border-radius:.625rem}.fly-modal-open[_ngcontent-%COMP%] > .fly-modal[_ngcontent-%COMP%]{display:flex}.fly-modal-overlay[_ngcontent-%COMP%]{display:none;z-index:inherit;position:fixed;top:0;left:0;background-color:var(--ui-curtain);opacity:.7;width:100%;height:100%}.fly-modal-open[_ngcontent-%COMP%] > .fly-modal-overlay[_ngcontent-%COMP%]{display:initial}.fly-modal[_ngcontent-%COMP%]   .fly-modal-image-pane[_ngcontent-%COMP%]{flex:0 0 auto}.fly-modal[_ngcontent-%COMP%]   .fly-modal-content-pane[_ngcontent-%COMP%]{display:flex;flex-direction:column;justify-content:space-between;align-items:stretch;width:100%}.fly-modal[_ngcontent-%COMP%]   .fly-modal-content-pane[_ngcontent-%COMP%]   .fly-modal-content[_ngcontent-%COMP%]{display:flex;flex-direction:column;min-height:0;height:100%}.fly-modal[_ngcontent-%COMP%]   .fly-modal-content-pane[_ngcontent-%COMP%]   .fly-modal-user-content[_ngcontent-%COMP%]{overflow-y:auto;height:100%}.fly-modal[_ngcontent-%COMP%]   .fly-modal-button-container[_ngcontent-%COMP%]{justify-self:flex-end;display:flex;justify-content:flex-end;gap:1rem;padding:.5rem 1rem 1rem}.fly-modal[_ngcontent-%COMP%]   .fly-modal-button-container.centered[_ngcontent-%COMP%]{justify-content:center}.fly-modal-close-button[_ngcontent-%COMP%]{position:absolute;top:.8rem;right:.5rem;width:.875rem;cursor:pointer}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyModalComponent, [{
    type: Component,
    args: [{
      selector: 'fly-modal',
      template: "<div #rootRef\n    class=\"fly-modal-wrapper\">\n  <div class=\"fly-modal-overlay\"\n    *ngIf=\"displayOverlay\"\n    (click)=\"dismiss()\">\n  </div>\n  <div class=\"fly-modal\"\n    [class.mobile]=\"isMobile\">\n    <div class=\"fly-modal-image-pane\"\n      *ngIf=\"!isMobile\">\n      <ng-content\n        select=\"[slot=image]\"></ng-content>\n    </div>\n    <div class=\"fly-modal-content-pane\">\n      <div class=\"fly-modal-content\">\n        <div class=\"fly-modal-header\">\n          <ng-content\n            select=\"[slot=header]\"></ng-content>\n          <button class=\"fly-modal-close-button\"\n            aria-label=\"Close\"\n            *ngIf=\"displayCloseButton\"\n            (click)=\"dismiss()\">\n            <fly-icon [icon]=\"close\"\n              aria-hidden=\"true\"></fly-icon>\n          </button>\n        </div>\n        <div class=\"fly-modal-user-content\">\n          <ng-content></ng-content>\n        </div>\n      </div>\n      <div class=\"fly-modal-button-container\"\n        [class.centered]=\"centerButtons\"\n        *ngIf=\"modalType !== ModalType.Custom && modalType !== ModalType.Map\">\n        <button\n          *ngIf=\"modalType === ModalType.Confirmation\"\n          flyButton\n          [options]=\"{\n            style: 'secondary'}\"\n          (click)=\"cancel.emit()\">\n          {{ cancelButtonText }}\n        </button>\n        <button\n          flyButton\n          [options]=\"confirmButtonOptions\"\n          [disabled]=\"confirmButtonDisabled\"\n          (click)=\"confirm.emit()\">\n          {{ confirmButtonText }}\n        </button>\n      </div>\n    </div>\n  </div>\n</div>\n",
      styles: [".fly-modal-wrapper{position:fixed;top:0;left:0;width:100vw;height:100vh;align-items:center;justify-content:center;display:none}.fly-modal-wrapper.fly-modal-open{display:flex}.fly-modal{position:fixed;z-index:inherit;background-color:var(--ui-section);padding:0;display:none;max-width:100%;max-height:100%;overflow:hidden;border-radius:.625rem}.fly-modal-open>.fly-modal{display:flex}.fly-modal-overlay{display:none;z-index:inherit;position:fixed;top:0;left:0;background-color:var(--ui-curtain);opacity:.7;width:100%;height:100%}.fly-modal-open>.fly-modal-overlay{display:initial}.fly-modal .fly-modal-image-pane{flex:0 0 auto}.fly-modal .fly-modal-content-pane{display:flex;flex-direction:column;justify-content:space-between;align-items:stretch;width:100%}.fly-modal .fly-modal-content-pane .fly-modal-content{display:flex;flex-direction:column;min-height:0;height:100%}.fly-modal .fly-modal-content-pane .fly-modal-user-content{overflow-y:auto;height:100%}.fly-modal .fly-modal-button-container{justify-self:flex-end;display:flex;justify-content:flex-end;gap:1rem;padding:.5rem 1rem 1rem}.fly-modal .fly-modal-button-container.centered{justify-content:center}.fly-modal-close-button{position:absolute;top:.8rem;right:.5rem;width:.875rem;cursor:pointer}\n"]
    }]
  }], () => [{
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: ModalService
  }, {
    type: i2.BreakpointObserver
  }], {
    options: [{
      type: Input
    }],
    closable: [{
      type: Input
    }],
    confirmButtonOptions: [{
      type: Input
    }],
    displayCloseButton: [{
      type: Input
    }],
    displayOverlay: [{
      type: Input
    }],
    breakpoint: [{
      type: Input
    }],
    dismissible: [{
      type: Input
    }],
    modalTitle: [{
      type: Input
    }],
    modalType: [{
      type: Input
    }],
    cancelButtonText: [{
      type: Input
    }],
    confirmButtonDisabled: [{
      type: Input
    }],
    confirmButtonText: [{
      type: Input
    }],
    centerButtons: [{
      type: Input
    }],
    cancel: [{
      type: Output
    }],
    confirm: [{
      type: Output
    }],
    rootElem: [{
      type: ViewChild,
      args: ['rootRef']
    }]
  });
})();
class FlyModalModule {
  static {
    this.ɵfac = function FlyModalModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyModalModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyModalModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [ModalService],
      imports: [CommonModule, FlyButtonModule, FlyIconModule, FlySharedModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyModalModule, [{
    type: NgModule,
    args: [{
      declarations: [FlyModalComponent],
      imports: [CommonModule, FlyButtonModule, FlyIconModule, FlySharedModule],
      exports: [FlyModalComponent],
      providers: [ModalService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/modal
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlyModalComponent, FlyModalModule, ModalType };
