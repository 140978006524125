import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flyPhone'
})
export class PhonePipe implements PipeTransform {

  transform(value: number|string|undefined): string {
    return this._formatPhoneNumber('-', value);
  }

  transform_space(value: number|string|undefined): string {
    return this._formatPhoneNumber(' ', value);
  }

  private _formatPhoneNumber(separator: string, value: number|string|undefined): string {
    if (value !== undefined) {
      if (value.toString() === '1-800-WX-BRIEF') return value.toString();
      const strVal = value.toString().replace(/[\(\) -]/g, '');
      const startIndex = Math.max(strVal.length-10, 0);
      const last10 = strVal.slice(startIndex, strVal.length);
      return `${startIndex === 0 ? '' : `${strVal.slice(0, startIndex)}${separator}`}${last10.slice(0, 3)}${separator}${last10.slice(3, 6)}${separator}${last10.slice(6, 10)}`;
    }
    return '';
  }
}
