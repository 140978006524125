<div
  class="popup"
  *ngIf="showPopup">
  <fly-icon
    class="popup-extension"
    [icon]="Icons.ArrowLeft"></fly-icon>
  <div class="popup-body">
    <button
      flyButton
      [options]="{ style: 'ghost' }"
      (click)="hideAndEmit(replace)">
      <p>Change Airway</p>
      <fly-icon [icon]="Icons.ArrowRight"></fly-icon>
    </button>
    <button
      flyButton
      [options]="{ style: 'ghost' }"
      (click)="hideAndEmit(loadAirwayAfter)">
      <p>{{ nextLegIsAirway ? 'Change' : 'Load' }} Airway After {{ exitName }}</p>
      <fly-icon [icon]="nextLegIsAirway ? Icons.ArrowRight : Icons.Plus"></fly-icon>
    </button>
    <button
      flyButton
      [options]="{ style: 'dangerghost' }"
      (click)="hideAndEmit(remove)">
      Remove Airway
      <fly-icon [icon]="Icons.Trash"></fly-icon>
    </button>
  </div>
</div>
