/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The aircraft filing information to use instead of retrieving the relevant information from the associated aircraft in the database. Note that this field is currently required, because the aircraft service API is not yet functional.
 */
export interface AircraftFilingOverrideInfo { 
    /**
     * The aircraft tail number
     */
    aircraftTailNo: string;
    /**
     * The aircraft registration. Only needed if different from the aircraft tail number
     */
    aircraftReg?: string;
    /**
     * The ICAO aircraft type
     */
    aircraftType?: string;
    /**
     * Color(s) of the aircraft
     */
    aircraftColors?: Array<string>;
    /**
     * The home base of the aircraft
     */
    aircraftBase?: string;
    /**
     * (enum) The wake turbulence category of the aircraft.
     */
    aircraftWakeTurbulence?: AircraftFilingOverrideInfoAircraftWakeTurbulence;
    /**
     * The aircraft surveillance equipment code(s)
     */
    aircraftSurveillanceEquipment?: string;
    /**
     * ICAO aircraft equipment code(s)
     */
    aircraftEquipment?: string;
    /**
     * The US domestic aircraft equipment code
     */
    usDomesticAircraftEquipment?: string;
    /**
     * Whether or not a ballistic parachute is installed on the aircraft
     */
    aircraftBallisticPara?: boolean;
    /**
     * (enum) The landing gear type of the aircraft
     */
    aircraftLandingGear?: AircraftFilingOverrideInfoAircraftLandingGear;
    /**
     * (enum) Types of survival equipment on board
     */
    survivalEquipment?: Array<AircraftFilingOverrideInfoSurvivalEquipment>;
    /**
     * (enum) Radio communication, navigation and approach aid equipment and capabilities
     */
    survivalRadios?: Array<AircraftFilingOverrideInfoSurvivalRadios>;
    /**
     * (enum) Types of jackets on board
     */
    jacketTypes?: Array<AircraftFilingOverrideInfoJacketTypes>;
    /**
     * Supplemental survival remarks to include in item 19 for filing.
     */
    survivalRemarks?: string;
    /**
     * Emergency Locator Transmitter Type. Must be specified when survivalRadios includes the ELT type
     */
    eltType?: string;
    /**
     * (enum) Emergency Locator Transmitter Type. Must be specified when survivalRadios includes the ELT type
     */
    eltTypeEnum?: AircraftFilingOverrideInfoEltTypeEnum;
    /**
     * Number of dinghies on board.
     */
    dinghyCount?: number;
    /**
     * Total person capacity of all dinghies on board.
     */
    dinghiesCapacity?: number;
    /**
     * Color of the dinghies on board.
     */
    dinghiesColor?: string;
    /**
     * Whether or not the dinghies are covered.
     */
    dinghiesCovered?: boolean;
    /**
     * Performance Based Navigation (PBN) indicator
     */
    perfBasedNav?: string;
    /**
     * Navigation Equipment (NAV) indicator
     */
    navEquipment?: string;
    /**
     * Communication Equipment (COM) indicator
     */
    commEquipment?: string;
    /**
     * Data Link Capability (DAT) indicator
     */
    dataLink?: string;
    /**
     * Surveillance (SUR) indicator
     */
    surveillance?: string;
    /**
     * Aircraft SELCAL code (SEL) indicator
     */
    selcal?: string;
    /**
     * Runway Visual Range (RVR) indicator
     */
    runwayVisRange?: string;
    /**
     * Aircraft Performance (PER) indicator
     */
    aircraftPerformance?: string;
    /**
     * Customer aircraft type override to use in item 18. This field is typically used in item 18 under the \"TYP\" marker to indicate the aircraft type(s) when numberOfAircraft is greater than one or aircraftType is Non-ICAO (ZZZZ).
     */
    item18TypOverride?: string;
}
export enum AircraftFilingOverrideInfoAircraftWakeTurbulence {
    HEAVY = 'HEAVY',
    MEDIUM = 'MEDIUM',
    LIGHT = 'LIGHT'
};
export enum AircraftFilingOverrideInfoAircraftLandingGear {
    WHEELS = 'WHEELS',
    SKIS_SKIDS_SEAPLANE = 'SKIS_SKIDS_SEAPLANE',
    FLOATS = 'FLOATS',
    AMPHIBIAN = 'AMPHIBIAN',
    WHEELS_AND_SKIDS = 'WHEELS_AND_SKIDS'
};
export enum AircraftFilingOverrideInfoSurvivalEquipment {
    POLAR = 'POLAR',
    DESERT = 'DESERT',
    MARITIME = 'MARITIME',
    JUNGLE = 'JUNGLE'
};
export enum AircraftFilingOverrideInfoSurvivalRadios {
    UHF = 'UHF',
    VHF = 'VHF',
    ELT = 'ELT'
};
export enum AircraftFilingOverrideInfoJacketTypes {
    LIGHT = 'LIGHT',
    FLUORESCENT = 'FLUORESCENT',
    UHF = 'UHF',
    VHF = 'VHF'
};
export enum AircraftFilingOverrideInfoEltTypeEnum {
    AUTOMATIC_EJECTABLE = 'AUTOMATIC_EJECTABLE',
    AUTOMATIC_DEPLOYABLE = 'AUTOMATIC_DEPLOYABLE',
    AUTOMATIC_FIXED = 'AUTOMATIC_FIXED',
    AUTOMATIC_PORTABLE = 'AUTOMATIC_PORTABLE',
    FIXED = 'FIXED',
    PERSONAL = 'PERSONAL',
    SURVIVAL = 'SURVIVAL',
    WATER_ACTIVATED = 'WATER_ACTIVATED',
    NONE = 'NONE'
};



