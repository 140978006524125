import { Component, OnInit, Input, inject, OnChanges, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {  Validators } from '@angular/forms';
import { isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { SettingsWaypointsWaypointModel } from '@shared/models/settings/waypoints/settings-waypoints-waypoint.model';
import { CoordType, CoordinatePipe } from '@shared/pipes/coordinate/coordinate.pipe';
import { LatLonUtils, WPT_FORMATS } from '@shared/utils/lat-lon-utils';
import { SettingsWaypointsService } from '../../settings-waypoints.service';


@Component({
  selector: 'pilot-settings-lat-lon-tab',
  templateUrl: './settings-lat-lon-tab.component.html',
  styleUrls: ['./settings-lat-lon-tab.component.scss']
})

export class SettingsLatLonTabComponent implements OnInit, OnChanges {
  @Input() waypoint: SettingsWaypointsWaypointModel;
  destroyRef: DestroyRef = inject(DestroyRef);
  private readonly settingsWaypointService = inject(SettingsWaypointsService);

  readonly latLonForm = this.settingsWaypointService.latLonForm;

  formatSelected: string = WPT_FORMATS.DEG;
  formatOptions = Object.values(WPT_FORMATS).map((value) => { return { text: value.toUpperCase(), value: value }; });


  private readonly coordPipe: CoordinatePipe = new CoordinatePipe();
  errorMap = {
    required: 'Required Value.',
    unknownError: 'Something went wrong.'
  };

  errorStrings = {
    required: 'Required Value.',
    range: 'Value out of range.',
    invalidFormat: 'Invalid format.',
    latTooBig: 'Latitude cannot be larger than 90 degrees.',
    lonTooBig: 'Longitude cannot be larger than 180 degrees.'
  };

  ngOnInit(): void {
    this.updateLatLonFormat();
    this._loadForm(this.waypoint);
  }

  ngOnChanges(): void {
    this._loadForm(this.waypoint);
  }

  // if editing a waypoint, set the form values
  _loadForm(waypoint: SettingsWaypointsWaypointModel | any = {}): void {
    if (!isStringNonEmpty(waypoint.waypointJson?.format)) {
      this.latLonForm.controls.format.setValue(WPT_FORMATS.DEG);
    } else {
      this.formatSelected = waypoint.waypointJson?.format;
      this.latLonForm.controls.format.setValue(waypoint.waypointJson?.format);
    }
    if (waypoint.lat != null || waypoint.lon != null) {
      this.formatLatLon(waypoint.lat, waypoint.lon);
    }
  }

  private formatLatLon(loadedLat: number, loadedLon: number): void {
    const [latDeg, latMin, latSec] = LatLonUtils.splitDegrees(this.coordPipe.transform(loadedLat, CoordType.LATITUDE, this.latLonForm.controls.format.value));
    const [lonDeg, lonMin, lonSec] = LatLonUtils.splitDegrees(this.coordPipe.transform(loadedLon, CoordType.LONGITUDE, this.latLonForm.controls.format.value));
    this.latLonForm.patchValue({
      latDeg, latMin, latSec,
      lonDeg, lonMin, lonSec
    });
  }

  // listen for format changes to update the validators
  private updateLatLonFormat(): void {
    this.latLonForm.controls.format.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value) => {
      if (value === WPT_FORMATS.DEG) {
        this.latLonForm.controls.latDeg.setValidators([Validators.required, LatLonUtils.latValidator(WPT_FORMATS.DEG, "deg")]);
        this.latLonForm.controls.lonDeg.setValidators([Validators.required, LatLonUtils.lonValidator(WPT_FORMATS.DEG, "deg")]);
        this.latLonForm.controls.latMin.clearValidators();
        this.latLonForm.controls.lonMin.clearValidators();
        this.latLonForm.controls.latSec.clearValidators();
        this.latLonForm.controls.lonSec.clearValidators();
      } else if (value === WPT_FORMATS.DEG_MIN) {
        this.latLonForm.controls.latDeg.setValidators([Validators.required, LatLonUtils.latValidator(WPT_FORMATS.DEG_MIN, "deg")]);
        this.latLonForm.controls.lonDeg.setValidators([Validators.required, LatLonUtils.lonValidator(WPT_FORMATS.DEG_MIN, "deg")]);
        this.latLonForm.controls.latMin.setValidators([Validators.required, LatLonUtils.latValidator(WPT_FORMATS.DEG_MIN, "min")]);
        this.latLonForm.controls.lonMin.setValidators([Validators.required, LatLonUtils.lonValidator(WPT_FORMATS.DEG_MIN, "min")]);
        this.latLonForm.controls.latSec.clearValidators();
        this.latLonForm.controls.lonSec.clearValidators();
      } else if (value === WPT_FORMATS.DEG_MIN_SEC) {
        this.latLonForm.controls.latDeg.setValidators([Validators.required, LatLonUtils.latValidator(WPT_FORMATS.DEG_MIN_SEC, "deg")]);
        this.latLonForm.controls.lonDeg.setValidators([Validators.required, LatLonUtils.lonValidator(WPT_FORMATS.DEG_MIN_SEC, "deg")]);
        this.latLonForm.controls.latMin.setValidators([Validators.required, LatLonUtils.latValidator(WPT_FORMATS.DEG_MIN_SEC, "min")]);
        this.latLonForm.controls.lonMin.setValidators([Validators.required, LatLonUtils.lonValidator(WPT_FORMATS.DEG_MIN_SEC, "min")]);
        this.latLonForm.controls.latSec.setValidators([Validators.required, LatLonUtils.latValidator(WPT_FORMATS.DEG_MIN_SEC, "sec")]);
        this.latLonForm.controls.lonSec.setValidators([Validators.required, LatLonUtils.lonValidator(WPT_FORMATS.DEG_MIN_SEC, "sec")]);
      }
      this.latLonForm.controls.latDeg.updateValueAndValidity();
      this.latLonForm.controls.latMin.updateValueAndValidity();
      this.latLonForm.controls.latSec.updateValueAndValidity();
      this.latLonForm.controls.lonDeg.updateValueAndValidity();
      this.latLonForm.controls.lonMin.updateValueAndValidity();
      this.latLonForm.controls.lonSec.updateValueAndValidity();

      this.formatSelected = value;

      const lat = LatLonUtils.convertToDegrees(this.latLonForm.controls.latDeg.value, this.latLonForm.controls.latMin.value, this.latLonForm.controls.latSec.value);
      const lon = LatLonUtils.convertToDegrees(this.latLonForm.controls.lonDeg.value, this.latLonForm.controls.lonMin.value, this.latLonForm.controls.lonSec.value);
      if (lat != null && lon != null) {
        this.formatLatLon(lat, lon);
      }
    });
  }
}
