import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flyPadStart'
})
export class PadStartPipe implements PipeTransform {

  transform(value: any, length: number, padStr: string = '0', alwaysSign: boolean = false, decimalPlaces?: number): string {
    if (value == null) {
      return '';
    }
    if (typeof value === 'number') {
      const valueStr = decimalPlaces === undefined ? Math.abs(value).toString() : Math.abs(value).toFixed(decimalPlaces);
      if (value < 0 || alwaysSign) {
        return `${value < 0 ? '-' : '+'}${valueStr.padStart(length-1, padStr)}`;
      }
      return `${valueStr.padStart(length, padStr)}`;
    } else if (typeof value === 'bigint') {
      const absValue = value < 0 ? -value : value;
      if (value < 0 || alwaysSign) {
        return `${value < 0 ? '-' : '+'}${absValue.toString().padStart(length-1, padStr)}`;
      }
      return `${absValue.toString().padStart(length, padStr)}`;
    } else {
      return value.toString().padStart(length, padStr);
    }
  }
}
