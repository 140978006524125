import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilingStatusPipe } from './filing-status.pipe';


@NgModule({
  declarations: [
    FilingStatusPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FilingStatusPipe
  ]
})
export class FilingStatusModule { }
