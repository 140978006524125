import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtils } from '@shared/utils/date-time/date-time-utils';

@Pipe({
  name: 'flyLocalTimeTimezone'
})
export class LocalTimeTimezonePipe implements PipeTransform {

  transform(unixTime: number): string {
    return DateTimeUtils.unixToLocalTimeString(unixTime);
  }
}
