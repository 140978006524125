/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PerformanceInfo } from './performance-info.model';


/**
 * Data specific to the RouteV2 object
 */
export interface RouteV2Data { 
    /**
     * List of client types that created/modified this route
     */
    routeClientTypes?: Array<RouteV2DataRouteClientTypes>;
    /**
     * ARINC702A String representation, with Garmin Extensions, of the Route
     */
    arinc702AString?: string;
    /**
     * Target indicated airspeed in knots
     */
    indicatedAirSpeedKnots?: number;
    /**
     * Distance of the entire route, in meters
     */
    distanceMeters?: number;
    performanceInfo?: PerformanceInfo;
    /**
     * Flight direction
     */
    flightDirection?: RouteV2DataFlightDirection;
}
export enum RouteV2DataRouteClientTypes {
    IOS_GP = 'IOS_GP',
    ANDROID_GP = 'ANDROID_GP',
    WEB_CLIENT = 'WEB_CLIENT',
    ORCHESTRATOR_SERVICE = 'ORCHESTRATOR_SERVICE'
};
export enum RouteV2DataFlightDirection {
    EAST = 'EAST',
    WEST = 'WEST'
};



