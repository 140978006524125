import { Component, DestroyRef, inject, input, effect } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouteComputedLeg } from '@generated/flight-orchestrator-service';
import { FlightRouteControllerService, LatLonRequest, RouteComputedLegLocationType } from '@generated/flight-route-service';
import { SearchResult } from '@shared/components/search/search.component';
import { SettingsWaypointsWaypointModel } from '@shared/models/settings/waypoints/settings-waypoints-waypoint.model';
import { Unit, UnitAbbreviations, UnitConverterPipe } from '@shared/pipes/unit-converter/unit-converter.pipe';
import { INTEGER_ERROR_STRING } from '@shared/validators/integer';
import { SettingsWaypointsService } from '../../settings-waypoints.service';
@Component({
  selector: 'pilot-settings-brg-dis-tab',
  templateUrl: './settings-brg-dis-tab.component.html',
  styleUrls: ['./settings-brg-dis-tab.component.scss']
})

export class SettingsBrgDisTabComponent {
  waypoint = input<SettingsWaypointsWaypointModel | undefined> (undefined);
  private readonly settingsWaypointService = inject(SettingsWaypointsService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly flightRouteService = inject(FlightRouteControllerService);
  private readonly unitConverterPipe = new UnitConverterPipe();

  readonly UnitAbbreviations = UnitAbbreviations;
  readonly brgDisForm = this.settingsWaypointService.brgDisForm;
  private readonly latLonRequest: LatLonRequest = {waypoint1:{identifier: ''}};
  loadedWaypoint: RouteComputedLeg | null;

  constructor() {
    effect(() => {
      this._loadForm(this.waypoint());
    });
  }

  errorStrings = {
    required: 'Required Value.',
    ...INTEGER_ERROR_STRING
  };

  _loadForm(waypoint: SettingsWaypointsWaypointModel | undefined): void {
    if (waypoint?.name != null && waypoint?.waypointJson['referenceWaypoint1Identifier'] != null) {
      this.latLonRequest.waypoint1 = {
        identifier: waypoint.waypointJson['referenceWaypoint1Identifier'],
        countryCode: waypoint.waypointJson['referenceWaypoint1Qualifier'],
        locationType: waypoint.waypointJson['referenceWaypoint1LocationType'],
        lat: waypoint.lat,
        lon: waypoint.lon
      };
      this.loadedWaypoint = this.latLonRequest.waypoint1;
      this.flightRouteService.reverseLatLongSearchPost(this.latLonRequest).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((response) => {
          if (response.bearing1 != null && response.distance1 != null) {
            if (response.bearing1 < 0) {
              response.bearing1 = response.bearing1 + 360;
            }
            this.brgDisForm.controls.rad.setValue(response.bearing1);
            this.brgDisForm.controls.dist.setValue(this.unitConverterPipe.transform(response.distance1, Unit.METER, Unit.NAUTICAL_MILE));
          }
        });
    } else {
      this.loadedWaypoint = null;
    }
  }

  saveWaypoint(chosenWaypoint: SearchResult): void {
    if (chosenWaypoint.airport != null && chosenWaypoint.airport.icao != null) {
      this.brgDisForm.patchValue({
        locationType: RouteComputedLegLocationType.AIRPORT,
        identifier: chosenWaypoint.airport.icao,
        qualifier: chosenWaypoint.airport.name,
        lat: chosenWaypoint.airport.lat,
        lon: chosenWaypoint.airport.lon
      });
    } else if (chosenWaypoint.waypoint != null && chosenWaypoint.waypoint.countryCode != null &&
      chosenWaypoint.waypoint.locationType != null && chosenWaypoint.waypoint.lat != null && chosenWaypoint.waypoint.lon != null) {
      this.brgDisForm.patchValue({
        locationType: chosenWaypoint.waypoint.locationType,
        identifier: chosenWaypoint.waypoint.identifier,
        qualifier: chosenWaypoint.waypoint.countryCode,
        lat: chosenWaypoint.waypoint.lat,
        lon: chosenWaypoint.waypoint.lon
      });
    }
  }
}
