import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtils } from '@shared/utils/date-time/date-time-utils';

@Pipe({
  name: 'flyLocalDateTimeTimezone'
})
export class LocalDateTimeTimezonePipe implements PipeTransform {

  transform(unixTime: number): string {
    return DateTimeUtils.unixToLocalDateTimeString(unixTime);
  }
}
