<a
  [class.primary]="primary()"
  [title]="text()"
  [routerLink]="locked() ? null : path()"
  [routerLinkActiveOptions]="isActiveOptions()"
  routerLinkActive="active">
    <fly-icon [icon]="icon()"></fly-icon>
    @if (!iconOnly()) {
      <span
        [class.h6]="primary()">
        {{ text() }}
      </span>
      @if (locked()) {
        <fly-icon [icon]="Icons.Lock"></fly-icon>
      }
    }
</a>
