import { Pipe, PipeTransform } from '@angular/core';

/**
   Specifies whether the type of the coordinate component.
*/
export enum CoordType {
  LATITUDE = 'NS', // + is North, - is South
  LONGITUDE = 'EW' // + is East, - is West
}

@Pipe({
  name: 'flyCoordinate'
})
export class CoordinatePipe implements PipeTransform {

  transform(value: number, type: CoordType, format: string = 'hd.ddd\u{00B0}'): string {
    /*
      Format string parsing:
      - Match against the parser regex
        - At the string start, match 'h' always
        - deg group: match 'd+', then either match the remainder to '.d+(deg)' or
          should be followed by '(deg)m'
        - min group: match 'm+', then either match the remainder to '.m+'' or
          should be followed by ''s'
        - sec group: match 's+.s+"'
        - Ensure the string is fully consumed
      - The match groups provide the lengths to use for each degree/minute/second
        sub-component in the resulting string. The fdeg/fmin/fsec optional groups
        indicate the digits past the decimal point for the respective type.
    */
    const formatParser = /^h(?<deg>d+(?:\.(?<fdeg>d+(?=\u00B0$))|(?=\u00B0m)))\u00B0(?:(?<min>m+(?:\.(?<fmin>m+(?='$))|(?='s)))')?(?:(?<sec>s+\.(?<fsec>s+(?="$)))")?$/;

    const formatMatch = formatParser.exec(format);

    if (formatMatch?.groups == null) {
      throw new Error(`Invalid format string: '${format}'`);
    }

    const degreesFrac = value >= 0 ? value : -value;
    const minutesFrac = (degreesFrac * 60) % 60;
    const secondsFrac = (minutesFrac * 60) % 60;
    const markers = ['\u00B0', '\'', '"'];
    const parts: Array<string | null> = [];

    parts.push(this.formatForDMS(degreesFrac, formatMatch.groups['deg']?.length, formatMatch.groups['fdeg']?.length));
    parts.push(this.formatForDMS(minutesFrac, formatMatch.groups['min']?.length, formatMatch.groups['fmin']?.length));
    parts.push(this.formatForDMS(secondsFrac, formatMatch.groups['sec']?.length, formatMatch.groups['fsec']?.length));

    return parts.reduce(
      (result: string, part, idx) => part != null ? result.concat(part, markers[idx]) : result,
      type.charAt(value >= 0 ? 0 : 1)
    );
  }

  /**
     Format the number according to the desired overall length and decimal digits.

     @param [in] value            The number to format.
     @param [in] totalLength      The total length of the resulting string.
     @param [in] decimalDigits    The number of digits past the decimal point.

     @return If totalLength is undefined, null is returned. Otherwise, a string
     is returned, containing the input number formatted according to the desired
     overall length and digits past the decimal point.
  */
  private formatForDMS(value: number, totalLength?: number, decimalDigits?: number): string | null {
    if (totalLength == null) {
      return null;
    } else {
      // For each component, we need to determine whether to round (for decimals)
      // or truncate (a smaller subdivision will come next).
      const displayNum = decimalDigits == null ? Math.trunc(value) : value;

      return displayNum.toFixed(decimalDigits).padStart(totalLength, '0');
    }
  }
}
