/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RouteLeg } from './route-leg.model';


/**
 * List of modifications to be performed on the route.
 */
export interface RouteModification { 
    /**
     * Possible actions that can be performed to modify a route
     */
    modification: RouteModificationModification;
    /**
     * The index of the leg the action is to be performed on. For ADD operations, the new leg will be added at the index and all existing legs will be shifted by 1 index. DELETE operations to not need an accompanying operand.
     */
    routeLegIndex: number;
    operand: RouteLeg;
}
export enum RouteModificationModification {
    ADD = 'ADD',
    DELETE = 'DELETE',
    REPLACE = 'REPLACE'
};



