import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlyCardModule } from '@garmin-avcloud/avcloud-ui-common/card';
import { Icon, FlightIcons, Icons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import { isStringNonEmpty } from '@garmin-avcloud/avcloud-web-utils';
import { RouteComputedLeg } from '@generated/flight-route-service';
import { getWaypointIcon } from '@shared/components/search/search.utils';

@Component({
  selector: 'pilot-search-card',
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss'],
  standalone: true,
  imports: [CommonModule, FlyCardModule, FlyIconModule]
})

export class SearchCardComponent implements OnInit {

  @Input() showNextArrow?: boolean;
  @Input() waypoint: RouteComputedLeg;
  @Output() readonly selected = new EventEmitter<SearchCardComponent>();

  readonly FlightIcons = FlightIcons;
  readonly Icons = Icons;
  icon: Icon;
  isSelected = false;
  displayedSubName: string;

  ngOnInit(): void {
    this.icon = getWaypointIcon(
      {
        type: this.waypoint.locationType ?? '',
        identifier: this.waypoint.identifier,
        navaidType: this.waypoint.navaidType
      }
    ) as Icon;
    // TODO appropriate casing below
    this.displayedSubName = this.waypoint.locationType ?? this.waypoint.displayName ?? '';
    if (isStringNonEmpty(this.displayedSubName) && isStringNonEmpty(this.waypoint.countryCode)) {
      this.displayedSubName = this.displayedSubName.concat(' - ', this.waypoint.countryCode!);
    }
  }

  onSelect(): void {
    this.selected.emit(this);
    this.isSelected = true;
  }

  unselect(): void {
    this.isSelected = false;
  }

}
