import { Pipe, PipeTransform } from '@angular/core';
import { FlightFilingStatusValue } from '@generated/flight-orchestrator-service';

type FilingStatusColor = 'default' | 'success' | 'info' | 'pending' | 'error';

@Pipe({
  name: 'flyFilingStatus'
})
export class FilingStatusPipe implements PipeTransform {
  private readonly FILING_STATUS_DISPLAY_MAP: Record<FlightFilingStatusValue, string> = {
    [FlightFilingStatusValue.OVERDUE]: 'Overdue',
    [FlightFilingStatusValue.ACTIVATED]: 'Activated',
    [FlightFilingStatusValue.CLOSED]: 'Closed',
    [FlightFilingStatusValue.FILED]: 'Filed',
    [FlightFilingStatusValue.NOT_FILED]: 'Not Filed',
    [FlightFilingStatusValue.REJECTED]: 'Rejected',
    [FlightFilingStatusValue.UNCONFIRMED]: 'Unconfirmed',
    [FlightFilingStatusValue.SCHEDULED]: 'Will Be Filed',
    [FlightFilingStatusValue.COMPLETED]: 'Completed',
    [FlightFilingStatusValue.IN_FLIGHT]: 'In Flight',
    [FlightFilingStatusValue.ATC_PROPOSED]: 'ATC Proposed'
  };

  private readonly FILING_STATUS_COLOR_MAP: Record<FlightFilingStatusValue, FilingStatusColor> = {
    [FlightFilingStatusValue.OVERDUE]: 'error',
    [FlightFilingStatusValue.ACTIVATED]: 'success',
    [FlightFilingStatusValue.CLOSED]: 'info',
    [FlightFilingStatusValue.FILED]: 'success',
    [FlightFilingStatusValue.NOT_FILED]: 'default',
    [FlightFilingStatusValue.REJECTED]: 'info',
    [FlightFilingStatusValue.UNCONFIRMED]: 'error',
    [FlightFilingStatusValue.SCHEDULED]: 'pending',
    [FlightFilingStatusValue.COMPLETED]: 'info',
    //TODO: Set these colors up
    [FlightFilingStatusValue.IN_FLIGHT]: 'info',
    [FlightFilingStatusValue.ATC_PROPOSED]: 'info'
  };

  transform(filingStatus?: FlightFilingStatusValue, getColor?: 'getColor'): string {
    if (filingStatus == null) {
      return getColor ? 'error' : 'Error';
    }
    return getColor
      ? this.FILING_STATUS_COLOR_MAP[filingStatus]
      : this.FILING_STATUS_DISPLAY_MAP[filingStatus];
  }

}
