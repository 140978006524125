import { Component, EventEmitter, Injectable, Output } from '@angular/core';
import { Icons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { CookieService } from '@garmin-avcloud/avcloud-web-utils';
import { SearchFormComponent } from '../../subcomponents/search-form.component';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'pilot-recents-tab',
  templateUrl: './recents-tab.component.html',
  styleUrls: ['./recents-tab.component.scss']
})
export class RecentsTabComponent{
  @Output() readonly recentSelected = new EventEmitter<string>();
  cookieToSave: string[] = [];
  recentSearch: string;

  readonly icons = {
    plus: Icons.Plus
  };

  constructor(
    readonly cookieService: CookieService
  ) {
    this.saveSlot();
  }

  saveSlot(): void {
    if (this.cookieService.has(SearchFormComponent.keyConst)) {
      this.recentSearch = JSON.stringify(this.cookieService.get(SearchFormComponent.keyConst)).replace(/"/g, '');
      this.recentSearch = this.recentSearch.replace(/\\/g, '');
      const cookieArray = this.recentSearch.split(',');
      for (const cook of cookieArray) {
        this.cookieToSave.push(cook);
      }
    }
  }

  handleCookieSelected(searchStr: string): void {
    this.recentSelected.emit(searchStr);
  }

}
