<div class="rad-rad">
  <form class="form"
    [formGroup]="radRadForm">
    <pilot-settings-search class="search-component"
      label="Waypoint"
      [loadedWaypoint]="loadedWaypoint1"
      (waypointSent)="saveWaypoint($event, 1)">
    </pilot-settings-search>
    <fly-input formControlName="rad1"
      label="Radial 1"
      [errorStrings]="errorStrings"
      placeholder="Required"
      suffix="°M">
    </fly-input>
  <pilot-settings-search class="search-component"
    label="Waypoint"
    [loadedWaypoint]="loadedWaypoint2"
    (waypointSent)="saveWaypoint($event, 2)">
  </pilot-settings-search>
    <fly-input formControlName="rad2"
      label="Radial 2"
      [errorStrings]="errorStrings"
      placeholder="Required"
      suffix="°M">
    </fly-input>
  </form>
</div>
