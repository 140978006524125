import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

import { AirportModel } from '@shared/models/airport/airport.model';
import { NotamsResponse } from '@shared/models/airport/cloudflare/airports/notam/notams.model';
import { AirportSectionFetcher } from '@shared/services/airport/airport.service';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotamsService implements AirportSectionFetcher {
  private readonly airportServiceURL: string = environment.garmin.aviation.workerApiHost;

  private readonly _cache = new Map<string, Observable<AirportModel | null>>();

  constructor(private readonly http: HttpClient) {}

  getSectionDataById(airportId: string): Observable<AirportModel | null> {
    const upperAirportId = airportId?.toUpperCase();
    if (!this._cache.has(upperAirportId)) {
      this._cache.set(
        upperAirportId,
        this.http
          .get<NotamsResponse>(`${this.airportServiceURL}/v1/airports/${upperAirportId}/notams`, {
          withCredentials: true
        })
          .pipe(
            map((response) => ({ notamsResponse: response })),
            catchError((_error) => of(null)),
            shareReplay(1)
          )
      );
    }
    return this._cache.get(upperAirportId)!;
  }
}
