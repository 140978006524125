import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { NgModule, EventEmitter, inject, Injector, ElementRef, ChangeDetectorRef, Directive, Input } from '@angular/core';
import { OverlayPositionBuilder, Overlay } from '@angular/cdk/overlay';
import { NgControl, FormControlName, FormGroupDirective } from '@angular/forms';
import { Identifiable, FlySizing, ZIndexService } from '@garmin-avcloud/avcloud-ui-common/shared';
import { Subscription, startWith, tap, switchMap, merge, map, filter } from 'rxjs';
class FlyOptionsBaseModule {
  static {
    this.ɵfac = function FlyOptionsBaseModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyOptionsBaseModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyOptionsBaseModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyOptionsBaseModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule],
      exports: []
    }]
  }], null, null);
})();

/**
 * Base component that controls the opening and closing of a list of options for a form control.
 */
class OptionsBaseComponent extends Identifiable {
  get lastFocusedIndex() {
    return this._lastFocusedIndex;
  }
  constructor() {
    super('');
    this.size = FlySizing.Medium;
    this.internalSelected = new EventEmitter();
    this.multiselectEnabled = false;
    this.handleSpace = true;
    this.optionsVisible = false;
    this.clickedInside = false;
    this.positions = [{
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top'
    }, {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top'
    }, {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom'
    }, {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom'
    }];
    this.FlySizing = FlySizing;
    this.selectedOptions = new Set();
    this.lastSelectedIndex = -1;
    this.valueSelected = false;
    this.activeOptionRefs = [];
    this.panelSubscription = new Subscription();
    this.overlayAttached = false;
    this._subscription$ = new Subscription();
    this.injector = inject(Injector);
    this._lastFocusedIndex = 0;
    /**
     * @deprecated use toFormValue and toDisplayValue for clarity
     * @param value
     * @returns string representation of the value
     */
    this.stringify = value => value.toString();
    this.valueComparer = (a, b) => a === b;
    this.formValueComparer = (formValue, flyValue) => formValue === flyValue;
    this.elementRef = this.injector.get(ElementRef);
    this.changeDetectorRef = this.injector.get(ChangeDetectorRef);
    this.zIndexer = this.injector.get(ZIndexService);
    this.overlayPositionBuilder = this.injector.get(OverlayPositionBuilder);
    this.overlayService = this.injector.get(Overlay);
  }
  ngOnInit() {
    this.identifier = this.id;
    this.id = '';
    super.ngOnInit();
    const ngControl = this.injector.get(NgControl);
    if (ngControl instanceof FormControlName) {
      this.formControl = this.injector.get(FormGroupDirective).getControl(ngControl);
    } else {
      this.formControl = ngControl.form;
    }
    this.formControl.updateValueAndValidity({
      emitEvent: false
    });
    if (this.toFormValue == null) {
      this.toFormValue = this.stringify;
    }
    if (this.toDisplayValue == null) {
      this.toDisplayValue = this.toFormValue;
    }
  }
  ngOnChanges(changes) {
    if (changes.hasOwnProperty('multiselectEnabled') && !changes['multiselectEnabled']?.isFirstChange() && changes['multiselectEnabled'] != null) {
      for (const option of this.optionRefs) {
        option.multiselectEnabled = this.multiselectEnabled;
      }
    }
  }
  ngAfterContentInit() {
    this._subscription$.add(this.optionRefs.changes.pipe(startWith(this.optionRefs), tap(() => this.activeOptionRefs.at(this._lastFocusedIndex)?.blur()), tap(() => this.lastSelectedIndex = -1), tap(optionRefs => this.activeOptionRefs = optionRefs.filter(option => !option.disabled)), tap(() => this.focusFirstOption(new Event('filter-update'), false)), tap(optionRefs => optionRefs?.forEach(element => {
      element.multiselectEnabled = this.multiselectEnabled;
      switch (this.size) {
        case FlySizing.Large:
          element.elementRef.nativeElement.classList.add('fly-input-size-large');
          break;
        default:
        case FlySizing.Medium:
          element.elementRef.nativeElement.classList.add('fly-input-size-medium');
          break;
      }
    })), tap(() => this.overlay?.overlayRef?.updatePosition()), switchMap(() => merge(...this.activeOptionRefs.map((option, index) => option.selected.pipe(map(value => {
      this.changeOptionFocus(this._lastFocusedIndex, index);
      if (!this.multiselectEnabled) {
        this.selectedOptions.forEach(value2 => {
          this.activeOptionRefs.find(option2 => option != option2 && option2.flyValue == value2)?.unselectQuietly();
        });
        this.selectedOptions.clear();
      }
      this.selectedOptions.add(value);
      this.lastSelectedIndex = index;
      return this.multiselectEnabled ? [...this.selectedOptions] : this.selectedOptions.size > 0 ? this.selectedOptions.values().next().value : null;
    }))), ...this.activeOptionRefs.map((option, index) => option.unselected.pipe(map(value => {
      this.selectedOptions.delete(value);
      this.lastSelectedIndex = index;
      return this.multiselectEnabled ? [...this.selectedOptions] : this.selectedOptions.size > 0 ? this.selectedOptions.values().next().value : null;
    })))))).subscribe(value => {
      this.internalSelected.emit(value);
    }));
    this._subscription$.add(this.formControl.valueChanges.subscribe(() => {
      this.valueSelected = false;
      // There might be a change in error messaging that shifts the modal or page layout, but it won't happen until after everything updates.
      setTimeout(() => this.overlay?.overlayRef?.updatePosition(), 0);
    }));
    this._subscription$.add(this.internalSelected.subscribe(() => {
      this.valueSelected = true;
    }));
  }
  ngAfterViewInit() {
    this.nativeInput = this.elementRef.nativeElement.querySelector('input');
    this.nativeInput?.setAttribute('autocomplete', 'off');
    this.overlay.origin = this.overlayOriginOverride?.nativeElement ?? this.nativeInput;
    this.positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.overlay.origin).withGrowAfterOpen(true).withPush(false).withFlexibleDimensions(false).withViewportMargin(50).withPositions(this.positions);
    this.scrollStrategy = this.overlayService.scrollStrategies.reposition({
      scrollThrottle: 0,
      autoClose: true
    });
    this.changeDetectorRef.detectChanges();
  }
  // eslint-disable-next-line complexity
  onKeyDownHandler(event) {
    if (!this.optionsVisible) {
      if (event.code === 'ArrowDown') {
        event.preventDefault();
        this.focusFirstOption(event);
      } else if (event.code === 'ArrowUp') {
        event.preventDefault();
        this.focusFirstOption(event);
      } else if (event.code === 'Enter' || event.code === 'Space' && this.handleSpace) {
        event.preventDefault();
        this.focusFirstOption(event);
      } else if (event.code === 'Backspace' || event.code === 'Delete') {
        this.optionsVisible = true;
      }
    } else {
      const optionsContainerElement = this.optionsContainer?.nativeElement;
      if (event.code === 'ArrowDown') {
        event.preventDefault();
        this.focusNextOption();
      } else if (event.code === 'ArrowUp') {
        event.preventDefault();
        this.focusPreviousOption();
      } else if (event.code === 'Enter' || event.code === 'Space' && this.handleSpace) {
        event.preventDefault();
        event.stopPropagation();
        if (this.activeOptionRefs.length > 0) {
          this.activeOptionRefs.at(this._lastFocusedIndex)?.toggleSelect();
        }
      } else if (event.code === 'Home') {
        event.preventDefault();
        this.focusFirstOption(event);
      } else if (event.code === 'End') {
        event.preventDefault();
        this.focusLastOption(event);
      } else if (event.code === 'PageUp') {
        event.preventDefault();
        event.stopPropagation();
        const activeElem = this.activeOptionRefs.at(this._lastFocusedIndex)?.elementRef.nativeElement;
        // Scroll so the old active element is at the bottom.
        optionsContainerElement.scrollTo(0, Math.max(activeElem.offsetTop - optionsContainerElement.offsetHeight + activeElem.offsetHeight, 0));
        // Find the first element in the displayed area.
        let i = this._lastFocusedIndex - 1;
        while (i >= 0 && this.activeOptionRefs.at(i)?.elementRef.nativeElement.offsetTop >= optionsContainerElement.scrollTop) {
          i -= 1;
        }
        this.changeOptionFocus(this._lastFocusedIndex, i + 1, false);
      } else if (event.code === 'PageDown') {
        event.preventDefault();
        event.stopPropagation();
        const activeElem = this.activeOptionRefs.at(this._lastFocusedIndex)?.elementRef.nativeElement;
        // Scroll so the old active element is at the top.
        optionsContainerElement.scrollTo(0, activeElem.offsetTop);
        // Find last element in the displayed area.
        let i = this._lastFocusedIndex + 1;
        while (i < this.activeOptionRefs.length && (this.activeOptionRefs.at(i)?.elementRef.nativeElement).offsetTop + (this.activeOptionRefs.at(i)?.elementRef.nativeElement).offsetHeight <= optionsContainerElement.scrollTop + optionsContainerElement.offsetHeight) {
          i += 1;
        }
        this.changeOptionFocus(this._lastFocusedIndex, i - 1, false);
      } else if (event.code === 'Escape') {
        this.hideOptions();
      }
    }
    if (event.code === 'Tab') {
      this.hideOptions();
    }
  }
  ngOnDestroy() {
    this._subscription$.unsubscribe();
  }
  focusPreviousOption() {
    if (this._lastFocusedIndex > 0) {
      this.changeOptionFocus(this._lastFocusedIndex, this._lastFocusedIndex - 1);
    }
  }
  focusNextOption() {
    if (this._lastFocusedIndex < this.activeOptionRefs.length - 1) {
      this.changeOptionFocus(this._lastFocusedIndex, this._lastFocusedIndex + 1);
    }
  }
  focusFirstOption(event, showOptions = true) {
    event.preventDefault();
    event.stopPropagation();
    if (showOptions) {
      this.showOptions(event);
    }
    this.changeOptionFocus(this._lastFocusedIndex, 0);
  }
  focusLastOption(event) {
    event.preventDefault();
    event.stopPropagation();
    this.showOptions(event);
    this.changeOptionFocus(this._lastFocusedIndex, this.activeOptionRefs.length - 1);
  }
  hideOptions() {
    this.optionsVisible = false;
  }
  showOptions(event) {
    event.stopPropagation();
    this.optionsVisible = true;
  }
  toggleOptions() {
    if (this.optionsVisible) {
      this.hideOptions();
    } else {
      this.showOptions(new Event('dummy'));
    }
  }
  onFocus(event) {
    const eventOrigin = event.composedPath()[0];
    const fromHelperButton = eventOrigin.tagName === 'BUTTON';
    if (fromHelperButton) {
      return;
    }
    this.showOptions(event);
  }
  onAttachment() {
    this.clickedInside = true;
    this.overlayAttached = true;
    this.componentWidth = (this.overlayOriginOverride?.nativeElement ?? this.nativeInput).getBoundingClientRect().width;
    this.changeDetectorRef.detectChanges();
    let elem = this.overlay.overlayRef.overlayElement;
    while ((elem = elem.parentElement) != null) {
      if (elem.classList.contains('cdk-overlay-container')) {
        elem.style.zIndex = this.zIndexer.nextZIndex().toString();
        break;
      }
    }
    this.overlay.overlayRef.overlayElement.style.zIndex = this.zIndexer.nextZIndex().toString();
    this.panelSubscription.unsubscribe();
    this.panelSubscription = new Subscription();
    this._subscription$.add(this.panelSubscription);
    // Closing triggers - used to be more that turned out to be already handled by cdkConnectedOverlay, but some might be missing, so leaving merge in place
    this.panelSubscription.add(merge(this.overlay.overlayOutsideClick.pipe(filter(e => !(this.overlayOriginOverride?.nativeElement ?? this.nativeInput).contains(e.target))), this.internalSelected.pipe(filter(value => this.shouldCloseOnSelection(value)))).subscribe(() => {
      if (!this.clickedInside) {
        //prevent dragging mouse outside of search bar to hide options
        this.hideOptions();
      }
    }));
  }
  shouldCloseOnSelection(_) {
    return true;
  }
  onDetachment() {
    this.overlayAttached = false;
    this.optionsVisible = false;
  }
  onBlur() {
    this.clickedInside = false;
    if (!this.overlayAttached) {
      this.hideOptions();
    }
  }
  changeOptionFocus(oldIndex, newIndex, scroll = true) {
    this.activeOptionRefs.at(oldIndex)?.blur();
    this.activeOptionRefs.at(newIndex)?.focus();
    this._lastFocusedIndex = newIndex;
    if (scroll) {
      const activeElement = this.activeOptionRefs.at(newIndex)?.elementRef.nativeElement;
      if (oldIndex < newIndex) {
        // Going down
        if (activeElement?.offsetTop + activeElement?.offsetHeight > this.optionsContainer?.nativeElement.scrollTop + this.optionsContainer?.nativeElement.offsetHeight) {
          activeElement?.scrollIntoView(false);
        }
      } else {
        // Going up
        if (activeElement?.offsetTop < this.optionsContainer?.nativeElement.scrollTop) {
          activeElement?.scrollIntoView(true);
        }
      }
    }
  }
  static {
    this.ɵfac = function OptionsBaseComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || OptionsBaseComponent)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: OptionsBaseComponent,
      inputs: {
        id: "id",
        size: "size",
        multiselectEnabled: "multiselectEnabled",
        handleSpace: "handleSpace",
        stringify: "stringify",
        toFormValue: "toFormValue",
        toDisplayValue: "toDisplayValue",
        valueComparer: "valueComparer",
        formValueComparer: "formValueComparer"
      },
      features: [i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OptionsBaseComponent, [{
    type: Directive
  }], () => [], {
    id: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    multiselectEnabled: [{
      type: Input
    }],
    handleSpace: [{
      type: Input
    }],
    stringify: [{
      type: Input
    }],
    toFormValue: [{
      type: Input
    }],
    toDisplayValue: [{
      type: Input
    }],
    valueComparer: [{
      type: Input
    }],
    formValueComparer: [{
      type: Input
    }]
  });
})();

/*
 * Public API Surface of avcloud-ui-common/link
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlyOptionsBaseModule, OptionsBaseComponent };
