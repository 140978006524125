/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FlightSearchParams { 
    customerGuid?: string;
    pilotInCommandUuid?: string;
    aircraftTail?: string;
    callSign?: string;
    aircraftUuid?: string;
    updatedAfter?: string;
    departureAfter?: string;
    departureBefore?: string;
    includeDeleted?: boolean;
    flightPlanIdStatus?: FlightSearchParamsFlightPlanIdStatus;
    filedOnly?: boolean;
    maxFlightCount?: number;
    offset?: number;
    latitude?: number;
    longitude?: number;
    departure?: string;
    arrival?: string;
    departureBeforeAsString?: string;
    pilotInCommandUuidAsString?: string;
    aircraftUuidAsString?: string;
    updatedAfterAsString?: string;
    departureAfterAsString?: string;
}
export enum FlightSearchParamsFlightPlanIdStatus {
    NULL = 'NULL',
    NOT_NULL = 'NOT_NULL'
};



