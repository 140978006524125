import { Component, OnInit, Input } from '@angular/core';
import { Icon, FlightIcons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { RouteComputedLegLocationType, RouteComputedLegNavaidType, RouteLegLocationType, RouteLegNavaidType } from '@generated/flight-route-service';
import { MapIcons } from 'projects/avcloud-pilotweb/src/app/features/map/constants/map-icons';

@Component({
  selector: 'pilot-waypoint-icon',
  templateUrl: './waypoint-icon.component.html',
  styleUrls: ['./waypoint-icon.component.scss']
})
export class WaypointIconComponent implements OnInit {
  @Input() type?: RouteComputedLegLocationType | RouteLegLocationType;
  @Input() navaidType?: RouteComputedLegNavaidType | RouteLegNavaidType;
  icon: Icon;

  ngOnInit(): void {
    this.icon = this.resolveIcon();
  }

  resolveIcon(): Icon {
    let icon;
    switch (this.type) {
      case RouteComputedLegLocationType.AIRPORT: icon = FlightIcons.Airports.UntoweredServices; break;
      case RouteComputedLegLocationType.AIRWAY: icon = FlightIcons.Navaids.AIRWAY; break;
      case RouteComputedLegLocationType.ARRIVAL_PROCEDURE: icon = FlightIcons.Navaids.ARRIVAL_PROCEDURE; break;
      case RouteComputedLegLocationType.DEPARTURE_PROCEDURE: icon = FlightIcons.Navaids.DEPARTURE_PROCEDURE; break;
      case RouteComputedLegLocationType.NDB: icon = MapIcons.NAVAID.VFR.NDB; break;
      case RouteComputedLegLocationType.USER_WAYPOINT: icon = FlightIcons.Navaids.MANUAL_WAYPOINT; break;
      case RouteComputedLegLocationType.VOR:
        switch (this.navaidType) {
          case RouteLegNavaidType.DME_ONLY: icon = MapIcons.NAVAID.VFR.DME; break;
          case RouteLegNavaidType.TACAN_ONLY: icon = MapIcons.NAVAID.VFR.TACAN; break;
          case RouteLegNavaidType.VOR_DME: icon = MapIcons.NAVAID.VFR.VOR_DME; break;
          case RouteLegNavaidType.VOR_TAC: icon = MapIcons.NAVAID.VFR.VORTAC; break;
          default: icon = MapIcons.NAVAID.VFR.VOR;
        }
        break;
      default: icon = MapIcons.NAVAID.VFR.WPT; break;
    }
    return icon;
  }
}
