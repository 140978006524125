<div
  class="popup"
  *ngIf="showPopup">
  <fly-icon
    class="popup-extension"
    [icon]="Icons.ArrowLeft"></fly-icon>
  <div class="popup-body">
    <button
      flyButton
      [options]="{ style: 'ghost' }"
      *ngIf="routeStage !== RouteStageType.ORIGIN"
      (click)="hideAndEmit(addBefore)">
      <p>Insert Before</p>
      <fly-icon [icon]="Icons.Plus"></fly-icon>
    </button>
    <button
      flyButton
      [options]="{ style: 'ghost' }"
      *ngIf="routeStage !== RouteStageType.DESTINATION"
      (click)="hideAndEmit(addAfter)">
      <p>Insert After</p>
      <fly-icon [icon]="Icons.Plus"></fly-icon>
    </button>
    <button
      flyButton
      [options]="{ style: 'ghost' }"
      *ngIf="routeStage === RouteStageType.ENROUTE"
      (click)="hideAndEmit(replace)">
      <p>Replace Waypoint</p>
      <fly-icon [icon]="Icons.ArrowRight"></fly-icon>
    </button>
    <button
      flyButton
      [options]="{ style: 'ghost' }"
      *ngIf="routeStage === RouteStageType.ORIGIN && hasDepartureProcedures"
      (click)="hideAndEmit(addProcedure, ProcedurePickerType.DEPARTURE)">
      <p>Add Departure</p>
      <fly-icon [icon]="Icons.Plus"></fly-icon>
    </button>
    <button
      flyButton
      [options]="{ style: 'ghost' }"
      *ngIf="routeStage === RouteStageType.ENROUTE && hasAirways"
      (click)="hideAndEmit(loadAirway)">
      <p>{{ nextLegIsAirway ? 'Change' : 'Load' }} Airway</p>
      <fly-icon [icon]="Icons.ArrowRight"></fly-icon>
    </button>
    <button
      flyButton
      [options]="{ style: 'ghost' }"
      *ngIf="routeStage === RouteStageType.DESTINATION && hasArrivalProcedures"
      (click)="hideAndEmit(addProcedure, ProcedurePickerType.ARRIVAL)">
      <p>Add Arrival</p>
      <fly-icon [icon]="Icons.Plus"></fly-icon>
    </button>
    <button
      flyButton
      [options]="{ style: 'dangerghost' }"
      *ngIf="routeStage === RouteStageType.ENROUTE"
      (click)="hideAndEmit(remove)">
      Remove Waypoint
      <fly-icon [icon]="Icons.Trash"></fly-icon>
    </button>
  </div>
</div>
