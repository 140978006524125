import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';

@Component({
  selector: 'pilot-upgrade-subscription',
  standalone: true,
  imports: [CommonModule, FlyButtonModule],
  templateUrl: './upgrade-subscription.component.html',
  styleUrl: './upgrade-subscription.component.scss'
})
export class UpgradeSubscriptionComponent {

}
