/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RouteLegNavData } from './route-leg-nav-data.model';
import { RouteLegPerfData } from './route-leg-perf-data.model';
import { Frequency } from './frequency.model';
import { RouteComputedLeg } from './route-computed-leg.model';


/**
 * Data specific to the RoutePointV2 (the FlightRouteService RouteLeg) object
 */
export interface RoutePointV2Data { 
    /**
     * String representation of the route leg. Can be identifier, or procedures/airways with a dot notation
     */
    displayString?: string;
    /**
     * Location type (following the Unified Route Model enum definition)
     */
    locationType: RoutePointV2DataLocationType;
    /**
     * Second altitude value in feet, used only when the route leg involves an altitude climb
     */
    secondAltitudeFt?: number;
    /**
     * Country description
     */
    countryDesc?: string;
    navData?: RouteLegNavData;
    perfData?: RouteLegPerfData;
    /**
     * Child route legs. Only used for airways and procedures
     */
    childRouteLegs?: Array<RouteComputedLeg>;
    frequency?: Frequency;
    /**
     * Display name for the route leg
     */
    displayName?: string;
    /**
     * Morse code for the identifier of this route leg
     */
    morseCode?: string;
}
export enum RoutePointV2DataLocationType {
    AIRPORT = 'AIRPORT',
    INTERSECTION = 'INTERSECTION',
    NDB = 'NDB',
    VOR = 'VOR',
    USER_WAYPOINT = 'USER_WAYPOINT',
    RUNWAY = 'RUNWAY',
    AIRPORT_INTERSECTION = 'AIRPORT_INTERSECTION',
    AIRPORT_NDB = 'AIRPORT_NDB',
    AIRPORT_ILS = 'AIRPORT_ILS',
    GLS_MARKER = 'GLS_MARKER',
    UNIDENTIFIED = 'UNIDENTIFIED',
    INVALID = 'INVALID',
    LOCALIZER_MARKER = 'LOCALIZER_MARKER',
    CHART_OBSTACLE = 'CHART_OBSTACLE',
    CHART_TERRAIN = 'CHART_TERRAIN',
    VOR_CLASS = 'VOR_CLASS',
    FIX_REFERENCE_CLASS = 'FIX_REFERENCE_CLASS',
    ROUTE_WAYPOINT = 'ROUTE_WAYPOINT',
    LAT_LON = 'LAT_LON',
    ALONG_TRACK = 'ALONG_TRACK',
    PARALLEL_TRACK = 'PARALLEL_TRACK',
    VNAV_WAYPOINT = 'VNAV_WAYPOINT',
    DEPARTURE_PROCEDURE = 'DEPARTURE_PROCEDURE',
    ARRIVAL_PROCEDURE = 'ARRIVAL_PROCEDURE',
    ENROUTE_TRANSITION = 'ENROUTE_TRANSITION',
    APPROACH_PROCEDURE = 'APPROACH_PROCEDURE',
    AIRWAY = 'AIRWAY',
    AIRWAY_ENTRY = 'AIRWAY_ENTRY',
    AIRWAY_EXIT = 'AIRWAY_EXIT',
    VRP = 'VRP',
    EMPTY = ''
};



