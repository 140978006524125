import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtils } from '@shared/utils/date-time/date-time-utils';

@Pipe({
  name: 'flyDayDateTimeRange'
})
export class DayDateTimeRangePipe implements PipeTransform {

  transform(start: number, end: number): string {
    return DateTimeUtils.timeRangeDayDateValues(start, end);
  }

}
