import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Output, TemplateRef, ViewChild, inject } from '@angular/core';
import { Icons } from '@garmin-avcloud/avcloud-ui-common/icon';
import { FlyModalComponent, ModalType } from '@garmin-avcloud/avcloud-ui-common/modal';
import { RouteComputedLeg, RouteLeg } from '@generated/flight-route-service';

@Component({
  selector: 'pilot-airway-modal',
  templateUrl: './airway-modal.component.html',
  styleUrls: ['./airway-modal.component.scss']
})
export class AirwayModalComponent implements AfterViewInit {

  @Output() readonly airwaySelected: EventEmitter<RouteLeg> = new EventEmitter();

  @ViewChild(FlyModalComponent) flyModal: FlyModalComponent;
  @ViewChild('selectAirwayRef') selectAirwayRef: TemplateRef<any>;
  @ViewChild('selectExitRef') selectExitRef: TemplateRef<any>;

  airways: RouteLeg[];
  selectedAirway: RouteLeg;
  exits: RouteComputedLeg[];

  currentView: TemplateRef<any>;
  hasBackButton: boolean = false;
  title: string = 'Select Airway';

  readonly Icons = Icons;
  readonly ModalType = ModalType;

  private readonly cdr = inject(ChangeDetectorRef);

  ngAfterViewInit(): void {
    this.currentView = this.selectAirwayRef;
    this.cdr.detectChanges();
  }

  goBack(): void {
    this.title = 'Select Airway';
    this.currentView = this.selectAirwayRef;
    this.hasBackButton = false;
  }

  hide(): void {
    this.flyModal.hide();
  }

  /**
   * Open the airway modal. NOTE: airways are populated on caller to
   * determine availability of caller's "Load Airway" menu option.
   * @param airways list of airways associated with the calling leg
   */
  open(airways: RouteLeg[]): void {
    this.goBack();
    this.airways = airways;
    this.flyModal.show();
  }

  /**
   * Select an airway and move on to the "Select Exit" view.
   * @param airway the selected airway
   */
  selectAirway(airway: RouteLeg): void {
    this.selectedAirway = airway;
    this.exits = airway.childLegs ?? [];
    this.title = `Select ${this.selectedAirway.identifier} Exit`;
    this.currentView = this.selectExitRef;
    this.hasBackButton = true;
  }

  /**
   * Emit a {@link RouteLeg} with the selected airway and exit.
   * @param exit the selected exit
   */
  selectExit(exit: RouteComputedLeg): void {
    this.airwaySelected.emit({
      ...this.selectedAirway,
      childLegs: [exit]
    });
    this.hide();
  }
}
