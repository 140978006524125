import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flyReplace',
  standalone: true
})
export class ReplacePipe implements PipeTransform {

  transform(value: string, regexValue: string, replaceValue: string): string {
    const regex = new RegExp(regexValue, 'g');
    return value.replace(regex, replaceValue);
  }
}
