import { Pipe, PipeTransform } from '@angular/core';
import { VisibilityCategory } from '@shared/enums/airports/visibility-category.enum';
import { Unit } from '@shared/pipes/unit-converter/unit-converter.pipe';

/**
   The mapping table between unit and the MOS visibility category string.

   The categories are defined via https://www.nws.noaa.gov/mdl/synop/mavcard.php
   which only provides a meaning in (statute) miles. Values in other units are
   derived from this, but are intentionally of limited precision, to reflect the
   limited precision in the original definitions.
*/
const unitVisibilityMap: Map<Unit, Record<VisibilityCategory, string>> = new Map([
  [Unit.METER, {
    [VisibilityCategory.UNDER_HALF_MILE]:   'Less than 800',
    [VisibilityCategory.HALF_TO_1_MILE]:    '800 to 1,600',
    [VisibilityCategory.FROM_1_TO_2_MILES]: '1,600 to 3,200',
    [VisibilityCategory.FROM_2_TO_3_MILES]: '3,200 to 4,800',
    [VisibilityCategory.FROM_3_TO_5_MILES]: '4,800 to 8,000',
    [VisibilityCategory.AT_6_MILES]:        '9,600',
    [VisibilityCategory.ABOVE_6_MILES]:     'Greater than 9,600'
  }],
  [Unit.STATUTE_MILE, {
    [VisibilityCategory.UNDER_HALF_MILE]:   'Less than \u{00BD}',
    [VisibilityCategory.HALF_TO_1_MILE]:    '\u{00BD} to 1',
    [VisibilityCategory.FROM_1_TO_2_MILES]: '1 to 2',
    [VisibilityCategory.FROM_2_TO_3_MILES]: '2 to 3',
    [VisibilityCategory.FROM_3_TO_5_MILES]: '3 to 5',
    [VisibilityCategory.AT_6_MILES]:        '6',
    [VisibilityCategory.ABOVE_6_MILES]:     'Greater than 6'
  }]
]);

/**
   A pipe for displaying the Visibility property from a MOS weather report
   in various units. This is a specialized form of the UnitConverterPipe for
   MOS weather reports to display visibility distances.
*/
@Pipe({
  name: 'flyMosVisibility'
})
export class MosVisibilityPipe implements PipeTransform {
  transform(value: VisibilityCategory, unit: Unit): string {
    const visibilityStringSet = unitVisibilityMap.get(unit);
    if (visibilityStringSet == null) {
      throw new Error('Unsupported unit for MOS visibility');
    }

    return visibilityStringSet[value];
  }
}
