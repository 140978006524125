/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AircraftPerfParams } from './aircraft-perf-params.model';
import { AircraftParams } from './aircraft-params.model';
import { Route } from './route.model';


export interface RouteRequest { 
    inputRoute: Route;
    /**
     * Flight Rules for this flight
     */
    flightRules: RouteRequestFlightRules;
    aircraftParams?: AircraftParams;
    aircraftPerfParams?: AircraftPerfParams;
}
export enum RouteRequestFlightRules {
    VFR = 'VFR',
    IFR = 'IFR',
    YFR = 'YFR',
    ZFR = 'ZFR',
    SFRA = 'SFRA'
};



