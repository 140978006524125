/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ADBCycleResponse } from './adb-cycle-response.model';


/**
 * Indicates if a Validation was successful or not.
 */
export interface ValidateResponseV2 { 
    /**
     * Validation Result status.
     */
    status: ValidateResponseV2Status;
    /**
     * Descriptive message accompanying the validation result
     */
    resultMessage: string;
    /**
     * Clearance string provided if the Route is valid.  The clearance string is FAA compatible, and adds the necessary \'DCT\' tokens required by the FAA for flights.
     */
    clearanceString?: string;
    adbCycleInfo: ADBCycleResponse;
}
export enum ValidateResponseV2Status {
    OK = 'OK',
    INVALID = 'INVALID'
};



