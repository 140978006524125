import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'flyRoundNum'
})
export class RoundNumPipe implements PipeTransform {

  transform(value: number, digits: number, dir: string='DOWN', alwaysSign: boolean=false): string {
    const round = dir.toUpperCase() === 'DOWN' && value >= 0? Math.floor : Math.ceil;
    const roundValue = round(value * (10 ** digits)) / (10 ** digits);
    if (roundValue >=0 && alwaysSign) {
      return `+${(roundValue).toString()}`;
    } else return `${(roundValue).toString()}`;
  }
}
