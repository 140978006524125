import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Directive, HostBinding, Output, HostListener, Component, Input, ViewChild, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import * as i4 from '@garmin-avcloud/avcloud-ui-common/icon';
import { Icons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import * as i3 from '@garmin-avcloud/avcloud-ui-common/link';
import { FlyLinkModule } from '@garmin-avcloud/avcloud-ui-common/link';
import * as i2 from '@garmin-avcloud/avcloud-ui-common/loading-spinner';
import { FlyLoadingSpinnerModule } from '@garmin-avcloud/avcloud-ui-common/loading-spinner';
import { Identifiable } from '@garmin-avcloud/avcloud-ui-common/shared';
const _c0 = ["fileSelectInput"];
function FileSelectorComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "input", 4, 1);
    i0.ɵɵlistener("change", function FileSelectorComponent_ng_container_1_Template_input_change_1_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.fileSelectedHandler());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "label", 5);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd();
    i0.ɵɵelement(5, "fly-icon", 6);
    i0.ɵɵelementStart(6, "div")(7, "span");
    i0.ɵɵtext(8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(9, "button", 7);
    i0.ɵɵlistener("click", function FileSelectorComponent_ng_container_1_Template_button_click_9_listener() {
      i0.ɵɵrestoreView(_r2);
      const fileSelectInput_r4 = i0.ɵɵreference(2);
      return i0.ɵɵresetView(fileSelectInput_r4.click());
    });
    i0.ɵɵtext(10, "browse ");
    i0.ɵɵelementEnd()();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("id", ctx_r2.identifier);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("for", ctx_r2.identifier);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r2.label, " ");
    i0.ɵɵadvance();
    i0.ɵɵproperty("icon", ctx_r2.icon);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate1("", ctx_r2.description, ", or ");
  }
}
function FileSelectorComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fly-loading-spinner");
  }
}
class DragAndDropDirective {
  constructor() {
    this.itemDropped = new EventEmitter();
  }
  onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.itemIsHovering = true;
  }
  onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.itemIsHovering = false;
  }
  ondrop(event) {
    event.preventDefault();
    event.stopPropagation();
    this.itemIsHovering = false;
    if (event.dataTransfer != null) {
      this.itemDropped.emit(event.dataTransfer);
    }
  }
  static {
    this.ɵfac = function DragAndDropDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DragAndDropDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DragAndDropDirective,
      selectors: [["", "flyDragAndDrop", ""]],
      hostVars: 2,
      hostBindings: function DragAndDropDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dragover", function DragAndDropDirective_dragover_HostBindingHandler($event) {
            return ctx.onDragOver($event);
          })("dragleave", function DragAndDropDirective_dragleave_HostBindingHandler($event) {
            return ctx.onDragLeave($event);
          })("drop", function DragAndDropDirective_drop_HostBindingHandler($event) {
            return ctx.ondrop($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵclassProp("item-hovering", ctx.itemIsHovering);
        }
      },
      outputs: {
        itemDropped: "itemDropped"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DragAndDropDirective, [{
    type: Directive,
    args: [{
      selector: '[flyDragAndDrop]'
    }]
  }], null, {
    itemIsHovering: [{
      type: HostBinding,
      args: ['class.item-hovering']
    }],
    itemDropped: [{
      type: Output
    }],
    onDragOver: [{
      type: HostListener,
      args: ['dragover', ['$event']]
    }],
    onDragLeave: [{
      type: HostListener,
      args: ['dragleave', ['$event']]
    }],
    ondrop: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }]
  });
})();
class FileSelectorComponent extends Identifiable {
  constructor() {
    super('file-upload');
    /*
      This fileUploadInProgress input can be used to display a loading indicator while the consumer of
      this component is uploading the selected files or doing any other time-consuming operation on them
    */
    this.fileUploadInProgress = false;
    this.label = 'File Upload';
    this.description = 'Drag a file here';
    // eslint-disable-next-line @typescript-eslint/prefer-as-const
    this.fileTypeIcon = 'image'; // TODO: Replace this default file icon when one is available
    this.fileSelected = new EventEmitter();
  }
  ngOnInit() {
    super.ngOnInit();
    switch (this.fileTypeIcon) {
      case 'image':
        this.icon = Icons.ImageFileType;
        break;
      default:
        this.icon = Icons.ImageFileType; // TODO: Replace this with a better default file icon
        break;
    }
  }
  fileSelectedHandler(files) {
    this.fileSelected.emit(files ?? this.fileSelectInput.nativeElement.files ?? {});
  }
  static {
    this.ɵfac = function FileSelectorComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FileSelectorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: FileSelectorComponent,
      selectors: [["fly-file-selector"]],
      viewQuery: function FileSelectorComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fileSelectInput = _t.first);
        }
      },
      inputs: {
        fileUploadInProgress: "fileUploadInProgress",
        label: "label",
        description: "description",
        fileTypeIcon: "fileTypeIcon"
      },
      outputs: {
        fileSelected: "fileSelected"
      },
      features: [i0.ɵɵInheritDefinitionFeature],
      decls: 4,
      vars: 2,
      consts: [["loading", ""], ["fileSelectInput", ""], ["flyDragAndDrop", "", 1, "dropzone", 3, "itemDropped"], [4, "ngIf", "ngIfElse"], ["multiple", "multiple", "type", "file", 1, "hidden", 3, "change", "id"], [1, "h6", 3, "for"], [1, "icon", 3, "icon"], ["flyLink", "", "flyLinkType", "external", 3, "click"]],
      template: function FileSelectorComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 2);
          i0.ɵɵlistener("itemDropped", function FileSelectorComponent_Template_div_itemDropped_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.fileSelectedHandler($event.files));
          });
          i0.ɵɵtemplate(1, FileSelectorComponent_ng_container_1_Template, 11, 5, "ng-container", 3)(2, FileSelectorComponent_ng_template_2_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          const loading_r5 = i0.ɵɵreference(3);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.fileUploadInProgress)("ngIfElse", loading_r5);
        }
      },
      dependencies: [i1.NgIf, i2.FlyLoadingSpinnerComponent, i3.FlyLinkDirective, i4.IconComponent, DragAndDropDirective],
      styles: [".dropzone[_ngcontent-%COMP%]{display:flex;flex-direction:column;gap:.5rem;align-items:center;text-align:center;border:dashed .125rem var(--ui-data-entry);border-radius:.25rem;padding:.5rem}.dropzone.item-hovering[_ngcontent-%COMP%]{background-color:var(--hover-secondary)}.hidden[_ngcontent-%COMP%]{visibility:hidden;position:absolute}.icon[_ngcontent-%COMP%]{color:var(--text-primary)}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileSelectorComponent, [{
    type: Component,
    args: [{
      selector: 'fly-file-selector',
      template: "<!-- linthtml-configure input-req-label=\"false\"-->\n<!-- linthtml-configure label-req-for=\"false\"-->\n<div class=\"dropzone\"\n  flyDragAndDrop\n  (itemDropped)=\"fileSelectedHandler($event.files)\">\n  <ng-container *ngIf=\"!fileUploadInProgress; else loading\">\n    <input\n      class=\"hidden\"\n      [id]=\"identifier\"\n      multiple=\"multiple\"\n      type=\"file\"\n      (change)=\"fileSelectedHandler()\"\n      #fileSelectInput>\n    <label\n      [for]=\"identifier\"\n      class=\"h6\">\n      {{ label }}\n    </label>\n    <fly-icon\n      [icon]=\"icon\"\n      class=\"icon\">\n    </fly-icon>\n    <div>\n      <span>{{ description }}, or </span>\n      <button\n        flyLink\n        flyLinkType=\"external\"\n        (click)=\"fileSelectInput.click()\">browse\n      </button>\n    </div>\n  </ng-container>\n  <ng-template #loading>\n    <fly-loading-spinner></fly-loading-spinner>\n  </ng-template>\n</div>\n",
      styles: [".dropzone{display:flex;flex-direction:column;gap:.5rem;align-items:center;text-align:center;border:dashed .125rem var(--ui-data-entry);border-radius:.25rem;padding:.5rem}.dropzone.item-hovering{background-color:var(--hover-secondary)}.hidden{visibility:hidden;position:absolute}.icon{color:var(--text-primary)}\n"]
    }]
  }], () => [], {
    fileUploadInProgress: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    description: [{
      type: Input
    }],
    fileTypeIcon: [{
      type: Input
    }],
    fileSelected: [{
      type: Output
    }],
    fileSelectInput: [{
      type: ViewChild,
      args: ['fileSelectInput']
    }]
  });
})();
class FlyFileUploadModule {
  static {
    this.ɵfac = function FlyFileUploadModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyFileUploadModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyFileUploadModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ReactiveFormsModule, FlyLoadingSpinnerModule, FlyLinkModule, FlyIconModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyFileUploadModule, [{
    type: NgModule,
    args: [{
      declarations: [FileSelectorComponent, DragAndDropDirective],
      imports: [CommonModule, ReactiveFormsModule, FlyLoadingSpinnerModule, FlyLinkModule, FlyIconModule],
      exports: [FileSelectorComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/file-selector
 */

/**
 * Generated bundle index. Do not edit.
 */

export { DragAndDropDirective, FileSelectorComponent, FlyFileUploadModule };
