import { AirportModel } from '@shared/models/airport/airport.model';

export enum AirportDataSection {
  CHART = 'CHART',
  CHARTSUPPLEMENT = 'CHARTSUPPLEMENT',
  FORECASTDISCUSSION = 'FORECASTDISCUSSION',
  FUEL = 'FUEL',
  METAR = 'METAR',
  MOS = 'MOS',
  //NAVAID = 'NAVAID',
  NOTAM = 'NOTAM',
  //PIREP = 'PIREP',
  RUNWAY = 'RUNWAY',
  TAF = 'TAF',
  WIND = 'WIND',
  ADBRESPONSE = 'ADBRESPONSE',
  AOPARESPONSE = 'AOPARESPONSE',
  AIRSIGRESPONSE = 'AIRSIGRESPONSE',
  NOTAMSSUMMARYRESPONSE = 'NOTAMSSUMMARYRESPONSE'
  // CHARTSRESPONSE = 'CHARTSRESPONSE',
  // CHARTSSUPPLEMENTRESPONSE = 'CHARTSSUPPLEMENTRESPONSE',
  // NOTAMSRESPONSE = 'NOTAMSRESPONSE',
  // NOTAMSSUMMARYRESPONSE = 'NOTAMSSUMMARYRESPONSE',
  // FUELSTATIONINFORESPONSE = 'FUELSTATIONINFORESPONSE',
  // FORECASTDISCUSSIONRESPONSE = 'FORECASTDISCUSSIONRESPONSE',
  // METARRESPONSE = 'METARRESPONSE',
  // TAFRESPONSE = 'TAFRESPONSE',
  // WINDSALOFTRESPONSE = 'WINDSALOFTRESPONSE',
  // WXRESPONSE = 'WXRESPONSE'
}

export const AirportDataSectionPropertyMap = new Map<AirportDataSection, keyof AirportModel>([
  [AirportDataSection.CHART, 'charts'],
  [AirportDataSection.CHARTSUPPLEMENT, 'chartSupplements'],
  [AirportDataSection.FORECASTDISCUSSION, 'forecastDiscussion'],
  [AirportDataSection.FUEL, 'fuel'],
  [AirportDataSection.METAR, 'metar'],
  [AirportDataSection.MOS, 'mos'],
  //[AirportDataSection.NAVAID, 'navaids'],
  [AirportDataSection.NOTAM, 'notamsResponse'],
  //[AirportDataSection.PIREP, 'pirep'],
  //[AirportDataSection.RUNWAY, 'runways'],
  [AirportDataSection.TAF, 'taf'],
  [AirportDataSection.WIND, 'winds'],
  [AirportDataSection.ADBRESPONSE, 'adbResponse'],
  [AirportDataSection.AOPARESPONSE, 'aopaResponse'],
  [AirportDataSection.AIRSIGRESPONSE, 'airsigs'],
  [AirportDataSection.NOTAMSSUMMARYRESPONSE, 'notamsSummaryResponse']
  // [AirportDataSection.CHARTSRESPONSE, 'chartsResponse'],
  // [AirportDataSection.CHARTSSUPPLEMENTRESPONSE, 'chartsSupplementResponse'],
  // [AirportDataSection.NOTAMSRESPONSE, 'notamsResponse'],
  // [AirportDataSection.FUELSTATIONINFORESPONSE, 'fuelStationInfoResponse'],
  // [AirportDataSection.FORECASTDISCUSSIONRESPONSE, 'forecastDiscussionResponse'],
  // [AirportDataSection.METARRESPONSE, 'metarResponse'],
  // [AirportDataSection.MOSRESPONSE, 'mosResponse'],
  // [AirportDataSection.TAFRESPONSE, 'tafResponse'],
  // [AirportDataSection.WINDSALOFTRESPONSE, 'windsAloftResponse'],
  // [AirportDataSection.WXRESPONSE, 'wxResponse']
]);

export const WxDataSectionPropertyMap = new Map<AirportDataSection, keyof AirportModel>([
  [AirportDataSection.FORECASTDISCUSSION, 'forecastDiscussion'],
  [AirportDataSection.METAR, 'metar'],
  [AirportDataSection.MOS, 'mos'],
  [AirportDataSection.TAF, 'taf']
]);
