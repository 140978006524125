import { UnifiedTokenRequestDesiredLocationTypes } from "@generated/flight-route-service";

export const TOKEN_SEARCH_TYPES = [
  UnifiedTokenRequestDesiredLocationTypes.AIRPORT,
  UnifiedTokenRequestDesiredLocationTypes.INTERSECTION,
  UnifiedTokenRequestDesiredLocationTypes.NDB,
  UnifiedTokenRequestDesiredLocationTypes.VOR,
  UnifiedTokenRequestDesiredLocationTypes.USER_WAYPOINT,
  UnifiedTokenRequestDesiredLocationTypes.RUNWAY,
  UnifiedTokenRequestDesiredLocationTypes.AIRPORT_INTERSECTION,
  UnifiedTokenRequestDesiredLocationTypes.AIRPORT_NDB,
  UnifiedTokenRequestDesiredLocationTypes.AIRPORT_ILS,
  UnifiedTokenRequestDesiredLocationTypes.LOCALIZER_MARKER,
  UnifiedTokenRequestDesiredLocationTypes.GLS_MARKER,
  UnifiedTokenRequestDesiredLocationTypes.VOR_CLASS,
  UnifiedTokenRequestDesiredLocationTypes.FIX_REFERENCE_CLASS,
  UnifiedTokenRequestDesiredLocationTypes.ROUTE_WAYPOINT,
  UnifiedTokenRequestDesiredLocationTypes.VRP
];

export const LEIDOS_SUPPORTED_COUNTRY_CODES = [
  'K', // Continental U.S.
  'CY', // Canada
  'MM', // Mexico
  'TJ', // Puerto Rico
  'MY', // Bahamas
  'P', // Alaska, Guam, Hawaii, Marshall Islands, U.S. Minor Outlying Islands, Micronesia
  'MB', // Turks and Caicos
  'TI', // U.S. Virgin Islands
  'NG' // Kiribati
];
