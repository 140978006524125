<div class="brg-dis">
  <pilot-settings-search class="search-component"
    label="Waypoint"
    [loadedWaypoint]="loadedWaypoint"
    (waypointSent)="saveWaypoint($event)">
  </pilot-settings-search>
  <form class="form"
    [formGroup]="brgDisForm">
    <fly-input formControlName="rad"
      label="Radial"
      [errorStrings]="errorStrings"
      placeholder="Required"
      suffix="°M">
    </fly-input>
    <fly-input formControlName="dist"
      label="Distance"
      [errorStrings]="errorStrings"
      placeholder="Required"
      suffix="NM">
  </fly-input>
  </form>
</div>
