<fly-modal #editModal
  [modalType]="ModalType.Custom"
  (confirm)="hideModal();">
  <div class="edit-modal">
    <div class="h5"
      slot="header">
      <div>{{title}}</div>
    </div>
    @if (waypointEditModalState === State.Loaded) {
      <div class="wrapper">
        <div class="content"
          [formGroup]="wptForm">
          <div class="section">
            <div class="identifier">
              <fly-input formControlName="name"
                label="Identifier"
                placeholder="Required"
                [errorStrings]="errorMap">
              </fly-input>
              <fly-input formControlName="comment"
                label="Comment"
                placeholder="Optional">
              </fly-input>
            </div>
          </div>
          <div class="switches">
            <div>
              <fly-checkbox formControlName="displayOnMap"
                mode="Toggle">
              </fly-checkbox>
              <div class="text">
                &nbsp;Show on map
              </div>
            </div>
          </div>
          <div class="tabs">
            <fly-tab-group tabGroupWidth="100%"
              [(selectedTab)]="tabIndex">
              @for (tab of tabs; track tab) {
                <fly-tab>{{tab.label}}</fly-tab>
              }
            </fly-tab-group>
          </div>
          @if (tabIndex === 0) {
            <pilot-settings-lat-lon-tab [waypoint]="waypoint"></pilot-settings-lat-lon-tab>
          } @else if (tabIndex === 1) {
            <pilot-settings-brg-dis-tab [waypoint]="waypoint"></pilot-settings-brg-dis-tab>
          } @else if (tabIndex === 2) {
            <pilot-settings-rad-rad-tab [waypoint]="waypoint"></pilot-settings-rad-rad-tab>
          }
        </div>
        <div class="buttons">
          <button flyButton
            [options]="{ style: 'dangerghost'}"
            (click)="hideModal()">
            Cancel
          </button>
          <button flyButton
            (click)="saveWaypoints()"
            [disabled]="wptForm.invalid || (latLonForm.invalid && tabIndex === 0) || (brgDisForm.invalid && tabIndex === 1) || (radRadForm.invalid && tabIndex === 2)">
            Save
          </button>
        </div>
      </div>
    }
  </div>
</fly-modal>

<pilot-confirmation-modal #confirmDeleteModal
  [title]="'Delete Waypoint'"
  (confirm)="deleteWaypoints()">
</pilot-confirmation-modal>
