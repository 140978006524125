/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Current filing status of the flight
 */
export interface FlightFilingStatus { 
    /**
     * (enum) Current filing status of the flight
     */
    value: FlightFilingStatusValue;
    /**
     * Reason for the status, may be null. Only used when the status is pending confirmation or there was an error/rejection.
     */
    description?: string;
}
export enum FlightFilingStatusValue {
    NOT_FILED = 'NOT_FILED',
    SCHEDULED = 'SCHEDULED',
    FILED = 'FILED',
    REJECTED = 'REJECTED',
    ACTIVATED = 'ACTIVATED',
    IN_FLIGHT = 'IN_FLIGHT',
    CLOSED = 'CLOSED',
    OVERDUE = 'OVERDUE',
    UNCONFIRMED = 'UNCONFIRMED',
    COMPLETED = 'COMPLETED',
    ATC_PROPOSED = 'ATC_PROPOSED'
};



