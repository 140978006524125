import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeUtils } from '@shared/utils/date-time/date-time-utils';

@Pipe({
  name: 'flyTimeRange'
})
export class TimeRangePipe implements PipeTransform {

  transform(start: number, end: number): string {
    return DateTimeUtils.timeRange(start, end);
  }

}
