import { Component, DestroyRef, inject, input, effect } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlightRouteControllerService, LatLonRequest, RouteComputedLeg, RouteComputedLegLocationType } from '@generated/flight-route-service';
import { SearchResult } from '@shared/components/search/search.component';

import { SettingsWaypointsWaypointModel } from '@shared/models/settings/waypoints/settings-waypoints-waypoint.model';
import { UnitAbbreviations, UnitConverterPipe } from '@shared/pipes/unit-converter/unit-converter.pipe';
import { AirportService } from '@shared/services/airport/airport.service';
import { INTEGER_ERROR_STRING } from '@shared/validators/integer';
import { SettingsWaypointsService } from '../../settings-waypoints.service';
@Component({
  selector: 'pilot-settings-rad-rad-tab',
  templateUrl: './settings-rad-rad-tab.component.html',
  styleUrls: ['./settings-rad-rad-tab.component.scss']
})

export class SettingsRadRadTabComponent {
  waypoint = input<SettingsWaypointsWaypointModel | undefined> (undefined);
  private readonly airportService = inject(AirportService);
  private readonly settingsWaypointService = inject(SettingsWaypointsService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly flightRouteService = inject(FlightRouteControllerService);
  private readonly unitConverterPipe = new UnitConverterPipe();

  readonly UnitAbbreviations = UnitAbbreviations;
  readonly radRadForm = this.settingsWaypointService.radRadForm;
  private readonly latLonRequest: LatLonRequest = {waypoint1: {identifier: ''}, waypoint2: {identifier: ''}};
  loadedWaypoint1: RouteComputedLeg | null;
  loadedWaypoint2: RouteComputedLeg | null;

  constructor() {
    effect(() => {
      this._loadForm(this.waypoint());
    });
  }

  errorStrings = {
    required: 'Required Value.',
    ...INTEGER_ERROR_STRING
  };

  _loadForm(waypoint: SettingsWaypointsWaypointModel | undefined): void {
    if (waypoint?.name != null && waypoint?.waypointJson['referenceWaypoint1Identifier'] != null && waypoint?.waypointJson['referenceWaypoint2Identifier'] != null) {
      this.latLonRequest.waypoint1 = {
        identifier: waypoint.waypointJson['referenceWaypoint1Identifier'],
        countryCode: waypoint.waypointJson['referenceWaypoint1Qualifier'],
        locationType: waypoint.waypointJson['referenceWaypoint1LocationType'],
        lat: waypoint.lat,
        lon: waypoint.lon
      };
      this.latLonRequest.waypoint2 = {
        identifier: waypoint.waypointJson['referenceWaypoint2Identifier'],
        countryCode: waypoint.waypointJson['referenceWaypoint2Qualifier'],
        locationType: waypoint.waypointJson['referenceWaypoint2LocationType'],
        lat: 1, // TODO: Backend requires these values to not be 0 or null, so we are giving placeholders but are not actually used in calculations
        lon: 1
      };
      this.loadedWaypoint1 = this.latLonRequest.waypoint1;
      this.loadedWaypoint2 = this.latLonRequest.waypoint2;
      this.flightRouteService.reverseLatLongSearchPost(this.latLonRequest).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((response) => {
          if (response.bearing1 != null && response.bearing2 != null) {
            if (response.bearing1 < 0) {
              response.bearing1 = response.bearing1 + 360;
            }
            if (response.bearing2 < 0) {
              response.bearing2 = response.bearing2 + 360;
            }
            this.radRadForm.controls.rad1.setValue(response.bearing1);
            this.radRadForm.controls.rad2.setValue(response.bearing2);
          }
        });
    } else {
      this.loadedWaypoint1 = null;
      this.loadedWaypoint2 = null;
    }
  }

  saveWaypoint(chosenWaypoint: SearchResult, index: 1 | 2): void {
    if (chosenWaypoint.airport != null && chosenWaypoint.airport.icao != null) {
      this.radRadForm.patchValue({
        [`locationType${index}`]: RouteComputedLegLocationType.AIRPORT,
        [`identifier${index}`]: chosenWaypoint.airport.icao,
        [`qualifier${index}`]: chosenWaypoint.airport.name,
        [`lat${index}`]:chosenWaypoint.airport.lat,
        [`lon${index}`]:chosenWaypoint.airport.lon
      });
    } else if (chosenWaypoint.waypoint != null && chosenWaypoint.waypoint.countryCode != null &&
      chosenWaypoint.waypoint.locationType != null && chosenWaypoint.waypoint.lat != null && chosenWaypoint.waypoint.lon != null) {
      this.radRadForm.patchValue({
        [`identifier${index}`]: chosenWaypoint.waypoint.identifier,
        [`lat${index}`]: chosenWaypoint.waypoint.lat,
        [`lon${index}`]: chosenWaypoint.waypoint.lon,
        [`locationType${index}`]: chosenWaypoint.waypoint.locationType,
        [`qualifier${index}`]: chosenWaypoint.waypoint.countryCode
      });
    }
  }
}
