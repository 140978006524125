import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { EventEmitter, Component, Output, Input, ContentChildren, ViewChild, HostListener, NgModule } from '@angular/core';
import * as i2 from '@garmin-avcloud/avcloud-ui-common/button';
import { FlyButtonModule } from '@garmin-avcloud/avcloud-ui-common/button';
import * as i3 from '@garmin-avcloud/avcloud-ui-common/icon';
import { Icons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import { Subscription } from 'rxjs';
const _c0 = ["*"];
const _c1 = () => ({
  style: "ghost"
});
const _c2 = (a0, a1, a2, a3, a4, a5, a6) => ({
  "selected": a0,
  "center-text": a1,
  "disabled": a2,
  "large": a3,
  "medium-large": a4,
  "medium": a5,
  "small": a6
});
function TabComponent_fly_icon_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fly-icon", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("icon", ctx_r0.Icons.CautionHardcodedColor);
  }
}
function TabComponent_fly_icon_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "fly-icon", 4);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("icon", ctx_r0.Icons.InvalidHardcodedColor);
  }
}
const _c3 = ["tabsContainer"];
const _c4 = (a0, a1, a2, a3, a4) => ({
  "d-none": a0,
  "large": a1,
  "medium-large": a2,
  "medium": a3,
  "small": a4
});
const _c5 = (a0, a1, a2, a3) => ({
  "d-none": a0,
  "large": a1,
  "medium": a2,
  "small": a3
});
class TabComponent {
  constructor(elementRef, cdr) {
    this.elementRef = elementRef;
    this.cdr = cdr;
    this.tabSelectedEvent = new EventEmitter();
    this.disabled = false;
    this.warning = 'none';
    this.selected = false;
    this.centerText = false;
    this.tabSize = 'large';
    this.Icons = Icons;
  }
  selectTab() {
    if (this.index != null && !this.disabled) {
      this.tabSelectedEvent.emit(this.index);
      this.selected = true;
      this.cdr.detectChanges();
    }
  }
  static {
    this.ɵfac = function TabComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TabComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TabComponent,
      selectors: [["fly-tab"]],
      inputs: {
        disabled: "disabled",
        warning: "warning",
        index: "index",
        selected: "selected"
      },
      outputs: {
        tabSelectedEvent: "tabSelectedEvent"
      },
      ngContentSelectors: _c0,
      decls: 6,
      vars: 14,
      consts: [["flyButton", "", "type", "button", 1, "tab", "button-extension", 3, "click", "options", "ngClass"], [1, "text"], [1, "warning-icon", 3, "ngSwitch"], [3, "icon", 4, "ngSwitchCase"], [3, "icon"]],
      template: function TabComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵlistener("click", function TabComponent_Template_button_click_0_listener() {
            return ctx.selectTab();
          });
          i0.ɵɵelementStart(1, "div", 1);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 2);
          i0.ɵɵtemplate(4, TabComponent_fly_icon_4_Template, 1, 1, "fly-icon", 3)(5, TabComponent_fly_icon_5_Template, 1, 1, "fly-icon", 3);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("options", i0.ɵɵpureFunction0(5, _c1))("ngClass", i0.ɵɵpureFunction7(6, _c2, ctx.selected, ctx.centerText, ctx.disabled, ctx.tabSize === "large", ctx.tabSize === "medium-large", ctx.tabSize === "medium", ctx.tabSize === "small"));
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("ngSwitch", ctx.warning);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "caution");
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngSwitchCase", "error");
        }
      },
      dependencies: [i1.NgClass, i1.NgSwitch, i1.NgSwitchCase, i2.FlyButtonDirective, i3.IconComponent],
      styles: ["[_nghost-%COMP%]{flex:1 0 8rem}.button-content[_ngcontent-%COMP%]{display:flex}.tab[_ngcontent-%COMP%]{width:100%;padding:0 min(1.25rem,10%);display:flex;align-items:center;justify-content:left;border-bottom:.125rem solid var(--ui-tab-underline)}.tab[_ngcontent-%COMP%]:hover{border-bottom:.125rem solid var(--hover-border)}.text[_ngcontent-%COMP%]{flex-grow:1}.selected[_ngcontent-%COMP%]{background-color:var(--selected-ui);border-bottom:.125rem solid var(--selected-primary);transition:border-bottom .25s linear,opacity .3s linear .4s,width .3s linear,padding-left .3s linear;font-weight:600}.disabled[_ngcontent-%COMP%]{color:var(--disabled-02);border-bottom:.125rem solid var(--disabled-03);cursor:not-allowed}.focused[_ngcontent-%COMP%]{border:.125rem solid var(--focus)}.hidden[_ngcontent-%COMP%]{opacity:0;overflow:hidden;padding:0;margin:0;width:0;transition:opacity .3s linear}.center-text[_ngcontent-%COMP%]{justify-content:center}.large[_ngcontent-%COMP%]{height:2.625rem;position:relative}.medium-large[_ngcontent-%COMP%]{height:2.3125rem}.medium[_ngcontent-%COMP%]{height:2.125rem}.small[_ngcontent-%COMP%]{height:2rem}.button-extension[_ngcontent-%COMP%]{width:100%;border-radius:0}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabComponent, [{
    type: Component,
    args: [{
      selector: 'fly-tab',
      template: "<button\n  class=\"tab button-extension\"\n  (click)=\"selectTab()\"\n  flyButton\n  type=\"button\"\n  [options]=\"{style: 'ghost'}\"\n  [ngClass]=\"{\n    'selected': selected,\n    'center-text': centerText,\n    'disabled': disabled,\n    'large': tabSize === 'large',\n    'medium-large': tabSize === 'medium-large',\n    'medium': tabSize === 'medium',\n    'small': tabSize === 'small' }\">\n  <div class=\"text\">\n    <ng-content>\n    </ng-content>\n  </div>\n  <div\n    [ngSwitch]=\"warning\"\n    class=\"warning-icon\">\n    <fly-icon\n      *ngSwitchCase=\"'caution'\"\n      [icon]=\"Icons.CautionHardcodedColor\"></fly-icon>\n    <fly-icon\n      *ngSwitchCase=\"'error'\"\n      [icon]=\"Icons.InvalidHardcodedColor\"></fly-icon>\n  </div>\n</button>\n",
      styles: [":host{flex:1 0 8rem}.button-content{display:flex}.tab{width:100%;padding:0 min(1.25rem,10%);display:flex;align-items:center;justify-content:left;border-bottom:.125rem solid var(--ui-tab-underline)}.tab:hover{border-bottom:.125rem solid var(--hover-border)}.text{flex-grow:1}.selected{background-color:var(--selected-ui);border-bottom:.125rem solid var(--selected-primary);transition:border-bottom .25s linear,opacity .3s linear .4s,width .3s linear,padding-left .3s linear;font-weight:600}.disabled{color:var(--disabled-02);border-bottom:.125rem solid var(--disabled-03);cursor:not-allowed}.focused{border:.125rem solid var(--focus)}.hidden{opacity:0;overflow:hidden;padding:0;margin:0;width:0;transition:opacity .3s linear}.center-text{justify-content:center}.large{height:2.625rem;position:relative}.medium-large{height:2.3125rem}.medium{height:2.125rem}.small{height:2rem}.button-extension{width:100%;border-radius:0}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }], {
    tabSelectedEvent: [{
      type: Output
    }],
    disabled: [{
      type: Input
    }],
    warning: [{
      type: Input
    }],
    index: [{
      type: Input
    }],
    selected: [{
      type: Input
    }]
  });
})();
class TabGroupComponent {
  constructor(elementRef, cdr) {
    this.elementRef = elementRef;
    this.cdr = cdr;
    this.selectedTabChange = new EventEmitter();
    this.tabSize = 'large';
    this.arrowsDisabled = false;
    this.tabGroupWidth = '100%';
    this.centerText = false;
    this.leftArrow = Icons.ArrowLeft;
    this.rightArrow = Icons.ArrowRight;
    this.hideArrows = false;
    this.selectedTabSubscription = new Subscription();
    this.isContentInitialized = false;
    this._selectedTab = 0;
  }
  get tabsContainer() {
    return this.tabsContainerElementRef.nativeElement;
  }
  set selectedTab(selectedTab) {
    this._selectedTab = selectedTab;
    if (this.isContentInitialized) {
      this.walkTabs((tab, i) => {
        tab.selected = i === this._selectedTab;
        tab.cdr.detectChanges();
      });
    }
  }
  configureScrolling() {
    this.elementRef.nativeElement.style.width = this.tabGroupWidth;
    this.scrollWidth = this.tabs.get(0).elementRef.nativeElement.clientWidth;
    this.hideArrows = this.tabsContainer.scrollWidth <= this.elementRef.nativeElement.offsetWidth;
  }
  ngAfterContentInit() {
    this.walkTabs((tab, i) => {
      tab.index = i;
      tab.tabSize = this.tabSize;
      tab.centerText = this.centerText;
      tab.cdr.detectChanges();
      this.selectedTabSubscription.add(tab.tabSelectedEvent.subscribe(newActiveTabIndex => {
        this.tabs.get(this._selectedTab).selected = false;
        this._selectedTab = newActiveTabIndex;
        //Emits selected tab index for parent component to use
        this.selectedTabChange.emit(this._selectedTab);
      }));
    });
    this.tabs.get(this._selectedTab).selectTab();
    this.isContentInitialized = true;
  }
  ngAfterViewInit() {
    this.configureScrolling();
    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    this.selectedTabSubscription.unsubscribe();
  }
  shiftTabsRight() {
    if (this.scrollWidth != null) {
      const scrollLeft = this.tabsContainer.scrollLeft;
      this.tabsContainer.scroll({
        left: scrollLeft + this.scrollWidth,
        behavior: 'smooth'
      });
    }
  }
  shiftTabsLeft() {
    if (this.scrollWidth != null) {
      const scrollLeft = this.tabsContainer.scrollLeft;
      this.tabsContainer.scroll({
        left: scrollLeft - this.scrollWidth,
        behavior: 'smooth'
      });
    }
  }
  walkTabs(callback) {
    if (this.tabs.length > 0) {
      if (this._selectedTab >= 0 && this._selectedTab <= this.tabs.length) {
        // Valid tabs.
        this.tabs.forEach((tab, i) => {
          callback(tab, i);
        });
      } else {
        throw new Error(`activeTabIndex must be greater than or equal to 0 and less than the number of tabs: ${this.tabs.length}`);
      }
    } else {
      throw new Error('TabGroupComponent must have at least 1 child TabComponent');
    }
  }
  static {
    this.ɵfac = function TabGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TabGroupComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TabGroupComponent,
      selectors: [["fly-tab-group"]],
      contentQueries: function TabGroupComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, TabComponent, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabs = _t);
        }
      },
      viewQuery: function TabGroupComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c3, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tabsContainerElementRef = _t.first);
        }
      },
      hostBindings: function TabGroupComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("resize", function TabGroupComponent_resize_HostBindingHandler() {
            return ctx.configureScrolling();
          }, false, i0.ɵɵresolveWindow);
        }
      },
      inputs: {
        tabSize: "tabSize",
        arrowsDisabled: "arrowsDisabled",
        tabGroupWidth: "tabGroupWidth",
        centerText: "centerText",
        selectedTab: "selectedTab"
      },
      outputs: {
        selectedTabChange: "selectedTabChange"
      },
      ngContentSelectors: _c0,
      decls: 11,
      vars: 19,
      consts: [["tabsContainer", ""], ["flyButton", "", 1, "button-extension", 3, "click", "ngClass", "options"], [3, "icon"], [1, "d-none"], [1, "tabs"]],
      template: function TabGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "button", 1);
          i0.ɵɵlistener("click", function TabGroupComponent_Template_button_click_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.shiftTabsLeft());
          });
          i0.ɵɵelement(1, "fly-icon", 2);
          i0.ɵɵelementStart(2, "span", 3);
          i0.ɵɵtext(3, "Shift Tabs Left");
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(4, "div", 4, 0);
          i0.ɵɵprojection(6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "button", 1);
          i0.ɵɵlistener("click", function TabGroupComponent_Template_button_click_7_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.shiftTabsRight());
          });
          i0.ɵɵelement(8, "fly-icon", 2);
          i0.ɵɵelementStart(9, "span", 3);
          i0.ɵɵtext(10, "Shift Tabs right");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction5(6, _c4, ctx.hideArrows, ctx.tabSize === "large", ctx.tabSize === "medium-large", ctx.tabSize === "medium", ctx.tabSize === "small"))("options", i0.ɵɵpureFunction0(12, _c1));
          i0.ɵɵadvance();
          i0.ɵɵproperty("icon", ctx.leftArrow);
          i0.ɵɵadvance(6);
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction4(13, _c5, ctx.hideArrows, ctx.tabSize === "large", ctx.tabSize === "medium", ctx.tabSize === "small"))("options", i0.ɵɵpureFunction0(18, _c1));
          i0.ɵɵadvance();
          i0.ɵɵproperty("icon", ctx.rightArrow);
        }
      },
      dependencies: [i1.NgClass, i2.FlyButtonDirective, i3.IconComponent],
      styles: ["[_nghost-%COMP%]{display:flex;background:var(--interactive-ui)}.tabs[_ngcontent-%COMP%]{width:100%;display:flex;column-gap:.125rem;overflow-x:hidden}.d-none[_ngcontent-%COMP%]{display:none}.button-extension[_ngcontent-%COMP%]{border-radius:0}.large[_ngcontent-%COMP%]{height:2.625rem}.medium-large[_ngcontent-%COMP%]{height:2.3125rem}.medium[_ngcontent-%COMP%]{height:2.125rem}.small[_ngcontent-%COMP%]{height:2rem}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TabGroupComponent, [{
    type: Component,
    args: [{
      selector: 'fly-tab-group',
      template: "<button\n  flyButton\n  class=\"button-extension\"\n  [ngClass]=\"{\n    'd-none': hideArrows,\n    'large': tabSize === 'large',\n    'medium-large': tabSize === 'medium-large',\n    'medium': tabSize === 'medium',\n    'small': tabSize === 'small'\n  }\"\n  [options]=\"{style: 'ghost'}\"\n  (click)=\"shiftTabsLeft()\">\n  <fly-icon [icon]=\"leftArrow\"></fly-icon>\n  <span class=\"d-none\">Shift Tabs Left</span>\n</button>\n<div\n  #tabsContainer\n  class=\"tabs\">\n  <ng-content></ng-content>\n</div>\n<button\n  flyButton\n  class=\"button-extension\"\n  [ngClass]=\"{\n    'd-none': hideArrows,\n    'large': tabSize === 'large',\n    'medium': tabSize === 'medium',\n    'small': tabSize === 'small'\n  }\"\n  [options]=\"{style: 'ghost'}\"\n  (click)=\"shiftTabsRight()\">\n  <fly-icon [icon]=\"rightArrow\"></fly-icon>\n  <span class=\"d-none\">Shift Tabs right</span>\n</button>\n",
      styles: [":host{display:flex;background:var(--interactive-ui)}.tabs{width:100%;display:flex;column-gap:.125rem;overflow-x:hidden}.d-none{display:none}.button-extension{border-radius:0}.large{height:2.625rem}.medium-large{height:2.3125rem}.medium{height:2.125rem}.small{height:2rem}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }], {
    tabs: [{
      type: ContentChildren,
      args: [TabComponent]
    }],
    tabsContainerElementRef: [{
      type: ViewChild,
      args: ['tabsContainer']
    }],
    selectedTabChange: [{
      type: Output
    }],
    tabSize: [{
      type: Input
    }],
    arrowsDisabled: [{
      type: Input
    }],
    tabGroupWidth: [{
      type: Input
    }],
    centerText: [{
      type: Input
    }],
    selectedTab: [{
      type: Input
    }],
    configureScrolling: [{
      type: HostListener,
      args: ['window:resize']
    }]
  });
})();
class FlyTabsModule {
  static {
    this.ɵfac = function FlyTabsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || FlyTabsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FlyTabsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FlyButtonModule, FlyIconModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FlyTabsModule, [{
    type: NgModule,
    args: [{
      declarations: [TabComponent, TabGroupComponent],
      imports: [CommonModule, FlyButtonModule, FlyIconModule],
      exports: [TabComponent, TabGroupComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common/tabs
 */

/**
 * Generated bundle index. Do not edit.
 */

export { FlyTabsModule, TabComponent, TabGroupComponent };
