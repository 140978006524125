/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * NAVAID frequency
 */
export interface Frequency { 
    /**
     * NAVAID frequency value in KHz
     */
    freqValue: number;
    /**
     * frequency ranges
     */
    freqType: FrequencyFreqType;
}
export enum FrequencyFreqType {
    HF = 'ADB_FREQ_UNITS_HF',
    VHF = 'ADB_FREQ_UNITS_VHF',
    VHF_833 = 'ADB_FREQ_UNITS_VHF_833',
    UHF = 'ADB_FREQ_UNITS_UHF',
    MF = 'ADB_FREQ_UNITS_MF',
    LF = 'ADB_FREQ_UNITS_LF'
};



