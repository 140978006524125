<span>
  @if (timeElapsed.days > 0) {
    {{ timeElapsed.days }}<span class="uom">D</span>
  }
  @if (timeElapsed.hours > 0) {
    {{ timeElapsed.hours }}<span class="uom">HR</span>
  }
  @if (timeElapsed.mins > 0) {
    {{ timeElapsed.mins }}<span class="uom">MIN</span>
  }
</span>
