import { Injectable, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AirportDataService {
  private readonly router = inject(Router);

  readonly airportId = signal<string | null>(null);

  /** @deprecated Please use the AirportDataService.airportId signal instead. */
  readonly airportId$ = toObservable(this.airportId);

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed()
      )
      .subscribe(() => {
        if (this.router.url.includes('airport')) {
          // airport routes are of the form: /airport/:id/:tab/:subtab
          this.airportId.set(this.router.url.split('/').at(2) ?? '');
        }
      });
  }

  setAirportId(id: string): void {
    this.airportId.set(id);
  }
}
