import { Injectable, OnDestroy, inject } from '@angular/core';
import {
  AuthService,
  CustomerAccount,
  CustomerFreeformSetting,
  CustomerFreeformSettingService,
  CustomerService,
  SettingService,
  SettingType
} from '@garmin-avcloud/avcloud-web-utils';
import { BehaviorSubject, Observable, Subject, Subscription, catchError, filter, forkJoin, of, switchMap, takeUntil, takeWhile } from 'rxjs';

const SETTING_CATEGORY = 'FAVORITES';

@Injectable({
  providedIn: 'root'
})
export class FavoriteAirportsService implements OnDestroy {
  private readonly authService = inject(AuthService);
  private readonly customerFreeformSettingService = inject(CustomerFreeformSettingService);
  private readonly customerService = inject(CustomerService);
  private readonly favoriteAirportIds = new BehaviorSubject<CustomerFreeformSetting[]>([]);
  private readonly settingService = inject(SettingService);
  private readonly subs = new Subscription();

  // we set these inside a subscription handler, so they can't be `readonly`
  private favoriteAirportsSettingUuid?: string;
  private customerGuid?: string;

  constructor() {
    // we need to get the following for this service to work:
    //  - customer GUID
    //  - the setting ID for favorite airports
    //  - all of the customer's current favorite airports (requires customer GUID)
    const respHandled = new Subject<void>();
    const initSubs = this.authService.isAuthenticated()
      .pipe(
        filter((authenticated) => authenticated),
        switchMap(() => this.customerService.getCustomerAccount()),
        takeWhile((customerAccount): customerAccount is CustomerAccount => customerAccount != null), // this will actually complete upon the first null value (in contrast to `filter`)
        switchMap((customerAccount) => {
          this.customerGuid = customerAccount.id;
          return forkJoin([
            this.settingService.getSettingSummaries(SETTING_CATEGORY),
            this.customerFreeformSettingService.getAll(this.customerGuid)
          ]);
        }),
        takeUntil(respHandled) // unsubscribe from `forkJoin` and `respHandled` when `respHandled` emits
      ).subscribe(([summaries, freeFormSettings]) => {
        this.favoriteAirportsSettingUuid = summaries.find((summary) => summary.setting.type === SettingType.SETTING_VALUE_AIRPORT_MULTI)?.setting.uuid;
        this.favoriteAirportIds.next(freeFormSettings.filter((setting) => setting.settingUuid === this.favoriteAirportsSettingUuid));
        respHandled.next();
        respHandled.complete();
      });
    this.subs.add(initSubs); // in case we never get a response
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Subscribe to this list to be notified when the user's list of favorite
   * airports changes.
   */
  getFavoriteAirportIds(): Observable<CustomerFreeformSetting[]> {
    return this.favoriteAirportIds;
  }

  isFavorite(airportId: string): boolean {
    return this.favoriteAirportIds.getValue()
      .find((f) => f.value === airportId) != null;
  }

  /**
   * Try to toggle the given airport ID as a user favorite.
   *
   * If `favoriteAirportIds$` emits a new array, then the toggle succeeded.
   *
   * @param airportId The airport ID to toggle.
   */
  tryToggleFavorite(airportId: string): void {
    if (this.customerGuid == null || this.favoriteAirportsSettingUuid == null) return;
    const index = this.favoriteAirportIds.getValue().findIndex((f) => f.value === airportId);
    if (index >= 0) {
      this.tryRemoveFavorite(index);
    } else {
      this.tryAddFavorite(airportId);
    }
  }

  private tryAddFavorite(airportId: string): void {
    const setting = {
      customerGuid: this.customerGuid,
      settingUuid: this.favoriteAirportsSettingUuid,
      value: airportId
    } as CustomerFreeformSetting; // FIXME: this cast is to get around the fact that `uuid` is not optional, which it should be

    const respHandled = new Subject<void>();
    const createSub = this.customerFreeformSettingService.create(setting)
      .pipe(
        catchError((e) => {
          console.error(e);
          return of(null);
        }),
        takeUntil(respHandled)
      )
      .subscribe((newFavorite) => {
        if (newFavorite != null) {
          const favorites = this.favoriteAirportIds.getValue();
          this.favoriteAirportIds.next([...favorites, newFavorite]);
        }
        respHandled.next();
        respHandled.complete();
      });
    this.subs.add(createSub); // in case we don't get a response
  }

  private tryRemoveFavorite(removalIdx: number): void {
    const favorites = this.favoriteAirportIds.getValue();
    const respHandled = new Subject<void>();
    const deleteSub = this.customerFreeformSettingService.delete(favorites[removalIdx].uuid)
      .pipe(
        switchMap(() => of(true)), // we're just switching to a type other than unknown
        catchError((e) => {
          console.error(e);
          return of(null);
        }),
        takeUntil(respHandled)
      )
      .subscribe((resp) => {
        if (resp != null) {
          favorites.splice(removalIdx, 1);
          this.favoriteAirportIds.next([...favorites]);
        }
        respHandled.next();
        respHandled.complete();
      });
    this.subs.add(deleteSub); // in case we don't get a response
  }
}
