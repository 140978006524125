<fly-modal #flyModal
  confirmButtonText="Confirm"
  cancelButtonText="Cancel"
  (cancel)="flyModal.hide()"
  (confirm)="confirm.emit(); flyModal.hide()">
  <div
    class="modal-header"
    slot="header">
    <span class="h5">{{ title }}</span>
  </div>
  <div class="modal-content">
    <ng-content></ng-content>
  </div>
</fly-modal>
