/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RouteLeg } from './route-leg.model';
import { PerformanceInfo } from './performance-info.model';
import { RoutePerf } from './route-perf.model';


/**
 * The userRoute containing the changes made on the front-end. Can be left null if there are no changes to the route.
 */
export interface RouteV2 { 
    /**
     * UUID for the route
     */
    routeUUID?: string;
    /**
     * Type of route
     */
    routeType?: RouteV2RouteType;
    /**
     * Type of route description
     */
    routeTypeDescription?: string;
    /**
     * List of client types that created/modified this route
     */
    routeClientTypes?: Array<RouteV2RouteClientTypes>;
    origin?: RouteLeg;
    destination?: RouteLeg;
    alternateDestination?: RouteLeg;
    secondAlternateDestination?: RouteLeg;
    /**
     * List of Route Legs that make up intermediate waypoints of the Route. This looks quite different for requests and responses.REQUESTS - Requests can use this list to specify any waypoints that fall outside of procedures or airways, but need to be added to the flight plan.  These points do not have any child waypoints.  This list can also specify airways.  Airways are specified by adding a RouteLeg with LocationType of AIRWAY, and specifying one child leg, which is the waypoint at which the flight will exit that airway.RESPONSES - Responses will contain all of the intermediate waypoints that make up the resulting route.  It looks much like the Request, but it also includes Procedures and all of their intermediate waypoints, and Airways that contain all of the intermediate waypoints, not just the exit point.
     */
    intermediateRoutePoints?: Array<RouteLeg>;
    /**
     * Timestamp of the estimated departure time
     */
    departureDateTime: string;
    /**
     * Altitude, specified in feet
     */
    altitude: number;
    /**
     * Override total estimated time en route, in minutes
     */
    eteOverride?: number;
    /**
     * Override fuel endurance stored in minutes
     */
    fuelEnduranceOverride?: number;
    performanceInfo?: PerformanceInfo;
    /**
     * String of waypoint identifiers from which to create a route.  This is not required in a /create operation, but is required in /create-from-string
     */
    clearanceString?: string;
    computedPerf?: RoutePerf;
    /**
     * Flag indicating that the PERF data contained in the route is valid. When the flag is false, the PERF structures may contain data, but it is indeterminate as to whether the data is valid or not.
     */
    perfDataValid: boolean;
    garinc702AString?: string;
}
export enum RouteV2RouteType {
    ATC_PROPOSED = 'ATC_PROPOSED',
    ATC_FILED = 'ATC_FILED',
    USER_COMPUTED_ROUTE = 'USER_COMPUTED_ROUTE',
    PDC_ROUTE = 'PDC_ROUTE',
    ATC_FILED_AMENDED = 'ATC_FILED_AMENDED',
    UNKNOWN = 'UNKNOWN'
};
export enum RouteV2RouteClientTypes {
    IOS_GP = 'IOS_GP',
    ANDROID_GP = 'ANDROID_GP',
    WEB_CLIENT = 'WEB_CLIENT',
    ORCHESTRATOR_SERVICE = 'ORCHESTRATOR_SERVICE'
};



