<div class="modal-full">
  <div class="modal-header"
      slot="header">
    <div class="invisible"></div>
    <p class="modal-title">Search</p>
    <div class="close-button-container header-button-container">
      <button class="close-button"
        (click)="dismissal()">
        <fly-icon [icon]="icons.close"></fly-icon>
      </button>
    </div>
  </div>
  <fly-tab-group [(selectedTab)]="currentTab">
    <fly-tab *ngFor="let tab of tabs">
    {{ tab.label }}
    </fly-tab>
  </fly-tab-group>
  <div [ngSwitch]="currentTab">
    <div *ngSwitchCase="0">
      <pilot-search-form [searchText]="searchTerm"
        [searchOrigin]="searchOrigin"
        [viewMode]="viewMode"
        (tokenSelected)="handleTokenSelected($event)"></pilot-search-form>
    </div>
    <div *ngSwitchCase="1">
      <pilot-recents-tab (recentSelected)="handleRecentSelected($event)"></pilot-recents-tab>
    </div>
  </div>
</div>
