<ng-container [formGroup]="latLonForm">
  <form class="form">
    <fly-select formControlName="format"
      label="Format"
      [options]="formatOptions"
      [errorMap]="errorMap">
    </fly-select>
    <div class="fields">
      @if (formatSelected === 'hddd.dddddd°') {
        <fly-input formControlName="latDeg"
          label="Latitude"
          [errorStrings]="errorStrings"
          placeholder="N00.000000">
        </fly-input>
        <span class="symbol">°</span>
      }
      @else {
        <fly-input class="min-sec"
          formControlName="latDeg"
          label="Latitude"
          [errorStrings]="errorStrings"
          placeholder="N00">
        </fly-input>
        <span class="symbol">°</span>
        @if (formatSelected === 'hddd°mm.mmm\'') {
          <fly-input class="min-sec"
            formControlName="latMin"
            [errorStrings]="errorStrings"
            placeholder="00.000">
          </fly-input>
          <span class="symbol">'</span>
        } @else if (formatSelected === 'hddd°mm\'ss.s\"') {
          <fly-input class="min-sec"
            formControlName="latMin"
            [errorStrings]="errorStrings"
            placeholder="00">
          </fly-input>
          <span class="symbol">'</span>
          <fly-input class="min-sec"
            formControlName="latSec"
            [errorStrings]="errorStrings"
            placeholder="00.0">
          </fly-input>
          <span class="symbol">"</span>
        }
      }
    </div>
    @if (latLonForm.controls.latDeg.errors?.['range'] !== true && latLonForm.controls.latMin.errors?.['range'] !== true &&
    latLonForm.controls.latSec.errors?.['range'] !== true && latLonForm.errors?.['latTooBig'] === true) {
    <div class="error-text">{{ errorStrings.latTooBig }}</div>
  }
    <div class="fields">
      @if (formatSelected === 'hddd.dddddd°') {
        <fly-input formControlName="lonDeg"
          label="Longitude"
          [errorStrings]="errorStrings"
          placeholder="W000.000000">
        </fly-input>
        <span class="symbol">&deg;</span>
      }
      @else {
        <fly-input class="min-sec"
          formControlName="lonDeg"
          label="Longitude"
          [errorStrings]="errorStrings"
          placeholder="W000">
        </fly-input>
        <span class="symbol">&deg;</span>
        @if (formatSelected === 'hddd°mm.mmm\'') {
          <fly-input class="min-sec"
            formControlName="lonMin"
            [errorStrings]="errorStrings"
            placeholder="00.000">
          </fly-input>
          <span class="symbol">'</span>
        } @else if (formatSelected === 'hddd°mm\'ss.s\"') {
          <fly-input class="min-sec"
            formControlName="lonMin"
            [errorStrings]="errorStrings"
            placeholder="00">
          </fly-input>
          <span class="symbol">'</span>
          <fly-input class="min-sec"
            formControlName="lonSec"
            [errorStrings]="errorStrings"
            placeholder="00.0">
          </fly-input>
          <span class="symbol">"</span>
        }
      }
    </div>
    @if (latLonForm.controls.lonDeg.errors?.['range'] !== true && latLonForm.controls.lonMin.errors?.['range'] !== true &&
      latLonForm.controls.lonSec.errors?.['range'] !== true && latLonForm.errors?.['lonTooBig'] === true) {
      <div class="error-text">{{ errorStrings.lonTooBig }}</div>
    }
  </form>
</ng-container>
