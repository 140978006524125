import * as i0 from '@angular/core';
import { Injectable, Inject, InjectionToken, SecurityContext, inject, signal, LOCALE_ID } from '@angular/core';
import { throwError, catchError, tap, timeout, switchMap, of, shareReplay, map, take, forkJoin, Observable, debounceTime } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import * as i1 from '@angular/common/http';
import { HttpStatusCode, HttpParams, HttpClient, HttpContextToken, HttpHeaders, HttpContext } from '@angular/common/http';
import * as i5 from '@angular/platform-browser';
import * as i2 from '@angular/router';
import { Router } from '@angular/router';
const InternationalCountryCodeList = {
  us_UnitedStatesOfAmerica: {
    country: 'United States of America',
    countryAbbreviation: 'us',
    phoneCountryCode: '1'
  },
  ac_AscensionIsland: {
    country: 'Ascension Island',
    countryAbbreviation: 'ac',
    phoneCountryCode: '247'
  },
  ad_Andorra: {
    country: 'Andorra',
    countryAbbreviation: 'ad',
    phoneCountryCode: '376'
  },
  ae_UnitedArabEmirates: {
    country: 'United Arab Emirates',
    countryAbbreviation: 'ae',
    phoneCountryCode: '971'
  },
  af_Afghanistan: {
    country: 'Afghanistan',
    countryAbbreviation: 'af',
    phoneCountryCode: '93'
  },
  ag_AntiguaAndBarbuda: {
    country: 'Antigua and Barbuda',
    countryAbbreviation: 'ag',
    phoneCountryCode: '1'
  },
  ai_Anguilla: {
    country: 'Anguilla',
    countryAbbreviation: 'ai',
    phoneCountryCode: '1'
  },
  al_Albania: {
    country: 'Albania',
    countryAbbreviation: 'al',
    phoneCountryCode: '355'
  },
  am_Armenia: {
    country: 'Armenia',
    countryAbbreviation: 'am',
    phoneCountryCode: '374'
  },
  ao_Angola: {
    country: 'Angola',
    countryAbbreviation: 'ao',
    phoneCountryCode: '244'
  },
  //none found for aq_Antarctica
  //none found for arab_LeagueOfArabStates
  ar_Argentina: {
    country: 'Argentina',
    countryAbbreviation: 'ar',
    phoneCountryCode: '54'
  },
  as_AmericanSamoa: {
    country: 'American Samoa',
    countryAbbreviation: 'as',
    phoneCountryCode: '1'
  },
  at_Austria: {
    country: 'Austria',
    countryAbbreviation: 'at',
    phoneCountryCode: '43'
  },
  au_Australia: {
    country: 'Australia',
    countryAbbreviation: 'au',
    phoneCountryCode: '61'
  },
  aw_Aruba: {
    country: 'Aruba',
    countryAbbreviation: 'aw',
    phoneCountryCode: '297'
  },
  ax_AlandIslands: {
    country: 'Aland Islands',
    countryAbbreviation: 'ax',
    phoneCountryCode: '358'
  },
  az_Azerbaijan: {
    country: 'Azerbaijan',
    countryAbbreviation: 'az',
    phoneCountryCode: '994'
  },
  ba_BosniaAndHerzegovina: {
    country: 'Bosnia and Herzegovina',
    countryAbbreviation: 'ba',
    phoneCountryCode: '387'
  },
  bb_Barbados: {
    country: 'Barbados',
    countryAbbreviation: 'bb',
    phoneCountryCode: '1'
  },
  bd_Bangladesh: {
    country: 'Bangladesh',
    countryAbbreviation: 'bd',
    phoneCountryCode: '880'
  },
  be_Belgium: {
    country: 'Belgium',
    countryAbbreviation: 'be',
    phoneCountryCode: '32'
  },
  bf_BurkinaFaso: {
    country: 'Burkina Faso',
    countryAbbreviation: 'bf',
    phoneCountryCode: '226'
  },
  bg_Bulgaria: {
    country: 'Bulgaria',
    countryAbbreviation: 'bg',
    phoneCountryCode: '359'
  },
  bh_Bahrain: {
    country: 'Bahrain',
    countryAbbreviation: 'bh',
    phoneCountryCode: '973'
  },
  bi_Burundi: {
    country: 'Burundi',
    countryAbbreviation: 'bi',
    phoneCountryCode: '257'
  },
  bj_Benin: {
    country: 'Benin',
    countryAbbreviation: 'bj',
    phoneCountryCode: '229'
  },
  bl_SaintBarthelemy: {
    country: 'Saint Barthelemy',
    countryAbbreviation: 'bl',
    phoneCountryCode: '590'
  },
  bm_Bermuda: {
    country: 'Bermuda',
    countryAbbreviation: 'bm',
    phoneCountryCode: '1'
  },
  bn_BruneiDarussalam: {
    country: 'Brunei Darussalam',
    countryAbbreviation: 'bn',
    phoneCountryCode: '673'
  },
  bo_Bolivia: {
    country: 'Bolivia',
    countryAbbreviation: 'bo',
    phoneCountryCode: '591'
  },
  bq_Bonaire: {
    country: 'Bonaire',
    countryAbbreviation: 'bq',
    phoneCountryCode: '599'
  },
  br_Brazil: {
    country: 'Brazil',
    countryAbbreviation: 'br',
    phoneCountryCode: '55'
  },
  bs_Bahamas: {
    country: 'Bahamas',
    countryAbbreviation: 'bs',
    phoneCountryCode: '1'
  },
  bt_Bhutan: {
    country: 'Bhutan',
    countryAbbreviation: 'bt',
    phoneCountryCode: '975'
  },
  bv_BouvetIsland: {
    country: 'Bouvet Island',
    countryAbbreviation: 'bv',
    phoneCountryCode: '47' //TODO: double check
  },
  bw_Botswana: {
    country: 'Botswana',
    countryAbbreviation: 'bw',
    phoneCountryCode: '267'
  },
  by_Belarus: {
    country: 'Belarus',
    countryAbbreviation: 'by',
    phoneCountryCode: '375'
  },
  bz_Belize: {
    country: 'Belize',
    countryAbbreviation: 'bz',
    phoneCountryCode: '501'
  },
  ca_Canada: {
    country: 'Canada',
    countryAbbreviation: 'ca',
    phoneCountryCode: '1'
  },
  cc_CocosIslands: {
    country: 'Cocos Islands',
    countryAbbreviation: 'cc',
    phoneCountryCode: '61'
  },
  cd_DemocraticRepublicOfTheCongo: {
    country: 'Democratic Republic of the Congo',
    countryAbbreviation: 'cd',
    phoneCountryCode: '243'
  },
  //none found for cefta_CentralEuropeanFreeTradeAgreement
  cf_CentralAfricanRepublic: {
    country: 'Central African Republic',
    countryAbbreviation: 'cf',
    phoneCountryCode: '236'
  },
  cg_RepublicOfTheCongo: {
    country: 'Republic of the Congo',
    countryAbbreviation: 'cg',
    phoneCountryCode: '242'
  },
  ch_Switzerland: {
    country: 'Switzerland',
    countryAbbreviation: 'ch',
    phoneCountryCode: '41'
  },
  ci_CotedIvoire: {
    country: 'Cote d\'Ivoire',
    countryAbbreviation: 'ci',
    phoneCountryCode: '225'
  },
  ck_CookIslands: {
    country: 'Cook Islands',
    countryAbbreviation: 'ck',
    phoneCountryCode: '682'
  },
  cl_Chile: {
    country: 'Chile',
    countryAbbreviation: 'cl',
    phoneCountryCode: '56'
  },
  cm_Cameroon: {
    country: 'Cameroon',
    countryAbbreviation: 'cm',
    phoneCountryCode: '237'
  },
  cn_China: {
    country: 'China',
    countryAbbreviation: 'cn',
    phoneCountryCode: '86'
  },
  co_Colombia: {
    country: 'Colombia',
    countryAbbreviation: 'co',
    phoneCountryCode: '57'
  },
  //none found for cp_ClippertonIsland
  cr_CostaRica: {
    country: 'Costa Rica',
    countryAbbreviation: 'cr',
    phoneCountryCode: '506'
  },
  cu_Cuba: {
    country: 'Cuba',
    countryAbbreviation: 'cu',
    phoneCountryCode: '53'
  },
  cv_CaboVerde: {
    country: 'Cabo Verde',
    countryAbbreviation: 'cv',
    phoneCountryCode: '238'
  },
  cw_Curacao: {
    country: 'Curacao',
    countryAbbreviation: 'cw',
    phoneCountryCode: '599'
  },
  cx_ChristmasIsland: {
    country: 'Christmas Island',
    countryAbbreviation: 'cx',
    phoneCountryCode: '61'
  },
  cy_Cyprus: {
    country: 'Cyprus',
    countryAbbreviation: 'cy',
    phoneCountryCode: '357'
  },
  cz_CzechRepublic: {
    country: 'Czech Republic',
    countryAbbreviation: 'cz',
    phoneCountryCode: '420'
  },
  de_Germany: {
    country: 'Germany',
    countryAbbreviation: 'de',
    phoneCountryCode: '49'
  },
  dg_DiegoGarcia: {
    country: 'Diego Garcia',
    countryAbbreviation: 'dg',
    phoneCountryCode: '246'
  },
  dj_Djibouti: {
    country: 'Djibouti',
    countryAbbreviation: 'dj',
    phoneCountryCode: '253'
  },
  dk_Denmark: {
    country: 'Denmark',
    countryAbbreviation: 'dk',
    phoneCountryCode: '45'
  },
  dm_Dominica: {
    country: 'Dominica',
    countryAbbreviation: 'dm',
    phoneCountryCode: '1'
  },
  do_DominicanRepublic: {
    country: 'Dominican Republic',
    countryAbbreviation: 'do',
    phoneCountryCode: '1'
  },
  dz_Algeria: {
    country: 'Algeria',
    countryAbbreviation: 'dz',
    phoneCountryCode: '213'
  },
  //none found for eac_EastAfricanCommunity
  ec_Ecuador: {
    country: 'Ecuador',
    countryAbbreviation: 'ec',
    phoneCountryCode: '593'
  },
  ee_Estonia: {
    country: 'Estonia',
    countryAbbreviation: 'ee',
    phoneCountryCode: '372'
  },
  eg_Egypt: {
    country: 'Egypt',
    countryAbbreviation: 'eg',
    phoneCountryCode: '20'
  },
  eh_WesternSahara: {
    country: 'Western Sahara',
    countryAbbreviation: 'eh',
    phoneCountryCode: '212'
  },
  er_Eritrea: {
    country: 'Eritrea',
    countryAbbreviation: 'er',
    phoneCountryCode: '291'
  },
  es_ct_Catalonia: {
    country: 'Catalonia',
    countryAbbreviation: 'es-ct',
    phoneCountryCode: '34'
  },
  es_ga_Galicia: {
    country: 'Galicia',
    countryAbbreviation: 'es-ga',
    phoneCountryCode: '34'
  },
  es_pv_BasqueCountry: {
    country: 'Basque Country',
    countryAbbreviation: 'es-pv',
    phoneCountryCode: '34'
  },
  es_Spain: {
    country: 'Spain',
    countryAbbreviation: 'es',
    phoneCountryCode: '34'
  },
  et_Ethiopia: {
    country: 'Ethiopia',
    countryAbbreviation: 'et',
    phoneCountryCode: '251'
  },
  //none found for eu_Europe
  fi_Finland: {
    country: 'Finland',
    countryAbbreviation: 'fi',
    phoneCountryCode: '358'
  },
  fj_Fiji: {
    country: 'Fiji',
    countryAbbreviation: 'fj',
    phoneCountryCode: '679'
  },
  fk_FalklandIslands: {
    country: 'Falkland Islands',
    countryAbbreviation: 'fk',
    phoneCountryCode: '500'
  },
  fm_FederatedStatesOfMicronesia: {
    country: 'Federated States Of Micronesia',
    countryAbbreviation: 'fm',
    phoneCountryCode: '691'
  },
  fo_FaroeIslands: {
    country: 'Faroe Islands',
    countryAbbreviation: 'fo',
    phoneCountryCode: '298'
  },
  fr_France: {
    country: 'France',
    countryAbbreviation: 'fr',
    phoneCountryCode: '33'
  },
  ga_Gabon: {
    country: 'Gabon',
    countryAbbreviation: 'ga',
    phoneCountryCode: '241'
  },
  gb_eng_England: {
    country: 'England',
    countryAbbreviation: 'gb-eng',
    phoneCountryCode: '44'
  },
  gb_nir_NorthernIreland: {
    country: 'Northern Ireland',
    countryAbbreviation: 'gb-nir',
    phoneCountryCode: '44'
  },
  gb_sct_Scotland: {
    country: 'Scotland',
    countryAbbreviation: 'gb-sct',
    phoneCountryCode: '44'
  },
  gb_wls_Wales: {
    country: 'Wales',
    countryAbbreviation: 'gb-wls',
    phoneCountryCode: '44'
  },
  gb_UnitedKingdom: {
    country: 'United Kingdom',
    countryAbbreviation: 'gb',
    phoneCountryCode: '44'
  },
  gd_Grenada: {
    country: 'Grenada',
    countryAbbreviation: 'gd',
    phoneCountryCode: '1'
  },
  ge_Georgia: {
    country: 'Georgia',
    countryAbbreviation: 'ge',
    phoneCountryCode: '995'
  },
  gf_FrenchGuiana: {
    country: 'French Guiana',
    countryAbbreviation: 'gf',
    phoneCountryCode: '594'
  },
  gg_Guernsey: {
    country: 'Guernsey',
    countryAbbreviation: 'gg',
    phoneCountryCode: '44'
  },
  gh_Ghana: {
    country: 'Ghana',
    countryAbbreviation: 'gh',
    phoneCountryCode: '233'
  },
  gi_Gibraltar: {
    country: 'Gibraltar',
    countryAbbreviation: 'gi',
    phoneCountryCode: '350'
  },
  gl_Greenland: {
    country: 'Greenland',
    countryAbbreviation: 'gl',
    phoneCountryCode: '299'
  },
  gm_Gambia: {
    country: 'Gambia',
    countryAbbreviation: 'gm',
    phoneCountryCode: '220'
  },
  gn_Guinea: {
    country: 'Guinea',
    countryAbbreviation: 'gn',
    phoneCountryCode: '224'
  },
  gp_Guadeloupe: {
    country: 'Guadeloupe',
    countryAbbreviation: 'gp',
    phoneCountryCode: '590'
  },
  gq_EquatorialGuinea: {
    country: 'Equatorial Guinea',
    countryAbbreviation: 'gq',
    phoneCountryCode: '240'
  },
  gr_Greece: {
    country: 'Greece',
    countryAbbreviation: 'gr',
    phoneCountryCode: '30'
  },
  gs_SouthGeorgiaAndTheSouthSandwichIslands: {
    country: 'South Georgia and the South Sandwich Islands',
    countryAbbreviation: 'gs',
    phoneCountryCode: '500'
  },
  gt_Guatemala: {
    country: 'Guatemala',
    countryAbbreviation: 'gt',
    phoneCountryCode: '502'
  },
  gu_Guam: {
    country: 'Guam',
    countryAbbreviation: 'gu',
    phoneCountryCode: '1'
  },
  gw_GuineBissau: {
    country: 'Guine Bissau',
    countryAbbreviation: 'gw',
    phoneCountryCode: '245'
  },
  gy_Guyana: {
    country: 'Guyana',
    countryAbbreviation: 'gy',
    phoneCountryCode: '592'
  },
  hk_HongKong: {
    country: 'Hong Kong',
    countryAbbreviation: 'hk',
    phoneCountryCode: '852'
  },
  hm_HeardIslandAndMcDonaldIslands: {
    country: 'Heard Island and McDonald Islands',
    countryAbbreviation: 'hm',
    phoneCountryCode: '672'
  },
  hn_Honduras: {
    country: 'Honduras',
    countryAbbreviation: 'hn',
    phoneCountryCode: '504'
  },
  hr_Croatia: {
    country: 'Croatia',
    countryAbbreviation: 'hr',
    phoneCountryCode: '385'
  },
  ht_Haiti: {
    country: 'Haiti',
    countryAbbreviation: 'ht',
    phoneCountryCode: '509'
  },
  hu_Hungary: {
    country: 'Hungary',
    countryAbbreviation: 'hu',
    phoneCountryCode: '36'
  },
  ic_CanaryIslands: {
    country: 'Canary Island',
    countryAbbreviation: 'ic',
    phoneCountryCode: '34'
  },
  id_Indonesia: {
    country: 'Indonesia',
    countryAbbreviation: 'id',
    phoneCountryCode: '62'
  },
  ie_Ireland: {
    country: 'Ireland',
    countryAbbreviation: 'ie',
    phoneCountryCode: '353'
  },
  il_Israel: {
    country: 'Israel',
    countryAbbreviation: 'il',
    phoneCountryCode: '972'
  },
  im_IsleOfMan: {
    country: 'Isle Of Man',
    countryAbbreviation: 'im',
    phoneCountryCode: '44'
  },
  in_India: {
    country: 'India',
    countryAbbreviation: 'in',
    phoneCountryCode: '91'
  },
  io_BritishIndianOceanTerritory: {
    country: 'British Indian Ocean Territory',
    countryAbbreviation: 'io',
    phoneCountryCode: '246'
  },
  iq_Iraq: {
    country: 'Iraq',
    countryAbbreviation: 'iq',
    phoneCountryCode: '964'
  },
  ir_Iran: {
    country: 'Iran',
    countryAbbreviation: 'ir',
    phoneCountryCode: '98'
  },
  is_Iceland: {
    country: 'Iceland',
    countryAbbreviation: 'is',
    phoneCountryCode: '354'
  },
  it_Italy: {
    country: 'Italy',
    countryAbbreviation: 'it',
    phoneCountryCode: '39'
  },
  je_Jersey: {
    country: 'Jersey',
    countryAbbreviation: 'je',
    phoneCountryCode: '44'
  },
  jm_Jamaica: {
    country: 'Jamaica',
    countryAbbreviation: 'jm',
    phoneCountryCode: '1'
  },
  jo_Jordan: {
    country: 'Jordan',
    countryAbbreviation: 'jo',
    phoneCountryCode: '962'
  },
  jp_Japan: {
    country: 'Japan',
    countryAbbreviation: 'jp',
    phoneCountryCode: '81'
  },
  ke_Kenya: {
    country: 'Kenya',
    countryAbbreviation: 'ke',
    phoneCountryCode: '254'
  },
  kg_Kyrgyzstan: {
    country: 'Kyrgyzstan',
    countryAbbreviation: 'kg',
    phoneCountryCode: '996'
  },
  kh_Cambodia: {
    country: 'Cambodia',
    countryAbbreviation: 'kh',
    phoneCountryCode: '855'
  },
  ki_Kiribati: {
    country: 'Kiribati',
    countryAbbreviation: 'ki',
    phoneCountryCode: '686'
  },
  km_Comoros: {
    country: 'Comoros',
    countryAbbreviation: 'km',
    phoneCountryCode: '269'
  },
  kn_SaintKittsAndNevis: {
    country: 'Saint Kitts and Nevis',
    countryAbbreviation: 'kn',
    phoneCountryCode: '1'
  },
  kp_NorthKorea: {
    country: 'North Korea',
    countryAbbreviation: 'kp',
    phoneCountryCode: '850'
  },
  kr_SouthKorea: {
    country: 'South Korea',
    countryAbbreviation: 'kr',
    phoneCountryCode: '82'
  },
  kw_Kuwait: {
    country: 'Kuwait',
    countryAbbreviation: 'kw',
    phoneCountryCode: '965'
  },
  ky_CaymanIslands: {
    country: 'Cayman Islands',
    countryAbbreviation: 'ky',
    phoneCountryCode: '1'
  },
  kz_Kazakhstan: {
    country: 'Kazakhstan',
    countryAbbreviation: 'kz',
    phoneCountryCode: '7'
  },
  la_Laos: {
    country: 'Laos',
    countryAbbreviation: 'la',
    phoneCountryCode: '856'
  },
  lb_Lebanon: {
    country: 'Lebanon',
    countryAbbreviation: 'lb',
    phoneCountryCode: '961'
  },
  lc_SaintLucia: {
    country: 'Saint Lucia',
    countryAbbreviation: 'lc',
    phoneCountryCode: '1'
  },
  li_Liechtenstein: {
    country: 'Liechtenstein',
    countryAbbreviation: 'li',
    phoneCountryCode: '423'
  },
  lk_SriLanka: {
    country: 'Sri Lanka',
    countryAbbreviation: 'lk',
    phoneCountryCode: '94'
  },
  lr_Liberia: {
    country: 'Liberia',
    countryAbbreviation: 'lr',
    phoneCountryCode: '231'
  },
  ls_Lesotho: {
    country: 'Lesotho',
    countryAbbreviation: 'ls',
    phoneCountryCode: '266'
  },
  lt_Lithuania: {
    country: 'Lithuania',
    countryAbbreviation: 'lt',
    phoneCountryCode: '370'
  },
  lu_Luxembourg: {
    country: 'Luxembourg',
    countryAbbreviation: 'lu',
    phoneCountryCode: '352'
  },
  lv_Latvia: {
    country: 'Latvia',
    countryAbbreviation: 'lv',
    phoneCountryCode: '371'
  },
  ly_Libya: {
    country: 'Libya',
    countryAbbreviation: 'ly',
    phoneCountryCode: '218'
  },
  ma_Morocco: {
    country: 'Morocco',
    countryAbbreviation: 'ma',
    phoneCountryCode: '212'
  },
  mc_Monaco: {
    country: 'Monaco',
    countryAbbreviation: 'mc',
    phoneCountryCode: '377'
  },
  md_Moldova: {
    country: 'Moldova',
    countryAbbreviation: 'md',
    phoneCountryCode: '373'
  },
  me_Montenegro: {
    country: 'Montenegro',
    countryAbbreviation: 'me',
    phoneCountryCode: '382'
  },
  mf_SaintMartin: {
    country: 'Saint Martin',
    countryAbbreviation: 'mf',
    phoneCountryCode: '590'
  },
  mg_Madagascar: {
    country: 'Madagascar',
    countryAbbreviation: 'mg',
    phoneCountryCode: '261'
  },
  mh_MarshallIslands: {
    country: 'Marshall Islands',
    countryAbbreviation: 'mh',
    phoneCountryCode: '692'
  },
  mk_NorthMacedonia: {
    country: 'North Macedonia',
    countryAbbreviation: 'mk',
    phoneCountryCode: '389'
  },
  ml_Mali: {
    country: 'Mali',
    countryAbbreviation: 'ml',
    phoneCountryCode: '223'
  },
  mm_Myanmar: {
    country: 'Myanmar',
    countryAbbreviation: 'mm',
    phoneCountryCode: '95'
  },
  mn_Mongolia: {
    country: 'Mongolia',
    countryAbbreviation: 'mn',
    phoneCountryCode: '976'
  },
  mo_Macau: {
    country: 'Macau',
    countryAbbreviation: 'mo',
    phoneCountryCode: '853'
  },
  mp_NorthernMarianaIslands: {
    country: 'Northern Mariana Islands',
    countryAbbreviation: 'mp',
    phoneCountryCode: '1'
  },
  mq_Martinique: {
    country: 'Martinique',
    countryAbbreviation: 'mq',
    phoneCountryCode: '596'
  },
  mr_Mauritania: {
    country: 'Mauritania',
    countryAbbreviation: 'mr',
    phoneCountryCode: '222'
  },
  ms_Montserrat: {
    country: 'Montserrat',
    countryAbbreviation: 'ms',
    phoneCountryCode: '1'
  },
  mt_Malta: {
    country: 'Malta',
    countryAbbreviation: 'mt',
    phoneCountryCode: '356'
  },
  mu_Mauritius: {
    country: 'Mauritius',
    countryAbbreviation: 'mu',
    phoneCountryCode: '230'
  },
  mv_Maldives: {
    country: 'Maldives',
    countryAbbreviation: 'mv',
    phoneCountryCode: '960'
  },
  mw_Malawi: {
    country: 'Malawi',
    countryAbbreviation: 'mw',
    phoneCountryCode: '265'
  },
  mx_Mexico: {
    country: 'Mexico',
    countryAbbreviation: 'mx',
    phoneCountryCode: '52'
  },
  my_Malaysia: {
    country: 'Malaysia',
    countryAbbreviation: 'my',
    phoneCountryCode: '60'
  },
  mz_Mozambique: {
    country: 'Mozambique',
    countryAbbreviation: 'mz',
    phoneCountryCode: '258'
  },
  na_Namibia: {
    country: 'Namibia',
    countryAbbreviation: 'na',
    phoneCountryCode: '264'
  },
  nc_NewCaledonia: {
    country: 'New Caledonia',
    countryAbbreviation: 'nc',
    phoneCountryCode: '687'
  },
  ne_Niger: {
    country: 'Niger',
    countryAbbreviation: 'ne',
    phoneCountryCode: '227'
  },
  nf_NorfolkIsland: {
    country: 'Norfolk Island',
    countryAbbreviation: 'nf',
    phoneCountryCode: '672'
  },
  ng_Nigeria: {
    country: 'Nigeria',
    countryAbbreviation: 'ng',
    phoneCountryCode: '234'
  },
  ni_Nicaragua: {
    country: 'Nicaragua',
    countryAbbreviation: 'ni',
    phoneCountryCode: '505'
  },
  nl_Netherlands: {
    country: 'Netherlands',
    countryAbbreviation: 'nl',
    phoneCountryCode: '31'
  },
  no_Norway: {
    country: 'Norway',
    countryAbbreviation: 'no',
    phoneCountryCode: '47'
  },
  np_Nepal: {
    country: 'Nepal',
    countryAbbreviation: 'np',
    phoneCountryCode: '977'
  },
  nr_Nauru: {
    country: 'Nauru',
    countryAbbreviation: 'nr',
    phoneCountryCode: '674'
  },
  nu_Niue: {
    country: 'Niue',
    countryAbbreviation: 'nu',
    phoneCountryCode: '683'
  },
  nz_NewZealand: {
    country: 'New Zealand',
    countryAbbreviation: 'nz',
    phoneCountryCode: '64'
  },
  om_Oman: {
    country: 'Oman',
    countryAbbreviation: 'om',
    phoneCountryCode: '968'
  },
  pa_Panama: {
    country: 'Panama',
    countryAbbreviation: 'pa',
    phoneCountryCode: '507'
  },
  pe_Peru: {
    country: 'Peru',
    countryAbbreviation: 'pe',
    phoneCountryCode: '51'
  },
  pf_FrenchPolynesia: {
    country: 'French Polynesia',
    countryAbbreviation: 'pf',
    phoneCountryCode: '689'
  },
  pg_PapuaNewGuinea: {
    country: 'Papua New Guinea',
    countryAbbreviation: 'pg',
    phoneCountryCode: '675'
  },
  ph_Philippines: {
    country: 'Philippines',
    countryAbbreviation: 'ph',
    phoneCountryCode: '63'
  },
  pk_Pakistan: {
    country: 'Pakistan',
    countryAbbreviation: 'pk',
    phoneCountryCode: '92'
  },
  pl_Poland: {
    country: 'Poland',
    countryAbbreviation: 'pl',
    phoneCountryCode: '48'
  },
  pm_SaintPierreAndMiquelon: {
    country: 'Saint Pierre and Miquelon',
    countryAbbreviation: 'pm',
    phoneCountryCode: '508'
  },
  pn_Pitcairn: {
    country: 'Pitcairn',
    countryAbbreviation: 'pn',
    phoneCountryCode: '64'
  },
  pr_PuertoRico: {
    country: 'Puerto Rico',
    countryAbbreviation: 'pr',
    phoneCountryCode: '1'
  },
  ps_StateOfPalestine: {
    country: 'State Of Palestine',
    countryAbbreviation: 'ps',
    phoneCountryCode: '970'
  },
  pt_Portugal: {
    country: 'Portugal',
    countryAbbreviation: 'pt',
    phoneCountryCode: '351'
  },
  pw_Palau: {
    country: 'Palau',
    countryAbbreviation: 'pw',
    phoneCountryCode: '680'
  },
  py_Paraguay: {
    country: 'Paraguay',
    countryAbbreviation: 'py',
    phoneCountryCode: '595'
  },
  qa_Qatar: {
    country: 'Qatar',
    countryAbbreviation: 'qa',
    phoneCountryCode: '974'
  },
  re_Reunion: {
    country: 'Reunion',
    countryAbbreviation: 're',
    phoneCountryCode: '262'
  },
  ro_Romania: {
    country: 'Romania',
    countryAbbreviation: 'ro',
    phoneCountryCode: '40'
  },
  rs_Serbia: {
    country: 'Serbia',
    countryAbbreviation: 'rs',
    phoneCountryCode: '381'
  },
  ru_Russia: {
    country: 'Russia',
    countryAbbreviation: 'ru',
    phoneCountryCode: '7'
  },
  rw_Rwanda: {
    country: 'Rwanda',
    countryAbbreviation: 'rw',
    phoneCountryCode: '250'
  },
  sa_SaudiArabia: {
    country: 'Saudi Arabia',
    countryAbbreviation: 'sa',
    phoneCountryCode: '966'
  },
  sb_SolomonIslands: {
    country: 'Solomon Islands',
    countryAbbreviation: 'sb',
    phoneCountryCode: '677'
  },
  sc_Seychelles: {
    country: 'Seychelles',
    countryAbbreviation: 'sc',
    phoneCountryCode: '248'
  },
  sd_Sudan: {
    country: 'Sudan',
    countryAbbreviation: 'sd',
    phoneCountryCode: '249'
  },
  se_Sweden: {
    country: 'Sweden',
    countryAbbreviation: 'se',
    phoneCountryCode: '46'
  },
  sg_Singapore: {
    country: 'Singapore',
    countryAbbreviation: 'sg',
    phoneCountryCode: '65'
  },
  sh_SaintHelena: {
    country: 'Saint Helena',
    countryAbbreviation: 'sh',
    phoneCountryCode: '290'
  },
  si_Slovenia: {
    country: 'Slovenia',
    countryAbbreviation: 'si',
    phoneCountryCode: '386'
  },
  sj_SvalbardAndJanMayen: {
    country: 'Svalbard and Jan Mayen',
    countryAbbreviation: 'sj',
    phoneCountryCode: '47'
  },
  sk_Slovakia: {
    country: 'Slovakia',
    countryAbbreviation: 'sk',
    phoneCountryCode: '421'
  },
  sl_SierraLeone: {
    country: 'Sierra Leone',
    countryAbbreviation: 'sl',
    phoneCountryCode: '232'
  },
  sm_SanMarino: {
    country: 'San Marino',
    countryAbbreviation: 'sm',
    phoneCountryCode: '378'
  },
  sn_Senegal: {
    country: 'Senegal',
    countryAbbreviation: 'sn',
    phoneCountryCode: '221'
  },
  so_Somalia: {
    country: 'Somalia',
    countryAbbreviation: 'so',
    phoneCountryCode: '252'
  },
  sr_Suriname: {
    country: 'Suriname',
    countryAbbreviation: 'sr',
    phoneCountryCode: '597'
  },
  ss_SouthSudan: {
    country: 'South Sudan',
    countryAbbreviation: 'ss',
    phoneCountryCode: '211'
  },
  st_SaoTomeAndPrincipe: {
    country: 'Sao Tome and Principe',
    countryAbbreviation: 'st',
    phoneCountryCode: '239'
  },
  sv_ElSalvador: {
    country: 'El Salvador',
    countryAbbreviation: 'sv',
    phoneCountryCode: '503'
  },
  sx_SintMaarten: {
    country: 'Sint Maarten',
    countryAbbreviation: 'sx',
    phoneCountryCode: '1'
  },
  sy_Syria: {
    country: 'Syria',
    countryAbbreviation: 'sy',
    phoneCountryCode: '963'
  },
  sz_Eswatini: {
    country: 'Eswatini',
    countryAbbreviation: 'sz',
    phoneCountryCode: '268'
  },
  ta_TristandaCunha: {
    country: 'Tristan da Cunha',
    countryAbbreviation: 'ta',
    phoneCountryCode: '290'
  },
  tc_TurksAndCaicosIslands: {
    country: 'Turks and Caicos Islands',
    countryAbbreviation: 'tc',
    phoneCountryCode: '1'
  },
  td_Chad: {
    country: 'Chad',
    countryAbbreviation: 'td',
    phoneCountryCode: '235'
  },
  tf_FrenchSouthernTerritories: {
    country: 'French Southern Territories',
    countryAbbreviation: 'tf',
    phoneCountryCode: '262'
  },
  tg_Togo: {
    country: 'Togo',
    countryAbbreviation: 'tg',
    phoneCountryCode: '228'
  },
  th_Thailand: {
    country: 'Thailand',
    countryAbbreviation: 'th',
    phoneCountryCode: '66'
  },
  tj_Tajikistan: {
    country: 'Tajikistan',
    countryAbbreviation: 'tj',
    phoneCountryCode: '992'
  },
  tk_Tokelau: {
    country: 'Tokelau',
    countryAbbreviation: 'tk',
    phoneCountryCode: '690'
  },
  tl_TimorLeste: {
    country: 'Timor Leste',
    countryAbbreviation: 'tl',
    phoneCountryCode: '670'
  },
  tm_Turkmenistan: {
    country: 'Turkmenistan',
    countryAbbreviation: 'tm',
    phoneCountryCode: '993'
  },
  tn_Tunisia: {
    country: 'Tunisia',
    countryAbbreviation: 'tn',
    phoneCountryCode: '216'
  },
  to_Tonga: {
    country: 'Tonga',
    countryAbbreviation: 'to',
    phoneCountryCode: '676'
  },
  tr_Turkiye: {
    country: 'Turkiye',
    countryAbbreviation: 'tr',
    phoneCountryCode: '90'
  },
  tt_TrinidadAndTobago: {
    country: 'Trinidad And Tobago',
    countryAbbreviation: 'tt',
    phoneCountryCode: '1'
  },
  tv_Tuvalu: {
    country: 'Tuvalu',
    countryAbbreviation: 'tv',
    phoneCountryCode: '688'
  },
  tw_Taiwan: {
    country: 'Taiwan',
    countryAbbreviation: 'tw',
    phoneCountryCode: '886'
  },
  tz_Tanzania: {
    country: 'Tanzania',
    countryAbbreviation: 'tz',
    phoneCountryCode: '255'
  },
  ua_Ukraine: {
    country: 'Ukraine',
    countryAbbreviation: 'ua',
    phoneCountryCode: '380'
  },
  ug_Uganda: {
    country: 'Uganda',
    countryAbbreviation: 'ug',
    phoneCountryCode: '256'
  },
  um_UnitedStatesMinorOutlyingIslands: {
    country: 'United States Minor Outlying Islands',
    countryAbbreviation: 'um',
    phoneCountryCode: '1'
  },
  //none found for un_UnitedNations
  uy_Uruguay: {
    country: 'Uruguay',
    countryAbbreviation: 'uy',
    phoneCountryCode: '598'
  },
  uz_Uzbekistan: {
    country: 'Uzbekistan',
    countryAbbreviation: 'uz',
    phoneCountryCode: '998'
  },
  va_HolySee: {
    country: 'Holy See',
    countryAbbreviation: 'va',
    phoneCountryCode: '39'
  },
  vc_SaintVincentAndTheGrenadines: {
    country: 'Saint Vincent and the Grenadines',
    countryAbbreviation: 'vc',
    phoneCountryCode: '1'
  },
  ve_Venezuela: {
    country: 'Venezuela',
    countryAbbreviation: 've',
    phoneCountryCode: '58'
  },
  vg_VirginIslands_British: {
    country: 'Virgin Islands (British)',
    countryAbbreviation: 'vg',
    phoneCountryCode: '1'
  },
  vi_VirginIslands_US: {
    country: 'Virgin Islands (US)',
    countryAbbreviation: 'vi',
    phoneCountryCode: '1'
  },
  vn_Vietnam: {
    country: 'Vietnam',
    countryAbbreviation: 'vn',
    phoneCountryCode: '84'
  },
  vu_Vanuatu: {
    country: 'Vanuatu',
    countryAbbreviation: 'vu',
    phoneCountryCode: '	678'
  },
  wf_WallisAndFutuna: {
    country: 'Wallis and Futuna',
    countryAbbreviation: 'wf',
    phoneCountryCode: '681'
  },
  ws_Samoa: {
    country: 'Samoa',
    countryAbbreviation: 'ws',
    phoneCountryCode: '685'
  },
  xk_Kosovo: {
    country: 'Kosovo',
    countryAbbreviation: 'xk',
    phoneCountryCode: '383'
  },
  //none found for xx_Unknown
  ye_Yemen: {
    country: 'Yemen',
    countryAbbreviation: 'ye',
    phoneCountryCode: '967'
  },
  yt_Mayotte: {
    country: 'Mayotte',
    countryAbbreviation: 'yt',
    phoneCountryCode: '262'
  },
  za_SouthAfrica: {
    country: 'South Africa',
    countryAbbreviation: 'za',
    phoneCountryCode: '27'
  },
  zm_Zambia: {
    country: 'Zambia',
    countryAbbreviation: 'zm',
    phoneCountryCode: '260'
  },
  zw_Zimbabwe: {
    country: 'Zimbabwe',
    countryAbbreviation: 'zw',
    phoneCountryCode: '263'
  }
};
var LocalStorageKey;
(function (LocalStorageKey) {
  LocalStorageKey["OAuthTokenResponse"] = "OAUTH_TOKEN_RESPONSE";
})(LocalStorageKey || (LocalStorageKey = {}));
/**
 * Cached items from local storage:
 * (1) are shared across tabs
 * (2) remain in storage until explicitly cleared
 */
class LocalStorageService {
  get(key) {
    return JSON.parse(localStorage.getItem(key) ?? 'null');
  }
  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  delete(key) {
    localStorage.removeItem(key);
  }
  clear() {
    localStorage.clear();
  }
  static {
    this.ɵfac = function LocalStorageService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LocalStorageService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LocalStorageService,
      factory: LocalStorageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class CookieService {
  constructor(document) {
    this.document = document;
  }
  get(key) {
    return this.getCurrentCookies().get(key);
  }
  set(key, value, optionalParameters = null) {
    if (optionalParameters != null) {
      this.document.cookie = `${key}=${value}${optionalParameters}`;
    } else {
      this.document.cookie = `${key}=${value}`;
    }
  }
  has(key) {
    return this.getCurrentCookies().has(key);
  }
  delete(key) {
    this.document.cookie = `${key}=; expires=${new Date().toUTCString()}; path=/;`;
  }
  getCurrentCookies() {
    const cookies = new Map();
    if (this.document.cookie !== '') {
      const cookieStrings = this.document.cookie.split('; ');
      cookieStrings.forEach(cookie => {
        const splitCookieStrings = cookie.split('=');
        cookies.set(splitCookieStrings[0], splitCookieStrings[1]);
      });
    }
    return cookies;
  }
  static {
    this.ɵfac = function CookieService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CookieService)(i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CookieService,
      factory: CookieService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CookieService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();
var ApiReadBaseUrl;
(function (ApiReadBaseUrl) {
  ApiReadBaseUrl["Dev"] = "https://avc-coredata-dev.garmin.com/customerReadService";
  ApiReadBaseUrl["Test"] = "https://avc-coredata-test.garmin.com/customerReadService";
  ApiReadBaseUrl["Stage"] = "https://avc-coredata-stg.garmin.com/customerReadService";
  ApiReadBaseUrl["Prod"] = "https://avc-coredata.garmin.com/customerReadService";
})(ApiReadBaseUrl || (ApiReadBaseUrl = {}));
var ApiWriteBaseUrl;
(function (ApiWriteBaseUrl) {
  ApiWriteBaseUrl["Dev"] = "https://avc-coredata-dev.garmin.com/customerWriteService";
  ApiWriteBaseUrl["Test"] = "https://avc-coredata-test.garmin.com/customerWriteService";
  ApiWriteBaseUrl["Stage"] = "https://avc-coredata-stg.garmin.com/customerWriteService";
  ApiWriteBaseUrl["Prod"] = "https://avc-coredata.garmin.com/customerWriteService";
})(ApiWriteBaseUrl || (ApiWriteBaseUrl = {}));
const CUSTOMER_SERVICE_CONFIG_TOKEN = new InjectionToken('CustomerServiceConfig');
class CustomerService {
  constructor(http, localStorageService, config) {
    this.http = http;
    this.localStorageService = localStorageService;
    this.config = config;
    switch (this.config.environment) {
      case 'PROD':
        this.readApiBaseUrl = `${ApiReadBaseUrl.Prod}/customers`;
        this.writeApiBaseUrl = `${ApiWriteBaseUrl.Prod}/customers`;
        break;
      case 'STAGE':
        this.readApiBaseUrl = `${ApiReadBaseUrl.Stage}/customers`;
        this.writeApiBaseUrl = `${ApiWriteBaseUrl.Stage}/customers`;
        break;
      case 'TEST':
        this.readApiBaseUrl = `${ApiReadBaseUrl.Test}/customers`;
        this.writeApiBaseUrl = `${ApiWriteBaseUrl.Test}/customers`;
        break;
      default:
        this.readApiBaseUrl = `${ApiReadBaseUrl.Dev}/customers`;
        this.writeApiBaseUrl = `${ApiWriteBaseUrl.Dev}/customers`;
        break;
    }
    if (this.config.readServiceLocationOverride != null) {
      this.readApiBaseUrl = `${this.config.readServiceLocationOverride}/customers`;
    }
    if (this.config.writeServiceLocationOverride != null) {
      this.writeApiBaseUrl = `${this.config.writeServiceLocationOverride}/customers`;
    }
  }
  createCustomer(customerGuid) {
    return this.http.post(this.writeApiBaseUrl, {
      customerGuid
    });
  }
  // Aviation Customer
  getCustomer(specifiedCustomerGuid) {
    const customerGuid = specifiedCustomerGuid ?? this.localStorageService.get(LocalStorageKey.OAuthTokenResponse)?.customerId;
    if (customerGuid == null) {
      return throwError(() => new Error('Unable to retrieve customerGuid'));
    }
    return this.getOrCreateCustomer(customerGuid);
  }
  getOrCreateCustomer(customerGuid, tryCreate = false) {
    return this.http.get(`${this.readApiBaseUrl}/${customerGuid}`).pipe(catchError(err => {
      if (err.status === HttpStatusCode.NotFound && tryCreate) {
        return this.createCustomer(customerGuid).pipe(tap(customer => {
          // Fire and forget, timeout after 30s to ensure completion.
          this.updateLastCustomerLogin(customer.customerGuid).pipe(timeout(30 * 1000)).subscribe();
        }));
      }
      return throwError(() => err);
    }));
  }
  // Garmin Customer
  getCustomerAccount(specifiedCustomerGuid) {
    const customerGuid = specifiedCustomerGuid ?? this.localStorageService.get(LocalStorageKey.OAuthTokenResponse)?.customerId;
    if (customerGuid == null) {
      return throwError(() => new Error('Unable to retrieve customerGuid'));
    }
    return this.http.post(`${this.readApiBaseUrl}/search/garmin-customer`, {
      customerGuid
    }).pipe(switchMap(customers => {
      if (customers.length > 0) {
        return of(customers[0]);
      } else {
        return throwError(() => new Error('Unable to retrieve customer account'));
      }
    }));
  }
  // Garmin Customer
  searchCustomerAccounts(searchQuery) {
    if (searchQuery.phoneNumber != null && searchQuery.phoneCountryCode == null && searchQuery.phoneAreaCode == null) {
      return throwError(() => new Error('Country code or area code must be provided if a phone number is specified'));
    }
    if ([searchQuery.customerGuid, searchQuery.legacyAvnUserUuid, searchQuery.legacyFlyGUserId, searchQuery.legacyGPUserId, searchQuery.legacyFltPlanUserId, searchQuery.username, searchQuery.email, searchQuery.customerKey].every(field => field == null)) {
      return throwError(() => new Error('At least one search field must be specified'));
    }
    return this.http.post(`${this.readApiBaseUrl}/search/garmin-customer`, searchQuery);
  }
  getLastCustomerLogin(specifiedCustomerGuid) {
    const customerGuid = specifiedCustomerGuid ?? this.localStorageService.get(LocalStorageKey.OAuthTokenResponse)?.customerId;
    if (customerGuid == null) {
      return throwError(() => new Error('Unable to retrieve customerGuid'));
    }
    return this.http.get(`${this.readApiBaseUrl}/${customerGuid}/last-login`).pipe(catchError(errorResponse => {
      if (errorResponse.status === HttpStatusCode.NotFound) {
        return of(null);
      } else {
        throw new Error('Unable to retrieve last customer login');
      }
    }));
  }
  updateLastCustomerLogin(specifiedCustomerGuid) {
    const customerGuid = specifiedCustomerGuid ?? this.localStorageService.get(LocalStorageKey.OAuthTokenResponse)?.customerId;
    if (customerGuid == null) {
      return throwError(() => new Error('Unable to retrieve customerGuid'));
    }
    return this.http.put(`${this.writeApiBaseUrl}/${customerGuid}/last-login`, {
      customerGuid,
      application: this.config.clientId
    });
  }
  static {
    this.ɵfac = function CustomerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CustomerService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(LocalStorageService), i0.ɵɵinject(CUSTOMER_SERVICE_CONFIG_TOKEN));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CustomerService,
      factory: CustomerService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomerService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: LocalStorageService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CUSTOMER_SERVICE_CONFIG_TOKEN]
    }]
  }], null);
})();
var ApiBaseUrl$3;
(function (ApiBaseUrl) {
  ApiBaseUrl["Dev"] = "https://avcloud-gateway-dev.garmin.com/webGateway/v2/auth";
  ApiBaseUrl["Test"] = "https://avcloud-gateway-test.garmin.com/webGateway/v2/auth";
  ApiBaseUrl["Stage"] = "https://avcloud-gateway-stg.garmin.com/webGateway/v2/auth";
  ApiBaseUrl["Prod"] = "https://avcloud-gateway.garmin.com/webGateway/v2/auth";
})(ApiBaseUrl$3 || (ApiBaseUrl$3 = {}));
var SsoBaseUrl;
(function (SsoBaseUrl) {
  SsoBaseUrl["Dev"] = "https://ssodev.garmin.com";
  SsoBaseUrl["Test"] = "https://ssotest.garmin.com";
  SsoBaseUrl["Stage"] = "https://ssostg.garmin.com";
  SsoBaseUrl["Prod"] = "https://sso.garmin.com";
})(SsoBaseUrl || (SsoBaseUrl = {}));
const AUTH_SERVICE_CONFIG_TOKEN = new InjectionToken('AuthServiceConfig');
class AuthService {
  constructor(cookieService, customerService, localStorageService, http, sanitizer, document, config) {
    this.cookieService = cookieService;
    this.customerService = customerService;
    this.localStorageService = localStorageService;
    this.http = http;
    this.sanitizer = sanitizer;
    this.document = document;
    this.config = config;
    this.pendingAuthentication = null;
    switch (this.config.environment) {
      case 'PROD':
        this.apiBaseUrl = ApiBaseUrl$3.Prod;
        this.ssoBaseUrl = SsoBaseUrl.Prod;
        break;
      case 'STAGE':
        this.apiBaseUrl = ApiBaseUrl$3.Stage;
        this.ssoBaseUrl = SsoBaseUrl.Stage;
        break;
      case 'TEST':
        this.apiBaseUrl = ApiBaseUrl$3.Test;
        this.ssoBaseUrl = SsoBaseUrl.Test;
        break;
      default:
        this.apiBaseUrl = ApiBaseUrl$3.Dev;
        this.ssoBaseUrl = SsoBaseUrl.Dev;
        break;
    }
    this.loginUrl = `${this.ssoBaseUrl}/sso/login?service=` + `${encodeURIComponent(this.getCurrentUrlWithoutTicket().href)}&gateway=true`;
    this.logoutUrl = `${this.ssoBaseUrl}/sso/logout?service=` + `${encodeURIComponent(this.document.location.origin)}`;
    /*
      LOCALE SHOULD NOT BE ENCODED INTO THE URL, IT SHOULD BE DETERMINED AUTOMATICALLY BY THE PORTAL PAGE.
      THE CURRENT PORTAL PAGE DOES NOT HANDLE THIS PROPERLY, SO THIS SHOULD BE UPDATED ONCE IT DOES.
    */
    this.portalUrl = `${this.ssoBaseUrl}/portal/sso/en-US/sign-in?` + `clientId=${encodeURIComponent(this.config.clientId)}` + `&service=${encodeURIComponent(this.getCurrentUrlWithoutTicket().href)}`;
  }
  isAuthenticated() {
    if (this.pendingAuthentication != null) {
      return this.pendingAuthentication;
    }
    const serviceTicket = new URL(this.document.URL).searchParams.get('ticket');
    const oAuthTokenResponse = this.localStorageService.get(LocalStorageKey.OAuthTokenResponse);
    if (serviceTicket != null) {
      this.pendingAuthentication = this.validateServiceTicket(serviceTicket, this.getCurrentUrlWithoutTicket().href).pipe(shareReplay(1));
      this.pendingAuthentication.subscribe(() => this.pendingAuthentication = null);
      return this.pendingAuthentication;
    } else if (!this.areAuthCookiesSet()) {
      this.localStorageService.delete(LocalStorageKey.OAuthTokenResponse);
      return of(false);
    } else if (oAuthTokenResponse != null) {
      this.pendingAuthentication = this.validateAccessToken(oAuthTokenResponse).pipe(shareReplay(1));
      this.pendingAuthentication.subscribe(() => this.pendingAuthentication = null);
      return this.pendingAuthentication;
    } else {
      this.redirectToUrl(this.loginUrl);
      return of(false);
    }
  }
  getSsoPortalUrl() {
    return this.portalUrl;
  }
  redirectToSsoLoginPortal() {
    this.redirectToUrl(this.portalUrl);
  }
  getCurrentUrlWithoutTicket() {
    const currentUrl = new URL(this.document.URL);
    currentUrl.searchParams.delete('ticket');
    return currentUrl;
  }
  logOut(redirectUrl) {
    this.revokeAccessToken().subscribe(() => {
      this.localStorageService.delete(LocalStorageKey.OAuthTokenResponse);
      this.redirectToUrl(redirectUrl != null ? `${this.ssoBaseUrl}/sso/logout?service=${encodeURIComponent(redirectUrl)}` : this.logoutUrl);
    });
  }
  validateAccessToken(oAuthTokenResponse) {
    return this.http.post(`${this.apiBaseUrl}/validate-access-token`, oAuthTokenResponse, {
      observe: 'response'
    }).pipe(switchMap(response => {
      if (response.status === HttpStatusCode.Ok && response.body != null) {
        return of(true);
      } else {
        this.localStorageService.delete(LocalStorageKey.OAuthTokenResponse);
        return of(false);
      }
    }), catchError(() => {
      this.localStorageService.delete(LocalStorageKey.OAuthTokenResponse);
      return of(false);
    }));
  }
  validateServiceTicket(serviceTicket, serviceUrl) {
    history.replaceState(null, '', this.getCurrentUrlWithoutTicket());
    const params = new HttpParams().append('oauthClientId', this.config.clientId);
    return this.http.post(`${this.apiBaseUrl}/validate-service-ticket`, {
      serviceTicket,
      serviceUrl
    }, {
      params
    }).pipe(tap(oAuthTokenResponse => {
      this.localStorageService.set(LocalStorageKey.OAuthTokenResponse, oAuthTokenResponse);
      this.updateLastCustomerLogin();
    }), map(() => {
      return true;
    }), catchError(() => {
      return of(false);
    }));
  }
  getCustomerGuid() {
    const customerGuid = this.localStorageService.get(LocalStorageKey.OAuthTokenResponse)?.customerId;
    if (customerGuid == null) {
      throw new Error('Unable to retrieve customerGuid');
    }
    return customerGuid;
  }
  /**
   * Checks the `GARMIN-SSO` and `GARMIN-SSO-CUST-GUID` cookies.
   *
   * Checks if `GARMIN-SSO` is preset.
   * Checks if the 'GARMIN-SSO-CUST-GUID' cookie matches the customerGuid in local storage.
   * @returns True if `GARMIN-SSO` is set and the logged in user's customerGuid matches the SSO CustGuid cookie.
   *   False otherwise.
   */
  areAuthCookiesSet() {
    if (!this.cookieService.has('GARMIN-SSO')) {
      return false;
    }
    try {
      const customerGuidSession = this.getCustomerGuid();
      const customerGuidCookie = this.cookieService.get('GARMIN-SSO-CUST-GUID');
      if (customerGuidCookie == null) {
        //Somehow, no cust guid cookie was found, but GARMIN-SSO was set.
        //Since GARMIN-SSO is the word of truth in _this_ case,
        //and no GARMIN-SSO-CUST-GUID cookie to contradict the sessionCustomerGuid, then return true.
        return true;
      }
      return customerGuidSession === customerGuidCookie;
    } catch {
      return false;
    }
  }
  updateLastCustomerLogin() {
    const customerGuid = this.getCustomerGuid();
    // Check if the user has ever logged in
    this.customerService.getLastCustomerLogin().pipe(switchMap(customerLogin => {
      return customerLogin != null
      // Since the user has logged in before, just update their last login date
      ? this.customerService.updateLastCustomerLogin()
      // Since the user has never logged in before, check if they have a customer object created yet
      : this.customerService.getCustomer().pipe(
      // Since the user does not have a customer object, create one
      catchError(() => this.customerService.createCustomer(customerGuid)),
      // Update the last login date since the user is guaranteed to have a customer object now
      switchMap(() => this.customerService.updateLastCustomerLogin()));
    }), take(1)).subscribe({
      error: () => {
        throw new Error('Unable to update last customer login');
      }
    });
  }
  revokeAccessToken() {
    const oAuthTokenResponse = this.localStorageService.get(LocalStorageKey.OAuthTokenResponse);
    return this.http.post(`${this.apiBaseUrl}/revoke-access-token`, {
      accessToken: oAuthTokenResponse?.accessToken
    }, {
      observe: 'response'
    }).pipe(switchMap(response => of(response.status === HttpStatusCode.NoContent)), catchError(() => {
      return of(false);
    }));
  }
  redirectToUrl(url) {
    const sanitizedUrl = this.sanitizer.sanitize(SecurityContext.URL, url);
    if (sanitizedUrl != null) {
      this.document.location.href = sanitizedUrl.replace(/%23$/, '');
    }
  }
  static {
    this.ɵfac = function AuthService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthService)(i0.ɵɵinject(CookieService), i0.ɵɵinject(CustomerService), i0.ɵɵinject(LocalStorageService), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i5.DomSanitizer), i0.ɵɵinject(DOCUMENT), i0.ɵɵinject(AUTH_SERVICE_CONFIG_TOKEN));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthService,
      factory: AuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: CookieService
  }, {
    type: CustomerService
  }, {
    type: LocalStorageService
  }, {
    type: i1.HttpClient
  }, {
    type: i5.DomSanitizer
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [AUTH_SERVICE_CONFIG_TOKEN]
    }]
  }], null);
})();
const AUTH_GUARD_CONFIG_TOKEN = new InjectionToken('AuthServiceConfig');
class AuthGuard {
  constructor(authService, router, config) {
    this.authService = authService;
    this.router = router;
    this.config = config;
  }
  canActivate(route) {
    return this.authService.isAuthenticated().pipe(switchMap(isAuthenticated => {
      if (isAuthenticated) {
        if (route.queryParamMap.has('ticket')) {
          return of(this.router.parseUrl(this.authService.getCurrentUrlWithoutTicket().pathname));
        } else {
          return of(true);
        }
      } else {
        return of(this.router.parseUrl(this.config.redirectRoute));
      }
    }));
  }
  static {
    this.ɵfac = function AuthGuard_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthGuard)(i0.ɵɵinject(AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(AUTH_GUARD_CONFIG_TOKEN));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthGuard,
      factory: AuthGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthGuard, [{
    type: Injectable
  }], () => [{
    type: AuthService
  }, {
    type: i2.Router
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [AUTH_GUARD_CONFIG_TOKEN]
    }]
  }], null);
})();
var ReadApiUrl;
(function (ReadApiUrl) {
  ReadApiUrl["DEV"] = "https://avc-coredata-dev.garmin.com/subscriptionReadService/subscriptions/customer/gp";
  ReadApiUrl["TEST"] = "https://avc-coredata-test.garmin.com/subscriptionReadService/subscriptions/customer/gp";
  ReadApiUrl["STAGE"] = "https://avc-coredata-stg.garmin.com/subscriptionReadService/subscriptions/customer/gp";
  ReadApiUrl["PROD"] = "https://avc-coredata.garmin.com/subscriptionReadService/subscriptions/customer/gp";
})(ReadApiUrl || (ReadApiUrl = {}));
const SUBSCRIPTION_SERVICE_CONFIG_TOKEN = new InjectionToken('SubscriptionServiceConfig');
/**
 * Use this service to get the set of feature types that the current customer
 * has subscribed to.
 */
class SubscriptionService {
  constructor() {
    this.config = inject(SUBSCRIPTION_SERVICE_CONFIG_TOKEN);
    this.localStorage = inject(LocalStorageService);
    this.http = inject(HttpClient);
    this.apiUrl = ReadApiUrl[this.config.environment];
    this.pendingSubscriptions = null;
  }
  /**
   * Use this to get a specific set of feature types that the current customer
   * has subscriptions to.
   *
   * @example
   * this.subscriptionService.getFeatureTypes('PRO_ENROUTE_PERF', 'PRO_WB')
   *   .subscribe((featureTypes) => {
   *     // do something interesting with `featureTypes`
   *   });
   *
   * @param identifiers The list of feature type identifiers to get.
   * @returns An array of `FeatureType` objects.
   */
  getFeatureTypes(...identifiers) {
    return this.getSubscriptions().pipe(switchMap(subscriptions => {
      return of(findFeatureTypes(subscriptions, identifiers));
    }));
  }
  /**
   * Use this to determine if the current customer has subscriptions to a
   * specific set of feature types.
   *
   * @example
   * this.subscriptionService.hasFeatureTypes('PRO_ENROUTE_PERF', 'PRO_WB')
   *   .subscribe((hasFeatureTypes) => {
   *     if (hasFeatureTypes) {
   *       // the customer has subscriptions to all feature types
   *     } else {
   *       // the customer does not have subscriptions to all these feature types
   *     }
   *   });
   *
   * @param identifiers The list of feature type identifiers to find.
   * @returns `true` if all `identifiers` are found; `false` otherwise.
   */
  hasFeatureTypes(...identifiers) {
    return this.getSubscriptions().pipe(switchMap(subscriptions => {
      const featureTypes = findFeatureTypes(subscriptions, identifiers);
      return of(featureTypes.length === 0 ? false : featureTypes.every(f => identifiers.includes(f.identifier)));
    }));
  }
  getSubscriptions() {
    if (this.pendingSubscriptions != null) return this.pendingSubscriptions;
    const currentCustomerGuid = this.localStorage.get(LocalStorageKey.OAuthTokenResponse)?.customerId ?? null;
    if (currentCustomerGuid == null) return of([]);
    this.pendingSubscriptions = this.fetchSubscriptions(currentCustomerGuid).pipe(shareReplay(1));
    return this.pendingSubscriptions;
  }
  fetchSubscriptions(customerGuid) {
    return this.http.get(`${this.apiUrl}?customerGuid=${customerGuid}`).pipe(catchError(error => {
      console.error(error);
      this.pendingSubscriptions = null; // clear our pending subscriptions so we can try again next time
      return of([]);
    }));
  }
  static {
    this.ɵfac = function SubscriptionService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SubscriptionService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SubscriptionService,
      factory: SubscriptionService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SubscriptionService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
/** Returns all the feature types matching the given identifiers. */
const findFeatureTypes = (subscriptions, identifiers) => {
  const featureTypes = [];
  for (const subscription of subscriptions) {
    for (const bestowal of subscription.gpSubscriptions.bestowals) {
      const features = bestowal.features?.filter(f => identifiers.includes(f.identifier)) ?? [];
      featureTypes.push(...features);
    }
  }
  return featureTypes;
};

// https://avcloud-coredata-api-ola.aviation.garmin.com/subscriptionReadService/swagger-ui/index.html#/subscription-read-only-controller/getCustomerGpSubscriptions

const SUBSCRIPTION_GUARD_CONFIG_TOKEN = new InjectionToken('SubscriptionServiceConfig');
const subscriptionGuard = route => {
  const config = inject(SUBSCRIPTION_GUARD_CONFIG_TOKEN);
  const subscriptionService = inject(SubscriptionService);
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.isAuthenticated().pipe(switchMap(isAuthenticated => {
    if (isAuthenticated) {
      if (route.queryParamMap.has('ticket')) {
        return of(router.parseUrl(authService.getCurrentUrlWithoutTicket().pathname));
      } else {
        //Check user subscriptions
        return subscriptionService.hasFeatureTypes('FULLAPP').pipe(map(hasFeatureTypes => {
          return hasFeatureTypes ? true : router.parseUrl(route.data['redirectRoute'] ?? config.redirectRoute);
        }));
      }
    } else {
      return of(router.parseUrl(route.data['redirectRoute'] ?? config.redirectRoute));
    }
  }));
};
const isGarminErrorResponse = obj => {
  return 'error' in obj && 'errorText' in obj && 'conversationId' in obj;
};
const obtainGarminRootCause = error => {
  try {
    return obtainGarminRootCause(JSON.parse(error.errorText));
  } catch (e) {
    return error.errorText;
  }
};
var AircraftFuelMeasureType;
(function (AircraftFuelMeasureType) {
  AircraftFuelMeasureType["GAL"] = "gal";
  AircraftFuelMeasureType["LB"] = "lb";
})(AircraftFuelMeasureType || (AircraftFuelMeasureType = {}));
var AircraftWakeTurbulenceType;
(function (AircraftWakeTurbulenceType) {
  AircraftWakeTurbulenceType["H"] = "H";
  AircraftWakeTurbulenceType["M"] = "M";
  AircraftWakeTurbulenceType["L"] = "L";
})(AircraftWakeTurbulenceType || (AircraftWakeTurbulenceType = {}));
var PerfEffectType;
(function (PerfEffectType) {
  PerfEffectType["FACTOR"] = "factor";
  PerfEffectType["OFFSET"] = "offset";
})(PerfEffectType || (PerfEffectType = {}));
var PerfFuelMeasureType;
(function (PerfFuelMeasureType) {
  PerfFuelMeasureType[PerfFuelMeasureType["WEIGHT"] = 0] = "WEIGHT";
  PerfFuelMeasureType[PerfFuelMeasureType["VOLUME"] = 1] = "VOLUME";
})(PerfFuelMeasureType || (PerfFuelMeasureType = {}));
var PerfPhase;
(function (PerfPhase) {
  PerfPhase["Climb"] = "climb";
  PerfPhase["Cruise"] = "cruise";
  PerfPhase["Descent"] = "descent";
})(PerfPhase || (PerfPhase = {}));
var PerfPowerSettingType;
(function (PerfPowerSettingType) {
  PerfPowerSettingType["PERCENTPOWER"] = "percentPower";
  PerfPowerSettingType["RPMMANIFOLDPRESSURE"] = "rpmManifoldPressure";
  PerfPowerSettingType["RPMTORQUE"] = "rpmTorque";
  PerfPowerSettingType["NULL"] = "null";
})(PerfPowerSettingType || (PerfPowerSettingType = {}));
var PerfTempType;
(function (PerfTempType) {
  PerfTempType["ABSOLUTE"] = "absolute";
  PerfTempType["ISA"] = "isa";
})(PerfTempType || (PerfTempType = {}));
const config = {
  parameters: ['weight', 'altitude', 'iasInput', 'inputRate', 'powerSetting', 'rpm', 'manifoldPressure', 'temperature', 'trueAirspeed', 'iasOutput', 'fuelFlow', 'outputRate', 'tableSelect'],
  parameterData: {
    altitude: {
      name: 'altitude',
      displayName: 'Altitude',
      type: 'number',
      input: false,
      units: [{
        name: 'Feet',
        display: 'FT'
      }],
      default: '',
      order: 2
    },
    iasInput: {
      name: 'iasInput',
      displayName: 'IAS',
      type: 'number',
      input: true,
      units: [{
        name: 'Knots',
        display: 'KT'
      }, {
        name: 'Miles / Hour',
        display: 'MPH'
      }, {
        name: 'Kilometers / Hour',
        display: 'KPH'
      }],
      default: '',
      order: 3
    },
    inputRate: {
      name: 'inputRate',
      displayName: 'Descent Rate',
      type: 'number',
      input: true,
      units: [{
        name: 'Feet / Minute',
        display: 'FPM'
      }, {
        name: 'Meters / Second',
        display: 'MPS'
      }, {
        name: 'Meters / Minute',
        display: 'MPM'
      }],
      default: '',
      order: 4
    },
    iasOutput: {
      name: 'iasOutput',
      displayName: 'IAS',
      type: 'number',
      input: true,
      units: [{
        name: 'Knots',
        display: 'KT'
      }, {
        name: 'Miles / Hour',
        display: 'MPH'
      }, {
        name: 'Kilometers / Hour',
        display: 'KPH'
      }],
      default: '',
      order: 11
    },
    outputRate: {
      name: 'outputRate',
      displayName: 'Climb Rate',
      type: 'number',
      input: true,
      units: [{
        name: 'Feet / Minute',
        display: 'FPM'
      }, {
        name: 'Meters / Second',
        display: 'MPS'
      }, {
        name: 'Meters / Minute',
        display: 'MPM'
      }],
      default: '',
      order: 13
    },
    manifoldPressure: {
      name: 'manifoldPressure',
      displayName: 'Man. Pressure',
      type: 'number',
      input: true,
      units: [{
        name: 'Inches of Mercury',
        display: 'IN HG'
      }],
      default: '',
      order: 7
    },
    powerSetting: {
      name: 'powerSetting',
      displayName: 'Power Setting',
      type: 'number',
      input: false,
      units: [{
        name: 'Percent',
        display: '%'
      }],
      default: '',
      order: 5
    },
    rpm: {
      name: 'rpm',
      displayName: 'RPM',
      type: 'number',
      input: false,
      units: [{
        name: 'rpm',
        display: 'RPM'
      }],
      default: '',
      order: 6
    },
    temperature: {
      name: 'temperature',
      displayName: 'Temperatures',
      type: 'number',
      input: false,
      units: [{
        name: 'Degrees Celsius',
        display: '°C'
      }, {
        name: 'Degrees Fahrenheit',
        display: '°F'
      }],
      default: '',
      order: 9
    },
    trueAirspeed: {
      name: 'trueAirspeed',
      displayName: 'TAS',
      type: 'number',
      input: true,
      units: [{
        name: 'Knots',
        display: 'KT'
      }, {
        name: 'Miles / Hour',
        display: 'MPH'
      }, {
        name: 'Kilometers / Hour',
        display: 'KPH'
      }],
      default: '',
      order: 10
    },
    weight: {
      name: 'weight',
      displayName: 'Weight',
      type: 'number',
      input: false,
      units: [{
        name: 'Pounds',
        display: 'LB'
      }, {
        name: 'Kilograms',
        display: 'KG'
      }],
      default: '',
      order: 1
    },
    fuelFlow: {
      name: 'fuelFlow',
      displayName: 'Fuel Flow',
      type: 'number',
      input: true,
      units: [{
        name: 'Gallons',
        display: 'GAL/HR'
      }, {
        name: 'Liters',
        display: 'L/HR'
      }, {
        name: 'Pounds',
        display: 'LB/HR'
      }, {
        name: 'Kilograms',
        display: 'KG/HR'
      }],
      default: '',
      order: 12
    },
    tableSelect: {
      name: 'tableSelect',
      displayName: '',
      type: 'string',
      input: false,
      units: [{
        name: '',
        display: ''
      }],
      default: '',
      order: 14
    }
  }
};
const PerfSampleParameterStrings = config.parameters;
var WbArmType;
(function (WbArmType) {
  WbArmType[WbArmType["FIXED"] = 0] = "FIXED";
  WbArmType[WbArmType["ADJUSTABLE"] = 1] = "ADJUSTABLE";
  WbArmType[WbArmType["INTERPOLATED_MOMENT"] = 2] = "INTERPOLATED_MOMENT";
})(WbArmType || (WbArmType = {}));
var WbArmUnit;
(function (WbArmUnit) {
  WbArmUnit[WbArmUnit["INCHES"] = 6] = "INCHES";
  WbArmUnit[WbArmUnit["MILLIMETERS"] = 8] = "MILLIMETERS";
})(WbArmUnit || (WbArmUnit = {}));
var WbCargoStationType;
(function (WbCargoStationType) {
  WbCargoStationType[WbCargoStationType["SEAT"] = 0] = "SEAT";
  WbCargoStationType[WbCargoStationType["BAGGAGE"] = 1] = "BAGGAGE";
  WbCargoStationType[WbCargoStationType["OTHER"] = 2] = "OTHER";
})(WbCargoStationType || (WbCargoStationType = {}));
var WbEnvelopeCollectionAxis;
(function (WbEnvelopeCollectionAxis) {
  WbEnvelopeCollectionAxis[WbEnvelopeCollectionAxis["LONGITUDINAL"] = 0] = "LONGITUDINAL";
  WbEnvelopeCollectionAxis[WbEnvelopeCollectionAxis["LATERAL"] = 1] = "LATERAL";
})(WbEnvelopeCollectionAxis || (WbEnvelopeCollectionAxis = {}));
var WbEnvelopeCollectionType;
(function (WbEnvelopeCollectionType) {
  WbEnvelopeCollectionType[WbEnvelopeCollectionType["WEIGHT_TO_LONGITUDINAL_CG"] = 0] = "WEIGHT_TO_LONGITUDINAL_CG";
  WbEnvelopeCollectionType[WbEnvelopeCollectionType["WEIGHT_TO_LONGITUDINAL_MOMENT"] = 1] = "WEIGHT_TO_LONGITUDINAL_MOMENT";
  WbEnvelopeCollectionType[WbEnvelopeCollectionType["WEIGHT_TO_LATERAL_CG"] = 2] = "WEIGHT_TO_LATERAL_CG";
  WbEnvelopeCollectionType[WbEnvelopeCollectionType["WEIGHT_TO_LATERAL_MOMENT"] = 3] = "WEIGHT_TO_LATERAL_MOMENT";
  WbEnvelopeCollectionType[WbEnvelopeCollectionType["LONGITUDINAL_CG_TO_LATERAL_CG"] = 4] = "LONGITUDINAL_CG_TO_LATERAL_CG";
})(WbEnvelopeCollectionType || (WbEnvelopeCollectionType = {}));
var WbFuelBurnStrategy;
(function (WbFuelBurnStrategy) {
  WbFuelBurnStrategy[WbFuelBurnStrategy["PROPORTIONAL"] = 0] = "PROPORTIONAL";
  WbFuelBurnStrategy[WbFuelBurnStrategy["SMALLEST_TANK_FIRST"] = 1] = "SMALLEST_TANK_FIRST";
  WbFuelBurnStrategy[WbFuelBurnStrategy["LARGEST_TANK_FIRST"] = 2] = "LARGEST_TANK_FIRST";
})(WbFuelBurnStrategy || (WbFuelBurnStrategy = {}));
var WbVolumeUnit;
(function (WbVolumeUnit) {
  WbVolumeUnit[WbVolumeUnit["GALLONS"] = 0] = "GALLONS";
  WbVolumeUnit[WbVolumeUnit["LITERS"] = 1] = "LITERS";
})(WbVolumeUnit || (WbVolumeUnit = {}));
var WbWeightUnit;
(function (WbWeightUnit) {
  WbWeightUnit[WbWeightUnit["LB"] = 0] = "LB";
  WbWeightUnit[WbWeightUnit["KG"] = 1] = "KG";
})(WbWeightUnit || (WbWeightUnit = {}));
var SettingType;
(function (SettingType) {
  SettingType["SETTING_VALUE_BOOLEAN"] = "SETTING_VALUE_BOOLEAN";
  SettingType["SETTING_VALUE_INTEGER"] = "SETTING_VALUE_INTEGER";
  SettingType["SETTING_VALUE_DOUBLE"] = "SETTING_VALUE_DOUBLE";
  SettingType["SETTING_VALUE_SINGLE_SELECT"] = "SETTING_VALUE_SINGLE_SELECT";
  SettingType["SETTING_VALUE_MULTI_SELECT"] = "SETTING_VALUE_MULTI_SELECT";
  SettingType["SETTING_VALUE_AIRPORT"] = "SETTING_VALUE_AIRPORT";
  SettingType["SETTING_VALUE_AIRPORT_MULTI"] = "SETTING_VALUE_AIRPORT_MULTI";
  SettingType["SETTING_VALUE_WAYPOINT"] = "SETTING_VALUE_WAYPOINT";
  SettingType["SETTING_VALUE_WAYPOINT_MULTI"] = "SETTING_VALUE_WAYPOINT_MULTI";
  SettingType["SETTING_VALUE_JSON"] = "SETTING_VALUE_JSON";
})(SettingType || (SettingType = {}));
const isSpringErrorResponse = obj => {
  return 'error' in obj && 'error_description' in obj;
};
const obtainSpringRootCause = error => {
  try {
    error.error_description = error.error_description.replace(/&quot;/gi, '"');
    return obtainSpringRootCause(JSON.parse(error.error_description));
  } catch (e) {
    return error.error_description;
  }
};

/*
  Creates a data type from a provided string union such that every permutation of the strings is
  represented in the output type. E.g. Permutations<'A' | 'B'> produces "A" | "B" | "AB" | "BA"
*/

// Source: https://github.com/Tealium/integration-angular/blob/master/example/src/app/tealium/utag.service.ts
// Known spelling complaints that cannot be rectified: tealium, utag, ovrd, noview.
class TealiumUtagService {
  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(renderer) {
    this.renderer = renderer;
    this.scriptSrc = '';
    this.blocked = false;
    window.utag_cfg_ovrd = {
      noview: true
    };
    window.utag_data = {};
  }
  // Generic script loader with callback
  getScript(src, callback = () => {
    return;
  }) {
    const callbackObject = {
      callback
    };
    if (typeof src === 'undefined') {
      return;
    }
    const scriptElement = this.renderer.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.async = true;
    scriptElement.charset = 'utf-8';
    scriptElement.src = src;
    if (typeof callbackObject.callback === 'function') {
      scriptElement.addEventListener('load', () => {
        callbackObject.callback();
      }, false);
    }
    scriptElement.addEventListener('error', () => {
      this.blocked = true;
    });
    const firstScript = this.renderer.selectRootElement('script');
    this.renderer.insertBefore(this.renderer.parentNode(firstScript), scriptElement, firstScript);
  }
  // Config settings used to build the path to the utag.js file
  setConfig(config) {
    if (config.account !== undefined && config.profile !== undefined && config.environment !== undefined) {
      this.scriptSrc = `https://tags.tiqcdn.com/utag/${config.account}/${config.profile}/${config.environment}/utag.js`;
    }
  }
  // Data layer is optional set of key/value pairs
  track(tealiumEvent, data) {
    if (this.blocked) {
      return;
    }
    if (this.scriptSrc === '') {
      console.log('Tealium config not set.');
      return;
    }
    if (window.utag === undefined) {
      this.getScript(this.scriptSrc, () => {
        if (window.utag === undefined) {
          this.blocked = true;
          return;
        }
        window.utag.track(tealiumEvent, data);
      });
    } else {
      window.utag.track(tealiumEvent, data);
    }
  }
  view(data) {
    this.track('view', data);
  }
  link(data) {
    this.track('link', data);
  }
  static {
    this.ɵfac = function TealiumUtagService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TealiumUtagService)(i0.ɵɵinject(i0.Renderer2));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TealiumUtagService,
      factory: TealiumUtagService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TealiumUtagService, [{
    type: Injectable
  }], () => [{
    type: i0.Renderer2
  }], null);
})();
var ApiBaseUrl$2;
(function (ApiBaseUrl) {
  ApiBaseUrl["DEV"] = "https://avc-coredata-dev.garmin.com";
  ApiBaseUrl["TEST"] = "https://avc-coredata-test.garmin.com";
  ApiBaseUrl["STAGE"] = "https://avc-coredata-stg.garmin.com";
  ApiBaseUrl["PROD"] = "https://avc-coredata.garmin.com";
})(ApiBaseUrl$2 || (ApiBaseUrl$2 = {}));
const AIRCRAFT_SERVICE_CONFIG_TOKEN = new InjectionToken('AircraftServiceConfig');
class AircraftService {
  constructor() {
    this.http = inject(HttpClient);
    this.localStorageService = inject(LocalStorageService);
    this.config = inject(AIRCRAFT_SERVICE_CONFIG_TOKEN);
    this.readApiBaseUrl = `${ApiBaseUrl$2[this.config.environment]}/aircraftManagementReadService/aircraft`;
    this.writeApiBaseUrl = `${ApiBaseUrl$2[this.config.environment]}/aircraftManagementWriteService/aircraft`;
    if (this.config.readServiceLocationOverride != null) {
      this.readApiBaseUrl = `${this.config.readServiceLocationOverride}/aircraft`;
    }
    if (this.config.writeServiceLocationOverride != null) {
      this.writeApiBaseUrl = `${this.config.writeServiceLocationOverride}/aircraft`;
    }
  }
  getAllAircraft(includeMasterAircraftUuid = false, includeDeleted = false, specifiedCustomerGuid) {
    const customerGuid = specifiedCustomerGuid ?? this.localStorageService.get(LocalStorageKey.OAuthTokenResponse)?.customerId;
    if (customerGuid == null) {
      return throwError(() => new Error('Unable to retrieve customerGuid'));
    }
    return this.http.get(this.readApiBaseUrl, {
      params: {
        customerGuid,
        includeMasterAircraftUuid,
        includeDeleted
      }
    });
  }
  getAircraftById(aircraftUuid, includeMasterAircraftUuid = false) {
    return this.http.get(`${this.readApiBaseUrl}/${encodeURIComponent(aircraftUuid)}`, {
      params: {
        includeMasterAircraftUuid
      }
    });
  }
  searchAircraft(aircraftSearchRequest) {
    if (aircraftSearchRequest.customerGuid == null) {
      aircraftSearchRequest.customerGuid = this.localStorageService.get(LocalStorageKey.OAuthTokenResponse)?.customerId;
    }
    return this.http.post(`${this.readApiBaseUrl}/search`, aircraftSearchRequest);
  }
  promoteAircraft(aircraftUuid, promotionRequest) {
    return this.http.post(`${this.writeApiBaseUrl}/${encodeURIComponent(aircraftUuid)}/promotion/promote`, promotionRequest);
  }
  static {
    this.ɵfac = function AircraftService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AircraftService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AircraftService,
      factory: AircraftService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AircraftService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();

/*
  TO ADD A NEW CONTENT SET:
  1. Add a new entry to the ContentDescriptorsKey enum.
  2. Add a new entry to the CONTENT_DESCRIPTORS map using the entry from the previous step as the key.
  3. Create a new service that calls the getContent() method (similar to the getPopularRoutes() method,
     and make sure to utilize shareReplay(1) to cache the response properly.
*/
const CAPS_SERVICE_CONFIG_TOKEN = new InjectionToken('CapsServiceConfig');
var ContentDescriptorsKey;
(function (ContentDescriptorsKey) {
  ContentDescriptorsKey["POPULAR_ROUTES"] = "popularRoutes";
  ContentDescriptorsKey["DATIS"] = "datis";
})(ContentDescriptorsKey || (ContentDescriptorsKey = {}));
const CONTENT_DESCRIPTORS = new Map([[ContentDescriptorsKey.POPULAR_ROUTES, {
  contentType: 'RoutesPopular',
  contentDomain: 'GLOBAL'
}], [ContentDescriptorsKey.DATIS, {
  contentType: 'Datis',
  contentDomain: 'GLOBAL'
}]]);
class CapsService {
  constructor(http, config) {
    this.http = http;
    this.config = config;
    this.cachedAuthResponse$ = null;
    this.cachedContentSetResponse$ = null;
    this.CAPS_AUTH_REQUEST_URL = `${this.config.baseUrl}` + '?req=caps.contentAuth' + '&rspFmt=JSON' + `&subscriptionType=${encodeURIComponent(this.config.subscriptionType)}` + `&deviceId=${encodeURIComponent(this.config.deviceId)}` + `&customerId=${encodeURIComponent(this.config.customerId)}` + `&transactionKey=${encodeURIComponent(this.config.transactionKey)}`;
  }
  getPopularRoutes(departureAirport, destinationAirport) {
    return this.getContent(ContentDescriptorsKey.POPULAR_ROUTES, `/${departureAirport}/${destinationAirport}.json`).pipe(map(popularRoutesResponse => {
      return popularRoutesResponse != null ? popularRoutesResponse?.PopularRoutesResult.Popular : [];
    }));
  }
  getContent(contentDescriptorKey, contentUrlPath) {
    if (this.cachedAuthResponse$ == null) {
      this.cachedAuthResponse$ = this.getAuthResponse();
    }
    return forkJoin({
      contentBaseUrl: this.getContentBaseUrl(contentDescriptorKey),
      authResponse: this.cachedAuthResponse$
    }).pipe(switchMap(urlStrings => {
      if (urlStrings.contentBaseUrl != null) {
        return this.http.get(`${urlStrings.contentBaseUrl}` + `${contentUrlPath}` + `?contentAuthToken=${urlStrings.authResponse.CapsContentAuthResponse.Format.contentAuthToken}`);
      } else {
        return of(null);
      }
    }));
  }
  getAuthResponse() {
    return this.http.get(this.CAPS_AUTH_REQUEST_URL).pipe(shareReplay(1));
  }
  getContentBaseUrl(contentDescriptorKey) {
    if (this.cachedContentSetResponse$ == null) {
      this.cachedContentSetResponse$ = this.getContentSet();
    }
    return this.cachedContentSetResponse$.pipe(switchMap(contentSetResponse => {
      // If content set is expired, then refetch the data. Date.now() is converted from ms to s to match CAPS.
      if (contentSetResponse.ContentSet.expireTime < Date.now() / 1000) {
        this.cachedContentSetResponse$ = this.getContentSet();
        return this.cachedContentSetResponse$.pipe(switchMap(() => {
          return of(contentSetResponse.ContentSet.Content.find(contentDescriptor => contentDescriptor.ContentDescriptor.contentDomain === CONTENT_DESCRIPTORS.get(contentDescriptorKey)?.contentDomain && contentDescriptor.ContentDescriptor.contentType === CONTENT_DESCRIPTORS.get(contentDescriptorKey)?.contentType)?.urlBase ?? null);
        }));
      } else {
        return of(contentSetResponse.ContentSet.Content.find(contentDescriptor => contentDescriptor.ContentDescriptor.contentDomain === CONTENT_DESCRIPTORS.get(contentDescriptorKey)?.contentDomain && contentDescriptor.ContentDescriptor.contentType === CONTENT_DESCRIPTORS.get(contentDescriptorKey)?.contentType)?.urlBase ?? null);
      }
    }));
  }
  getContentSet() {
    if (this.cachedAuthResponse$ == null) {
      this.cachedAuthResponse$ = this.getAuthResponse();
    }
    return this.buildContentSetUrl(this.cachedAuthResponse$).pipe(switchMap(contentSetUrl => {
      return this.http.get(contentSetUrl);
    }), shareReplay(1));
  }
  buildContentSetUrl(authResponse) {
    return authResponse.pipe(map(response => {
      return `${response.CapsContentAuthResponse.Format.contentSetUrl}` + `?contentAuthToken=${response.CapsContentAuthResponse.Format.contentAuthToken}`;
    }));
  }
  static {
    this.ɵfac = function CapsService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CapsService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(CAPS_SERVICE_CONFIG_TOKEN));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CapsService,
      factory: CapsService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CAPS_SERVICE_CONFIG_TOKEN]
    }]
  }], null);
})();
const HTTP_INTERCEPTOR_CONFIG_TOKEN = new InjectionToken('HttpInterceptorConfig');
const OMIT_ACCEPT_HEADER = new HttpContextToken(() => false);
const OMIT_CLIENT_HEADER = new HttpContextToken(() => false);
const OMIT_CONTENT_TYPE_HEADER = new HttpContextToken(() => false);
const OMIT_ACTOR_ID_HEADER = new HttpContextToken(() => false);
class HttpInterceptorService {
  constructor(localStorageService, config) {
    this.localStorageService = localStorageService;
    this.config = config;
    this.CLIENT_ID_HEADER = 'X-garmin-client-id';
    this.ACTOR_ID_HEADER = 'X-garmin-actor-id';
  }
  intercept(request, next) {
    if (this.config.ignoreList != null && this.config.ignoreList.some(url => request.url.startsWith(url))) {
      return next.handle(request);
    }
    let headers = request.headers;
    const actorId = this.config.actorId ?? this.localStorageService.get(LocalStorageKey.OAuthTokenResponse)?.customerId;
    if (!request.context.get(OMIT_ACTOR_ID_HEADER) && actorId != null) {
      headers = headers.set(this.ACTOR_ID_HEADER, actorId);
    }
    if (!request.context.get(OMIT_CLIENT_HEADER)) {
      headers = headers.set(this.CLIENT_ID_HEADER, this.config.clientId);
    }
    if (!request.context.get(OMIT_ACCEPT_HEADER)) {
      if (!headers.has('Accept')) {
        headers = headers.set('Accept', 'application/json');
      } else if (headers.getAll('Accept')?.findIndex(value => value === 'application/json') === -1) {
        headers = headers.append('Accept', 'application/json');
      }
    }
    if (!headers.has('Content-Type') && !request.context.get(OMIT_CONTENT_TYPE_HEADER)) {
      headers = headers.set('Content-Type', 'application/json');
    }
    const oAuthToken = this.localStorageService.get(LocalStorageKey.OAuthTokenResponse)?.accessToken;
    if (oAuthToken != null) {
      headers = headers.set('Authorization', `Bearer ${oAuthToken}`);
    }
    const clonedRequest = request.clone({
      headers
    });
    return next.handle(clonedRequest);
  }
  static {
    this.ɵfac = function HttpInterceptorService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || HttpInterceptorService)(i0.ɵɵinject(LocalStorageService), i0.ɵɵinject(HTTP_INTERCEPTOR_CONFIG_TOKEN));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HttpInterceptorService,
      factory: HttpInterceptorService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HttpInterceptorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: LocalStorageService
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [HTTP_INTERCEPTOR_CONFIG_TOKEN]
    }]
  }], null);
})();
var ApiBaseUrl$1;
(function (ApiBaseUrl) {
  ApiBaseUrl["Dev"] = "https://flydev.garmin.com/fly-garmin/api";
  ApiBaseUrl["Test"] = "https://flytest.garmin.com/fly-garmin/api";
  ApiBaseUrl["Stage"] = "https://flystg.garmin.com/fly-garmin/api";
  ApiBaseUrl["Prod"] = "https://fly.garmin.com/fly-garmin/api";
})(ApiBaseUrl$1 || (ApiBaseUrl$1 = {}));
const JSON_SYNC_SERVICE_CONFIG_TOKEN = new InjectionToken('JsonSyncServiceConfig');
class JsonSyncService {
  constructor(http, config) {
    this.http = http;
    this.config = config;
    switch (this.config.environment) {
      case 'PROD':
        this.apiBaseUrl = ApiBaseUrl$1.Prod;
        break;
      case 'STAGE':
        this.apiBaseUrl = ApiBaseUrl$1.Stage;
        break;
      case 'TEST':
        this.apiBaseUrl = ApiBaseUrl$1.Test;
        break;
      default:
        this.apiBaseUrl = ApiBaseUrl$1.Dev;
        break;
    }
  }
  getWeightAndBalanceProfile(uuid) {
    const headers = new HttpHeaders().set('Accept', 'application/vnd.garmin.fly.weight-balance+json;v=2');
    return this.http.get(`${this.apiBaseUrl}/weight-balance/individual/profiles/${uuid}/`, {
      headers,
      context: new HttpContext().set(OMIT_CLIENT_HEADER, true)
    });
  }
  static {
    this.ɵfac = function JsonSyncService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || JsonSyncService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(JSON_SYNC_SERVICE_CONFIG_TOKEN));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: JsonSyncService,
      factory: JsonSyncService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JsonSyncService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [JSON_SYNC_SERVICE_CONFIG_TOKEN]
    }]
  }], null);
})();

/**
 * Cached items from session storage:
 * (1) are specific to each tab
 * (2) are only cleared when the page is closed
 * (3) remain when page is refreshed as long as the page/tab session is active
 */
class SessionStorageService {
  get(key) {
    return JSON.parse(sessionStorage.getItem(key) ?? 'null');
  }
  set(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
  delete(key) {
    sessionStorage.removeItem(key);
  }
  clear() {
    sessionStorage.clear();
  }
  static {
    this.ɵfac = function SessionStorageService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SessionStorageService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SessionStorageService,
      factory: SessionStorageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SessionStorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
var ReadApiBaseUrl$1;
(function (ReadApiBaseUrl) {
  ReadApiBaseUrl["Dev"] = "https://avc-coredata-dev.garmin.com/customerReadService/customer-settings-freeform";
  ReadApiBaseUrl["Test"] = "https://avc-coredata-test.garmin.com/customerReadService/customer-settings-freeform";
  ReadApiBaseUrl["Stage"] = "https://avc-coredata-stg.garmin.com/customerReadService/customer-settings-freeform";
  ReadApiBaseUrl["Prod"] = "https://avc-coredata.garmin.com/customerReadService/customer-settings-freeform";
})(ReadApiBaseUrl$1 || (ReadApiBaseUrl$1 = {}));
var WriteApiBaseUrl$1;
(function (WriteApiBaseUrl) {
  WriteApiBaseUrl["Dev"] = "https://avc-coredata-dev.garmin.com/customerWriteService/customer-settings-freeform";
  WriteApiBaseUrl["Test"] = "https://avc-coredata-test.garmin.com/customerWriteService/customer-settings-freeform";
  WriteApiBaseUrl["Stage"] = "https://avc-coredata-stg.garmin.com/customerWriteService/customer-settings-freeform";
  WriteApiBaseUrl["Prod"] = "https://avc-coredata.garmin.com/customerWriteService/customer-settings-freeform";
})(WriteApiBaseUrl$1 || (WriteApiBaseUrl$1 = {}));
const CUSTOMER_FREEFORM_SETTING_SERVICE_CONFIG_TOKEN = new InjectionToken('CustomerFreeformSettingServiceConfig');
class CustomerFreeformSettingService {
  constructor(http, config) {
    this.http = http;
    this.config = config;
    switch (this.config.environment) {
      case 'PROD':
        this.readApiBaseUrl = ReadApiBaseUrl$1.Prod;
        this.writeApiBaseUrl = WriteApiBaseUrl$1.Prod;
        break;
      case 'STAGE':
        this.readApiBaseUrl = ReadApiBaseUrl$1.Stage;
        this.writeApiBaseUrl = WriteApiBaseUrl$1.Stage;
        break;
      case 'TEST':
        this.readApiBaseUrl = ReadApiBaseUrl$1.Test;
        this.writeApiBaseUrl = WriteApiBaseUrl$1.Test;
        break;
      default:
        this.readApiBaseUrl = ReadApiBaseUrl$1.Dev;
        this.writeApiBaseUrl = WriteApiBaseUrl$1.Dev;
        break;
    }
  }
  create(customerSetting) {
    return this.http.post(this.writeApiBaseUrl, customerSetting);
  }
  get(uuid) {
    this.checkUuid(uuid);
    return this.http.get(`${this.readApiBaseUrl}/${uuid}`);
  }
  getAll(customerGuid) {
    return this.http.get(customerGuid != null ? `${this.readApiBaseUrl}?customerGuid=${customerGuid}` : this.readApiBaseUrl);
  }
  update(uuid, customerSetting) {
    this.checkUuid(uuid);
    return this.http.put(`${this.writeApiBaseUrl}/${uuid}`, customerSetting);
  }
  delete(uuid) {
    this.checkUuid(uuid);
    return this.http.delete(`${this.writeApiBaseUrl}/${uuid}`);
  }
  checkUuid(uuid) {
    if (!isValidUuid(uuid)) {
      throw new Error('Invalid UUID');
    }
  }
  static {
    this.ɵfac = function CustomerFreeformSettingService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CustomerFreeformSettingService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(CUSTOMER_FREEFORM_SETTING_SERVICE_CONFIG_TOKEN));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CustomerFreeformSettingService,
      factory: CustomerFreeformSettingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomerFreeformSettingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CUSTOMER_FREEFORM_SETTING_SERVICE_CONFIG_TOKEN]
    }]
  }], null);
})();
var ReadApiBaseUrl;
(function (ReadApiBaseUrl) {
  ReadApiBaseUrl["Dev"] = "https://avc-coredata-dev.garmin.com/customerReadService/customer-settings-option-value";
  ReadApiBaseUrl["Test"] = "https://avc-coredata-test.garmin.com/customerReadService/customer-settings-option-value";
  ReadApiBaseUrl["Stage"] = "https://avc-coredata-stg.garmin.com/customerReadService/customer-settings-option-value";
  ReadApiBaseUrl["Prod"] = "https://avc-coredata.garmin.com/customerReadService/customer-settings-option-value";
})(ReadApiBaseUrl || (ReadApiBaseUrl = {}));
var WriteApiBaseUrl;
(function (WriteApiBaseUrl) {
  WriteApiBaseUrl["Dev"] = "https://avc-coredata-dev.garmin.com/customerWriteService/customer-settings-option-value";
  WriteApiBaseUrl["Test"] = "https://avc-coredata-test.garmin.com/customerWriteService/customer-settings-option-value";
  WriteApiBaseUrl["Stage"] = "https://avc-coredata-stg.garmin.com/customerWriteService/customer-settings-option-value";
  WriteApiBaseUrl["Prod"] = "https://avc-coredata.garmin.com/customerWriteService/customer-settings-option-value";
})(WriteApiBaseUrl || (WriteApiBaseUrl = {}));
const CUSTOMER_OPTION_VALUE_SETTING_SERVICE_CONFIG_TOKEN = new InjectionToken('CustomerOptionValueSettingServiceConfig');
class CustomerOptionValueSettingService {
  constructor(http, config) {
    this.http = http;
    this.config = config;
    switch (this.config.environment) {
      case 'PROD':
        this.readApiBaseUrl = ReadApiBaseUrl.Prod;
        this.writeApiBaseUrl = WriteApiBaseUrl.Prod;
        break;
      case 'STAGE':
        this.readApiBaseUrl = ReadApiBaseUrl.Stage;
        this.writeApiBaseUrl = WriteApiBaseUrl.Stage;
        break;
      case 'TEST':
        this.readApiBaseUrl = ReadApiBaseUrl.Test;
        this.writeApiBaseUrl = WriteApiBaseUrl.Test;
        break;
      default:
        this.readApiBaseUrl = ReadApiBaseUrl.Dev;
        this.writeApiBaseUrl = WriteApiBaseUrl.Dev;
        break;
    }
  }
  create(customerSetting) {
    return this.http.post(this.writeApiBaseUrl, customerSetting);
  }
  get(uuid) {
    this.checkUuid(uuid);
    return this.http.get(`${this.readApiBaseUrl}/${uuid}`);
  }
  getAll(customerGuid) {
    return this.http.get(customerGuid != null ? `${this.readApiBaseUrl}?customerGuid=${customerGuid}` : this.readApiBaseUrl);
  }
  update(uuid, customerSetting) {
    this.checkUuid(uuid);
    return this.http.put(`${this.writeApiBaseUrl}/${uuid}`, customerSetting);
  }
  delete(uuid) {
    this.checkUuid(uuid);
    return this.http.delete(`${this.writeApiBaseUrl}/${uuid}`);
  }
  checkUuid(uuid) {
    if (!isValidUuid(uuid)) {
      throw new Error('Invalid UUID');
    }
  }
  static {
    this.ɵfac = function CustomerOptionValueSettingService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CustomerOptionValueSettingService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(CUSTOMER_OPTION_VALUE_SETTING_SERVICE_CONFIG_TOKEN));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CustomerOptionValueSettingService,
      factory: CustomerOptionValueSettingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomerOptionValueSettingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [CUSTOMER_OPTION_VALUE_SETTING_SERVICE_CONFIG_TOKEN]
    }]
  }], null);
})();
var ApiBaseUrl;
(function (ApiBaseUrl) {
  ApiBaseUrl["Dev"] = "https://avc-coredata-dev.garmin.com/customerReadService/settings";
  ApiBaseUrl["Test"] = "https://avc-coredata-test.garmin.com/customerReadService/settings";
  ApiBaseUrl["Stage"] = "https://avc-coredata-stg.garmin.com/customerReadService/settings";
  ApiBaseUrl["Prod"] = "https://avc-coredata.garmin.com/customerReadService/settings";
})(ApiBaseUrl || (ApiBaseUrl = {}));
const SETTING_SERVICE_CONFIG_TOKEN = new InjectionToken('SettingServiceConfig');
class SettingService {
  constructor(http, config) {
    this.http = http;
    this.config = config;
    switch (this.config.environment) {
      case 'PROD':
        this.apiBaseUrl = ApiBaseUrl.Prod;
        break;
      case 'STAGE':
        this.apiBaseUrl = ApiBaseUrl.Stage;
        break;
      case 'TEST':
        this.apiBaseUrl = ApiBaseUrl.Test;
        break;
      default:
        this.apiBaseUrl = ApiBaseUrl.Dev;
        break;
    }
  }
  getSettingSummaries(category) {
    return this.http.get(`${this.apiBaseUrl}/summarize`, category != null ? {
      params: new HttpParams().append('category', category)
    } : undefined);
  }
  static {
    this.ɵfac = function SettingService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SettingService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(SETTING_SERVICE_CONFIG_TOKEN));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SettingService,
      factory: SettingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SettingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [SETTING_SERVICE_CONFIG_TOKEN]
    }]
  }], null);
})();
const createElementResizeObserver = (element, debounceTimeMs = 50) => {
  return new Observable(subscriber => {
    const observer = new ResizeObserver(() => subscriber.next());
    const nativeElem = element.hasOwnProperty('nativeElement') ? element.nativeElement : element;
    observer.observe(nativeElem);
    return () => {
      observer.disconnect();
    };
  }).pipe(debounceTime(debounceTimeMs));
};
const isStringNonEmpty = input => {
  if (input == null || typeof input !== 'string') {
    return false;
  }
  return input.length > 0;
};
const isValidUuid = uuid => {
  if (uuid == null) {
    return false;
  }
  if (uuid.length !== 36) {
    return false;
  }
  return RegExp('[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}').test(uuid.toLowerCase());
};
var settingsJson = {
  "en-US": {
    "Latitude/Longitude": "hdd.dddd°",
    Distance: "Nautical Miles",
    "Runway Length": "Feet",
    "Arm CG": "Inches",
    Visibility: "Miles",
    Pressure: "Pound-force Per Square Inch",
    Altimeter: "Inches of Mercury",
    Velocity: "Knots",
    "Wind Speed": "Knots",
    "Default Temperature": "Degrees Fahrenheit",
    "Temperature Aloft": "Degrees Celsius",
    "UTC/Local": "Local",
    Format: "12-Hour",
    "Fluid Volume": "Gallons",
    Weight: "Pounds"
  }
};
var LOCALE;
(function (LOCALE) {
  LOCALE["US"] = "en-US";
})(LOCALE || (LOCALE = {}));
var State;
(function (State) {
  State[State["INITIALIZED"] = 0] = "INITIALIZED";
  State[State["LOADING"] = 1] = "LOADING";
  State[State["LOADED"] = 2] = "LOADED";
})(State || (State = {}));
const NO_SETTING_FOUND_MESSAGE = 'No setting found';
class CustomerSettingsProvider {
  constructor(locale, localStorageService, customerFreeformSettingsService, customerOptionValueSettingsService, settingsService) {
    this.localStorageService = localStorageService;
    this.customerFreeformSettingsService = customerFreeformSettingsService;
    this.customerOptionValueSettingsService = customerOptionValueSettingsService;
    this.settingsService = settingsService;
    this.settings = new Map();
    this.settingUuids = new Map();
    this.customerSettings = new Map();
    this.customerSettingSignals = new Map();
    this.localDefaults = new Map(Object.entries(settingsJson[locale] ?? settingsJson[LOCALE.US]));
    for (const [label, defaultSetting] of this.localDefaults) {
      this.customerSettingSignals.set(label, signal(defaultSetting));
    }
    this.state = State.INITIALIZED;
    this.loadSettings();
  }
  getCustomerSetting(label) {
    if (this.state === State.INITIALIZED) {
      this.loadSettings();
    }
    const customerSettingValue = this.customerSettingSignals.get(label);
    if (customerSettingValue != null) {
      return customerSettingValue;
    } else {
      throw new Error(NO_SETTING_FOUND_MESSAGE);
    }
  }
  setCustomerSetting(settingLabel, newValue) {
    if (this.state === State.INITIALIZED) {
      this.loadSettings();
    }
    const customerGuid = this.localStorageService.get(LocalStorageKey.OAuthTokenResponse)?.customerId;
    if (customerGuid == null) {
      throw new Error('Unable to retrieve customerGuid');
    }
    const uuid = this.settingUuids.get(settingLabel);
    if (uuid == null) {
      throw new Error(NO_SETTING_FOUND_MESSAGE);
    }
    const settingSummary = this.settings.get(uuid);
    if (settingSummary == null) {
      // This code block should not realistically be reached but just in case
      throw new Error(NO_SETTING_FOUND_MESSAGE);
    }
    const customerSetting = this.customerSettings.get(settingSummary.setting.uuid);
    let customerSettingSignal = this.customerSettingSignals.get(settingSummary.setting.label);
    if (customerSettingSignal == null) {
      // In case we have a setting that doesn't have a default
      customerSettingSignal = signal('');
      this.customerSettingSignals.set(settingSummary.setting.label, customerSettingSignal);
    }
    if (settingSummary.settingOptionValues == null || settingSummary.settingOptionValues.length === 0) {
      (customerSetting == null ? this.customerFreeformSettingsService.create({
        customerGuid: customerGuid,
        settingUuid: settingSummary.setting.uuid,
        value: newValue
      }) : this.customerFreeformSettingsService.update(uuid, {
        uuid: customerSetting.uuid,
        customerGuid: customerGuid,
        settingUuid: settingSummary.setting.uuid,
        value: newValue
      })).subscribe(newCustomerSetting => {
        this.customerSettings.set(settingSummary.setting.uuid, newCustomerSetting);
        customerSettingSignal?.set(newCustomerSetting.value);
      });
    } else {
      const optionValueUuid = settingSummary.settingOptionValues.find(settingOptionValue => settingOptionValue.label === newValue)?.uuid;
      if (optionValueUuid == null) {
        throw new Error(`Invalid option value, valid options include: ${settingSummary.settingOptionValues.toString()}`);
      }
      (customerSetting == null ? this.customerOptionValueSettingsService.create({
        customerGuid: customerGuid,
        settingUuid: settingSummary.setting.uuid,
        settingOptionValueUuid: optionValueUuid
      }) : this.customerOptionValueSettingsService.update(customerSetting.uuid, {
        uuid: customerSetting.uuid,
        customerGuid: customerGuid,
        settingUuid: settingSummary.setting.uuid,
        settingOptionValueUuid: optionValueUuid
      })).subscribe(newCustomerSetting => {
        this.customerSettings.set(settingSummary.setting.uuid, newCustomerSetting);
        customerSettingSignal?.set(newValue);
      });
    }
  }
  deleteCustomerSetting(label) {
    if (this.state === State.INITIALIZED) {
      this.loadSettings();
    }
    const settingSummary = this.settings.get(this.settingUuids.get(label) ?? '');
    if (settingSummary == null) {
      throw new Error(NO_SETTING_FOUND_MESSAGE);
    }
    const customerSettingUuid = this.customerSettings.get(settingSummary.setting.uuid)?.uuid;
    if (customerSettingUuid != null) {
      let delObs;
      if (settingSummary.settingOptionValues == null || settingSummary.settingOptionValues.length === 0) {
        delObs = this.customerFreeformSettingsService.delete(customerSettingUuid);
      } else {
        delObs = this.customerOptionValueSettingsService.delete(customerSettingUuid);
      }
      delObs.subscribe(() => {
        this.customerSettings.delete(settingSummary.setting.uuid);
        const defaultSetting = this.localDefaults.get(label);
        if (defaultSetting != null) {
          this.customerSettingSignals.get(label)?.set(defaultSetting);
        } else {
          this.customerFreeformSettingsService.delete(label);
        }
      });
    }
  }
  getDefaultSetting(label) {
    const defaultSetting = this.localDefaults.get(label);
    if (defaultSetting != null) {
      return defaultSetting;
    }
    throw new Error(NO_SETTING_FOUND_MESSAGE);
  }
  loadSettings() {
    if (this.localStorageService.get(LocalStorageKey.OAuthTokenResponse) != null && this.state !== State.LOADING) {
      this.state = State.LOADING;
      // Fetch global setting summaries, and the customers existing settings
      forkJoin([this.settingsService.getSettingSummaries(), this.customerFreeformSettingsService.getAll(), this.customerOptionValueSettingsService.getAll()]).subscribe(([settingSummaries, customerFreeformSettings, customerOptionValuesSettings]) => {
        settingSummaries.forEach(settingSummary => {
          // Map settings summaries by label
          this.settings.set(settingSummary.setting.uuid, settingSummary);
          // Map setting uuids by label to identify settings
          this.settingUuids.set(settingSummary.setting.label, settingSummary.setting.uuid);
        });
        // Concat freeform settings and option values settings into a single array
        [...customerFreeformSettings, ...customerOptionValuesSettings].forEach(customerSetting => {
          // Map customer settings to by their corresponding setting uuid
          this.customerSettings.set(customerSetting.settingUuid, customerSetting);
          // Assign values to existing signals which should be using local defaults prior to running loadSettings()
          const settingSummary = this.settings.get(customerSetting.settingUuid);
          if (settingSummary != null) {
            // If this is an option value setting, fetch the raw text value from the corresponding setting summary, otherwise use the raw freeform text value
            const value = customerSetting.value ?? settingSummary.settingOptionValues.find(settingOptionValue => customerSetting.settingOptionValueUuid === settingOptionValue.uuid)?.label;
            if (value != null) {
              const customerSettingSignal = this.customerSettingSignals.get(settingSummary.setting.label);
              if (customerSettingSignal == null) {
                this.customerSettingSignals.set(settingSummary.setting.label, signal(value));
              } else {
                customerSettingSignal.set(value);
              }
            }
          }
        });
      });
      this.state = State.LOADED;
    }
  }
  static {
    this.ɵfac = function CustomerSettingsProvider_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || CustomerSettingsProvider)(i0.ɵɵinject(LOCALE_ID), i0.ɵɵinject(LocalStorageService), i0.ɵɵinject(CustomerFreeformSettingService), i0.ɵɵinject(CustomerOptionValueSettingService), i0.ɵɵinject(SettingService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CustomerSettingsProvider,
      factory: CustomerSettingsProvider.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomerSettingsProvider, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: LOCALE,
    decorators: [{
      type: Inject,
      args: [LOCALE_ID]
    }]
  }, {
    type: LocalStorageService
  }, {
    type: CustomerFreeformSettingService
  }, {
    type: CustomerOptionValueSettingService
  }, {
    type: SettingService
  }], null);
})();
var TemperatureUnit;
(function (TemperatureUnit) {
  TemperatureUnit[TemperatureUnit["CELSIUS"] = 0] = "CELSIUS";
  TemperatureUnit[TemperatureUnit["FAHRENHEIT"] = 1] = "FAHRENHEIT";
})(TemperatureUnit || (TemperatureUnit = {}));
const toCelsius = (temp, fromUnit = TemperatureUnit.FAHRENHEIT) => {
  switch (fromUnit) {
    case TemperatureUnit.FAHRENHEIT:
      return (temp - 32) * (5 / 9);
    default:
      return temp;
  }
};
const toFahrenheit = (temp, fromUnit = TemperatureUnit.CELSIUS) => {
  switch (fromUnit) {
    case TemperatureUnit.CELSIUS:
      return temp * (9 / 5) + 32;
    default:
      return temp;
  }
};
const toCelsiusISA = (temp, fromUnit = TemperatureUnit.FAHRENHEIT) => {
  switch (fromUnit) {
    case TemperatureUnit.FAHRENHEIT:
      return temp * (5 / 9);
    default:
      return temp;
  }
};
const toFahrenheitISA = (temp, fromUnit = TemperatureUnit.CELSIUS) => {
  switch (fromUnit) {
    case TemperatureUnit.CELSIUS:
      return temp * (9 / 5);
    default:
      return temp;
  }
};
var VelocityUnit;
(function (VelocityUnit) {
  VelocityUnit[VelocityUnit["KILOMETERS_PER_HOUR"] = 0] = "KILOMETERS_PER_HOUR";
  VelocityUnit[VelocityUnit["MILES_PER_HOUR"] = 1] = "MILES_PER_HOUR";
  VelocityUnit[VelocityUnit["KNOTS"] = 2] = "KNOTS";
})(VelocityUnit || (VelocityUnit = {}));
const KILOMETERS_PER_MILE = 1.60934;
const KPH_PER_KNOT = 1.852;
const MPH_PER_KNOT = 1.151;
const toKilometersPerHour = (speed, fromUnit = VelocityUnit.KILOMETERS_PER_HOUR) => {
  switch (fromUnit) {
    case VelocityUnit.MILES_PER_HOUR:
      return speed * KILOMETERS_PER_MILE;
    case VelocityUnit.KNOTS:
      return speed * KPH_PER_KNOT;
    default:
      return speed;
  }
};
const toMilesPerHour = (speed, fromUnit = VelocityUnit.KILOMETERS_PER_HOUR) => {
  switch (fromUnit) {
    case VelocityUnit.KILOMETERS_PER_HOUR:
      return speed / KILOMETERS_PER_MILE;
    case VelocityUnit.MILES_PER_HOUR:
      return speed * MPH_PER_KNOT;
    default:
      return speed;
  }
};
const toKnots = (speed, fromUnit = VelocityUnit.KILOMETERS_PER_HOUR) => {
  switch (fromUnit) {
    case VelocityUnit.KILOMETERS_PER_HOUR:
      return speed / KPH_PER_KNOT;
    case VelocityUnit.MILES_PER_HOUR:
      return speed / MPH_PER_KNOT;
    default:
      return speed;
  }
};
var VolumeUnit;
(function (VolumeUnit) {
  VolumeUnit[VolumeUnit["LITERS"] = 0] = "LITERS";
  VolumeUnit[VolumeUnit["GALLONS"] = 1] = "GALLONS";
})(VolumeUnit || (VolumeUnit = {}));
const LITERS_PER_GALLON = 3.785;
const toLiters = (volume, fromUnit) => {
  switch (fromUnit) {
    case VolumeUnit.GALLONS:
      return volume * LITERS_PER_GALLON;
    default:
      return volume;
  }
};
const toGallons = (volume, fromUnit) => {
  switch (fromUnit) {
    case VolumeUnit.LITERS:
      return volume / LITERS_PER_GALLON;
    default:
      return volume;
  }
};
var WeightUnit;
(function (WeightUnit) {
  WeightUnit[WeightUnit["KILOGRAMS"] = 0] = "KILOGRAMS";
  WeightUnit[WeightUnit["POUNDS"] = 1] = "POUNDS";
})(WeightUnit || (WeightUnit = {}));
const POUNDS_PER_KILOGRAM = 2.2046;
const toKilograms = (weight, fromUnit) => {
  switch (fromUnit) {
    case WeightUnit.POUNDS:
      return weight / POUNDS_PER_KILOGRAM;
    default:
      return weight;
  }
};
const toPounds = (weight, fromUnit) => {
  switch (fromUnit) {
    case WeightUnit.KILOGRAMS:
      return weight * POUNDS_PER_KILOGRAM;
    default:
      return weight;
  }
};
var VolumeFlowUnit;
(function (VolumeFlowUnit) {
  VolumeFlowUnit[VolumeFlowUnit["KILOGRAMS_PER_HOUR"] = 0] = "KILOGRAMS_PER_HOUR";
  VolumeFlowUnit[VolumeFlowUnit["POUNDS_PER_HOUR"] = 1] = "POUNDS_PER_HOUR";
  VolumeFlowUnit[VolumeFlowUnit["LITERS_PER_HOUR"] = 2] = "LITERS_PER_HOUR";
  VolumeFlowUnit[VolumeFlowUnit["GALLONS_PER_HOUR"] = 3] = "GALLONS_PER_HOUR";
})(VolumeFlowUnit || (VolumeFlowUnit = {}));
const DEFAULT_POUNDS_PER_GALLON = 6.7;
const DEFAULT_KILOGRAMS_PER_LITER = .8;
const toKilogramsPerHour = (volumeFlow, fromUnit = VolumeFlowUnit.KILOGRAMS_PER_HOUR, density) => {
  switch (fromUnit) {
    case VolumeFlowUnit.POUNDS_PER_HOUR:
      return toKilograms(volumeFlow, WeightUnit.POUNDS);
    case VolumeFlowUnit.LITERS_PER_HOUR:
      return volumeFlow * (density ?? DEFAULT_KILOGRAMS_PER_LITER);
    case VolumeFlowUnit.GALLONS_PER_HOUR:
      return toLiters(volumeFlow, VolumeUnit.GALLONS) * (density ?? DEFAULT_KILOGRAMS_PER_LITER);
    default:
      return volumeFlow;
  }
};
const toPoundsPerHour = (volumeFlow, fromUnit = VolumeFlowUnit.KILOGRAMS_PER_HOUR, density) => {
  switch (fromUnit) {
    case VolumeFlowUnit.KILOGRAMS_PER_HOUR:
      return toPounds(volumeFlow, WeightUnit.KILOGRAMS);
    case VolumeFlowUnit.LITERS_PER_HOUR:
      return toGallons(volumeFlow, VolumeUnit.LITERS) * (density ?? DEFAULT_POUNDS_PER_GALLON);
    case VolumeFlowUnit.GALLONS_PER_HOUR:
      return volumeFlow * (density ?? DEFAULT_POUNDS_PER_GALLON);
    default:
      return volumeFlow;
  }
};
const toGallonsPerHour = (volumeFlow, fromUnit = VolumeFlowUnit.KILOGRAMS_PER_HOUR, density) => {
  switch (fromUnit) {
    case VolumeFlowUnit.KILOGRAMS_PER_HOUR:
      return toPounds(volumeFlow, WeightUnit.KILOGRAMS) / (density ?? DEFAULT_POUNDS_PER_GALLON);
    case VolumeFlowUnit.POUNDS_PER_HOUR:
      return volumeFlow / (density ?? DEFAULT_POUNDS_PER_GALLON);
    case VolumeFlowUnit.LITERS_PER_HOUR:
      return toGallons(volumeFlow, VolumeUnit.LITERS);
    default:
      return volumeFlow;
  }
};
const toLitersPerHour = (volumeFlow, fromUnit = VolumeFlowUnit.KILOGRAMS_PER_HOUR, density) => {
  switch (fromUnit) {
    case VolumeFlowUnit.LITERS_PER_HOUR:
      return volumeFlow / (density ?? DEFAULT_KILOGRAMS_PER_LITER);
    case VolumeFlowUnit.POUNDS_PER_HOUR:
      return toKilograms(volumeFlow, WeightUnit.POUNDS) / (density ?? DEFAULT_KILOGRAMS_PER_LITER);
    case VolumeFlowUnit.GALLONS_PER_HOUR:
      return toLiters(volumeFlow, VolumeUnit.GALLONS);
    default:
      return volumeFlow;
  }
};

/*
 * Public API Surface of avcloud-web-utils
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AIRCRAFT_SERVICE_CONFIG_TOKEN, AUTH_GUARD_CONFIG_TOKEN, AUTH_SERVICE_CONFIG_TOKEN, AircraftFuelMeasureType, AircraftService, AircraftWakeTurbulenceType, AuthGuard, AuthService, CAPS_SERVICE_CONFIG_TOKEN, CUSTOMER_FREEFORM_SETTING_SERVICE_CONFIG_TOKEN, CUSTOMER_OPTION_VALUE_SETTING_SERVICE_CONFIG_TOKEN, CUSTOMER_SERVICE_CONFIG_TOKEN, CapsService, CookieService, CustomerFreeformSettingService, CustomerOptionValueSettingService, CustomerService, CustomerSettingsProvider, DEFAULT_KILOGRAMS_PER_LITER, DEFAULT_POUNDS_PER_GALLON, HTTP_INTERCEPTOR_CONFIG_TOKEN, HttpInterceptorService, InternationalCountryCodeList, JSON_SYNC_SERVICE_CONFIG_TOKEN, JsonSyncService, LITERS_PER_GALLON, LocalStorageKey, LocalStorageService, OMIT_ACCEPT_HEADER, OMIT_ACTOR_ID_HEADER, OMIT_CLIENT_HEADER, OMIT_CONTENT_TYPE_HEADER, POUNDS_PER_KILOGRAM, PerfEffectType, PerfFuelMeasureType, PerfPhase, PerfPowerSettingType, PerfSampleParameterStrings, PerfTempType, SETTING_SERVICE_CONFIG_TOKEN, SUBSCRIPTION_GUARD_CONFIG_TOKEN, SUBSCRIPTION_SERVICE_CONFIG_TOKEN, SessionStorageService, SettingService, SettingType, SubscriptionService, TealiumUtagService, TemperatureUnit, VelocityUnit, VolumeFlowUnit, VolumeUnit, WbArmType, WbArmUnit, WbCargoStationType, WbEnvelopeCollectionAxis, WbEnvelopeCollectionType, WbFuelBurnStrategy, WbVolumeUnit, WbWeightUnit, WeightUnit, config, createElementResizeObserver, isGarminErrorResponse, isSpringErrorResponse, isStringNonEmpty, isValidUuid, obtainGarminRootCause, obtainSpringRootCause, subscriptionGuard, toCelsius, toCelsiusISA, toFahrenheit, toFahrenheitISA, toGallons, toGallonsPerHour, toKilograms, toKilogramsPerHour, toKilometersPerHour, toKnots, toLiters, toLitersPerHour, toMilesPerHour, toPounds, toPoundsPerHour };
