/**
 * FlightOrchestratorService
 * flightOrchestratorService
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-ProdExp-Cloud-Connectivity@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RoutePointV2Data } from './route-point-v2-data.model';


/**
 * List of all of the RoutePoints associated with the route. Generally a minimum of two route points.
 */
export interface RoutePoint { 
    /**
     * ID of the entity. Leave null if adding new entity
     */
    id?: string;
    /**
     * The date the entity was deleted. Does not need to be set by the client when deleting.
     */
    deletedAt?: string;
    /**
     * The date the entity was created. Leave null when adding a new entity.
     */
    createdAt?: string;
    /**
     * The date the entity was last updated. Leave null when adding a new entity. Do not change this value on the client-side when updating.
     */
    updatedAt?: string;
    /**
     * ID for Route entity which this RoutePoint belongs to
     */
    routeId: string;
    /**
     * Represents the sequence of this point along the route.
     */
    pointIndex: number;
    /**
     * The cruising altitude of the flight in hundreds of feet. This is the planned altitude after this point.
     */
    cruisingLevelInFeet?: number;
    /**
     * The aircraft cruising speed in knots. This is the planned speed after this point.
     */
    cruisingSpeedInKnots?: number;
    /**
     * ICAO code
     */
    icaoId?: string;
    /**
     * IATA code
     */
    iataId?: string;
    /**
     * NAA code
     */
    naaId?: string;
    /**
     * Whether this route point is an alternate destination
     */
    isAlt?: boolean;
    /**
     * Enum, one of [A, C, N, V, F, Ni, I, S, Ar, W, Si, St] (A=Airport, C=Coordinate, N=Nondirectional Radio Beacon (NDB), V=Very High Frequency Omni-directional Range (VOR), F=Fix Radial Distance, Ni=Non-ICAO, I=Intersection, S=SFRA Gate, Ar=Airway, W=Waypoint, Si=SID, St=STAR)
     */
    type: string;
    /**
     * Latitude
     */
    latitude?: number;
    /**
     * Longitude
     */
    longitude?: number;
    /**
     * Country code
     */
    countryCode: string;
    navaid?: string;
    /**
     * (If Fix-Radial) Bearing (to the route point) from true north in degrees
     */
    bearing?: number;
    /**
     * (If Fix-Radial) Distance (in nautical miles)
     */
    distance?: number;
    /**
     * Estimated amount of time (in minutes) to fly from the previous route point to this route point (or if this is an airway, the amount of time to traverse the airway).
     */
    estElapsedTime?: number;
    /**
     * FPL: Airway that will be taken for this airway entry point. Will specify the name of the airway and the airway exit.
     */
    airway?: string;
    /**
     * If the route type is a procedure (for example SID or STAR) then this will hold the name of that procedure.
     */
    procedureName?: string;
    /**
     * GarminPilot import: values between 0 and 1.5, percent power, ref AVSYNCSERV-29 and AVSYNCSERV-226
     */
    powerSetting?: number;
    /**
     * GarminPilot import: examples: VFR or IFR, ref AVSYNCSERV-29
     */
    routePointType?: string;
    /**
     * GarminPilot import: ref AVSYNCSERV-91
     */
    rpm?: number;
    /**
     * GarminPilot import: ref AVSYNCSERV-91
     */
    manifoldPressure?: number;
    /**
     * GarminPilot import: max 8 characters, ref AVSYNCSERV-118
     */
    fplPointType?: string;
    /**
     * GarminPilot import: max 4 characters, ref AVSYNCSERV-118
     */
    flightRules?: string;
    /**
     * GarminPilot import: ref AVSYNCSERV-118
     */
    verticalSpeed?: number;
    /**
     * GarminPilot import: includes perfInfo, sarPattern
     */
    jsonAttributes?: string;
    /**
     * GarminPilot import: ref AVSYNCSERV-111
     */
    takeoffHeadwindTailwind?: number;
    /**
     * GarminPilot import: Epoch Seconds  ref AVSYNCSERV-111
     */
    takeoffEtd?: number;
    /**
     * The position of the alternate destination. Either FIRST_ALT_DEST or SECOND_ALT_DEST. Null means this is not an alternate destination.
     */
    altDestPosition?: RoutePointAltDestPosition;
    routePointV2Data?: RoutePointV2Data;
}
export enum RoutePointAltDestPosition {
    FIRST_ALT_DEST = 'FIRST_ALT_DEST',
    SECOND_ALT_DEST = 'SECOND_ALT_DEST'
};



