import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FlyCardModule } from '@garmin-avcloud/avcloud-ui-common/card';
import { FlightIcons, FlyIconModule } from '@garmin-avcloud/avcloud-ui-common/icon';
import { AirportSearchResult } from '@shared/models/airport/search/airport-search-result.model';
import { ReplacePipe } from '@shared/pipes/replace/replace.pipe';
import { ExtendedTitleCasePipe } from '../../../pipes/extended-title-case/extended-title-case.pipe';

@Component({
  selector: 'pilot-airport-search-card',
  templateUrl: './airport-search-card.component.html',
  styleUrls: ['./airport-search-card.component.scss'],
  standalone: true,
  imports: [CommonModule, ExtendedTitleCasePipe, FlyCardModule, FlyIconModule, ReplacePipe]
})
export class AirportSearchCardComponent {
  @Input() airport: AirportSearchResult;
  @Output() readonly selected = new EventEmitter<AirportSearchCardComponent>();

  readonly FlightIcons = FlightIcons;

  isSelected = false;

  onSelect(): void {
    this.selected.emit(this);
    this.isSelected = true;
  }

  unselect(): void {
    this.isSelected = false;
  }
}
