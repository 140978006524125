<fly-card class="search-card"
  [selected]="isSelected"
  (selectedChange)="onSelect()"
  [padding]="'reduced'">
  <div class="wrapper">
    <div class="icon">
      <fly-icon [icon]="icon"></fly-icon>
    </div>
    <div class="data">
      <span>{{ waypoint.identifier }}</span>
      <!-- TODO: Add titlecase -->
      <span class="label">{{ displayedSubName }}</span>
    </div>
    <ng-container *ngIf="showNextArrow === true">
      <div class="spacer"></div>
      <div class="icon">
        <fly-icon [icon]="Icons.ArrowRight"></fly-icon>
      </div>
    </ng-container>
  </div>
</fly-card>
