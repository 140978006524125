/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ADBCycleResponse } from './adb-cycle-response.model';


export interface ARTCCInfo { 
    adbCycleInfo?: ADBCycleResponse;
    /**
     * Enum defining the input types for ARTCC info queries
     */
    requestType: ARTCCInfoRequestType;
    /**
     * ICAO Identifier given as the input to the query
     */
    identifier: string;
    /**
     * The ICAO identifier for the ARTCC requested in the Query
     */
    artccId: string;
    /**
     * Name of the ARTCC facility
     */
    centerName: string;
    /**
     * The AFTN address of the facility
     */
    aftnAddress: string;
    /**
     * Final time, in minutes before planned flight time, which changes can be made to the flight plan
     */
    flightPlanLockoutTime: number;
    /**
     * Final time, in minutes before planned flight time, which a flight plan can be deleted
     */
    flightPlanDeletionTime: number;
    /**
     * Earliest time, in minutes before planned flight time, which a flight plan can be filed
     */
    flightPlanEarliestTimeToFile: number;
    /**
     * Latest time, in minutes after planned flight time, which a flight plan can be filed
     */
    flightPlanLatestTimeToFile: number;
}
export enum ARTCCInfoRequestType {
    UNDEFINED = 'UNDEFINED',
    ARTCC = 'ARTCC',
    FIR = 'FIR',
    UIR = 'UIR',
    AIRPORT = 'AIRPORT'
};



