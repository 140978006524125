import * as i0 from '@angular/core';
import { Directive } from '@angular/core';
import { Subscription } from 'rxjs';
class ControlValueAccessorImpl {
  constructor() {
    this.onChangeSubs = new Subscription();
    this.disabled = false;
    this.touched = false;
    this.onTouched = () => {
      return;
    };
  }
  ngOnDestroy() {
    this.onChangeSubs.unsubscribe();
  }
  writeValue(newVal) {
    this.control.setValue(newVal);
  }
  registerOnChange(fn) {
    const sub = this.control.valueChanges.subscribe(fn);
    this.onChangeSubs.add(sub);
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  static {
    this.ɵfac = function ControlValueAccessorImpl_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ControlValueAccessorImpl)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ControlValueAccessorImpl
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ControlValueAccessorImpl, [{
    type: Directive
  }], null, null);
})();

/*
 * Public API Surface of avcloud-ui-common
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ControlValueAccessorImpl };
