/**
 * FlightRouteService
 * Service to calculate flight plan routes
 *
 * The version of the OpenAPI document: 1.0.0-SNAPSHOT
 * Contact: #AVN-ProdEng-Services-Merlin-Navin-Valli-Said@garmin.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Settings used when choosing SID/STAR procedures
 */
export interface ProcedureSettings { 
    /**
     * Flag indicates whether to avoid SIDs. Defaults to false. If the flag is set to true, SID specified in the route request will be ignored.
     */
    avoidSids: boolean;
    /**
     * Flag indicates whether to avoid STARs. Defaults to false. If the flag is set to true, STAR specified in the route request will be ignored.
     */
    avoidStars: boolean;
    /**
     * Flag indicates whether to allow RNP AR procedures. Defaults to false.
     */
    allowRnpAr: boolean;
    /**
     * Aircraft category
     */
    aircraftCategory: ProcedureSettingsAircraftCategory;
    /**
     * Aircraft type
     */
    aircraftType: ProcedureSettingsAircraftType;
    /**
     * Max velocity of aircraft (m/s). Used to filter out procedures that would require flying at faster velocities than this value.
     */
    maxVelocity: number;
    /**
     * Estimated climb rate (feet per minute). Used to estimate altitude of legs in the procedure to be able to select which wind layers to use.
     */
    climbRate: number;
    /**
     * Estimated descent rate (feet per minute). Used to estimate altitude of legs in the procedure to be able to select which wind layers to use.
     */
    descentRate: number;
}
export enum ProcedureSettingsAircraftCategory {
    A = 'CATEGORY_A',
    B = 'CATEGORY_B',
    C = 'CATEGORY_C',
    D = 'CATEGORY_D',
    E = 'CATEGORY_E',
    ALL = 'CATEGORY_ALL'
};
export enum ProcedureSettingsAircraftType {
    HELICOPTER = 'HELICOPTER',
    JET = 'JET',
    PISTON = 'PISTON',
    MILITARY = 'MILITARY',
    TURBOPROP = 'TURBOPROP',
    ALL = 'ALL'
};



