import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flySplit'
})
export class SplitPipe implements PipeTransform {

  transform(value: string, separator: any): string[] {
    return value?.split(separator);
  }
}
