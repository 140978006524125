import { Pipe, PipeTransform } from '@angular/core';
import { toFahrenheit, toCelsius, toFahrenheitISA, toCelsiusISA } from '@garmin-avcloud/avcloud-web-utils';

export const Unit = {
  // DISTANCE
  STATUTE_MILE: 'STATUTE_MILE',
  NAUTICAL_MILE: 'NAUTICAL_MILE',
  FEET: 'FEET',
  INCHES: 'INCHES',
  MILLIMETER: 'MILLIMETER',
  METER: 'METER',
  KILOMETER: 'KILOMETER',

  // PRESSURE
  POUNDS_PER_SQUARE_INCH: 'POUNDS_PER_SQUARE_INCH',
  KILOPASCAL: 'KILOPASCAL',
  HECTOPASCAL: 'HECTOPASCAL',
  MILLIBAR: 'MILLIBAR',
  INCHES_OF_MERCURY: 'INCHES_OF_MERCURY',

  // FLUIDS
  GALLON: 'GALLON',
  LITER: 'LITER',

  // RATE
  GALLONS_PER_HOUR: 'GALLONS_PER_HOUR',
  LITERS_PER_HOUR: 'LITERS_PER_HOUR',
  POUNDS_PER_HOUR: 'POUNDS_PER_HOUR',
  KILOGRAMS_PER_HOUR: 'KILOGRAMS_PER_HOUR',

  // SPEED
  KNOTS: 'KNOTS',
  MILES_PER_HOUR: 'MILES_PER_HOUR',
  KILOMETERS_PER_HOUR: 'KILOMETERS_PER_HOUR',
  METERS_PER_SECOND: 'METERS_PER_SECOND',
  METERS_PER_MINUTE: 'METERS_PER_MINUTE',
  FEET_PER_MINUTE: 'FEET_PER_MINUTE',
  REVOLUTIONS_PER_MINUTE: 'REVOLUTIONS_PER_MINUTE',

  // TEMPERATURE
  DEGREE_CELSIUS: 'DEGREE_CELSIUS',
  DEGREE_FAHRENHEIT: 'DEGREE_FAHRENHEIT',
  KELVIN: 'KELVIN',

  // WEIGHT
  POUNDS: 'POUNDS',
  KILOGRAMS: 'KILOGRAMS',

  // MAGNETIC VARIANCE
  DEGREE: 'DEGREE',

  //TIME
  UTC: 'UTC',
  LOCAL: 'LOCAL',
  TWELVE_HOUR: 'TWELVE_HOUR',
  TWENTY_FOUR_HOUR: 'TWENTY_FOUR_HOUR',

  //DENSITY
  POUNDS_PER_GALLON: 'POUNDS_PER_GALLON',
  KILOGRAMS_PER_LITER: 'KILOGRAMS_PER_LITER'
} as const;

export const UnitAbbreviations = {
  // DISTANCE
  [Unit.STATUTE_MILE]: 'SM',
  [Unit.NAUTICAL_MILE]: 'NM',
  [Unit.FEET]: 'FT',
  [Unit.INCHES]: 'IN',
  [Unit.MILLIMETER]: 'MM',
  [Unit.METER]: 'M',
  [Unit.KILOMETER]: 'KM',

  // PRESSURE
  [Unit.POUNDS_PER_SQUARE_INCH]: 'PSI',
  [Unit.KILOPASCAL]: 'KPA',
  [Unit.HECTOPASCAL]: 'HPA',
  [Unit.MILLIBAR]: 'MB',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  [Unit.INCHES_OF_MERCURY]: 'IN', // as opposed to 'INHG'

  // FLUIDS
  [Unit.GALLON]: 'GAL',
  [Unit.LITER]: 'L',

  // SPEED
  [Unit.KNOTS]: 'KT',
  [Unit.MILES_PER_HOUR]: 'MPH',
  [Unit.KILOMETERS_PER_HOUR]: 'KPH',
  [Unit.METERS_PER_SECOND]: 'M/S',
  [Unit.METERS_PER_MINUTE]: 'M/M',
  [Unit.FEET_PER_MINUTE]: 'FPM',
  [Unit.REVOLUTIONS_PER_MINUTE]: 'RPM',

  // RATE
  [Unit.GALLONS_PER_HOUR]: 'GAL/HR',
  [Unit.LITERS_PER_HOUR]: 'L/HR',
  [Unit.POUNDS_PER_HOUR]: 'LB/HR',
  [Unit.KILOGRAMS_PER_HOUR]: 'KG/HR',

  // TEMPERATURE
  [Unit.DEGREE_CELSIUS]: '\u{00B0}C',
  [Unit.DEGREE_FAHRENHEIT]: '\u{00B0}F',
  [Unit.KELVIN]: 'K',

  // WEIGHT
  [Unit.POUNDS]: 'LB',
  [Unit.KILOGRAMS]: 'KG',

  // MAGNETIC VARIANCE
  [Unit.DEGREE]: '\u{00B0}',

  // TIME
  [Unit.UTC]: 'UTC',
  [Unit.LOCAL]: 'LCL',
  [Unit.TWELVE_HOUR]: '12H',
  [Unit.TWENTY_FOUR_HOUR]: '24H',

  //DENSITY
  [Unit.POUNDS_PER_GALLON]: 'LB/GAL',
  [Unit.KILOGRAMS_PER_LITER]: 'KG/L'
} as const;

// eslint-disable-next-line no-redeclare
export type Unit = (typeof Unit)[keyof typeof Unit];
// eslint-disable-next-line no-redeclare
export type UnitAbbreviations = (typeof UnitAbbreviations)[keyof typeof UnitAbbreviations];

/**
   Construct a functor which scales the input number by the specified
   factor.

   @param [in] scale    The factor to scale by.

   @return A unary functor which performs the specified scaling.
*/
const scaleBy = (scale: number): (value: number) => number => {
  return function (value: number): number {
    return value * scale;
  };
};

/**
   Construct a functor which rounds the input by the specified parameters.

   @param decimalPlaces Number of decimal places to round to
   @param floatValueCeiling Max value before the value is rounded to an integer

   @return A unary functor which performs the specified rounding
*/
const round = (decimalPlaces: number, floatValueCeiling?: number): (value: number) => number => {
  return function (value: number): number {
    if (floatValueCeiling != null && value > floatValueCeiling) {
      return Math.round(value);
    } else {
      const precisionCoefficient = Math.pow(10, decimalPlaces);
      return Math.round(value * precisionCoefficient) / precisionCoefficient;
    }
  };
};

const conversionFunctors: Map<string, (value: number) => number> = new Map<string, (value: number) => number>([
  // DISTANCE
  ['STATUTE_MILE->NAUTICAL_MILE',   scaleBy(0.8689762419007)],
  ['NAUTICAL_MILE->STATUTE_MILE',   scaleBy(1/0.8689762419007)],
  ['STATUTE_MILE->FEET',            scaleBy(5280)],
  ['FEET->STATUTE_MILE',            scaleBy(1/5280)],
  ['STATUTE_MILE->INCHES',          scaleBy(63360)],
  ['INCHES->STATUTE_MILE',          scaleBy(1/63360)],
  ['STATUTE_MILE->METER',           scaleBy(1609.344)],
  ['METER->STATUTE_MILE',           scaleBy(1/1609.344)],
  ['STATUTE_MILE->KILOMETER',       scaleBy(1.609344)],
  ['KILOMETER->STATUTE_MILE',       scaleBy(1/1.609344)],
  ['NAUTICAL_MILE->FEET',           scaleBy(6076.11548556431)],
  ['FEET->NAUTICAL_MILE',           scaleBy(1/6076.11548556431)],
  ['NAUTICAL_MILE->INCHES',         scaleBy(72913.38582677166)],
  ['INCHES->NAUTICAL_MILES',        scaleBy(1/72913.38582677166)],
  ['NAUTICAL_MILE->METER',          scaleBy(1852)],
  ['METER->NAUTICAL_MILE',          scaleBy(1/1852)],
  ['NAUTICAL_MILE->KILOMETER',      scaleBy(1.852)],
  ['KILOMETER->NAUTICAL_MILE',      scaleBy(1/1.852)],
  ['FEET->INCHES',                  scaleBy(12)],
  ['INCHES->FEET',                  scaleBy(1/12)],
  ['FEET->METER',                   scaleBy(1/3.280839895014)],
  ['METER->FEET',                   scaleBy(3.280839895014)],
  ['FEET->KILOMETER',               scaleBy(1/3280.839895014)],
  ['INCHES->METER',                 scaleBy(1/39.3700787401575)],
  ['METER->INCHES',                 scaleBy(39.3700787401575)],
  ['INCHES->KILOMETER',             scaleBy(1/39370.0787401575)],
  ['KILOMETER->INCHES',             scaleBy(39370.0787401575)],
  ['KILOMETER->FEET',               scaleBy(3280.839895014)],
  ['KILOMETER->METER',              scaleBy(1000)],
  ['METER->KILOMETER',              scaleBy(1/1000)],
  ['INCHES->MILLIMETER',            scaleBy(25.4)],
  ['MILLIMETER->INCHES',            scaleBy(1/25.4)],

  // PRESSURE
  ['HECTOPASCAL->INCHES_OF_MERCURY',            scaleBy(1/33.8638)],
  ['HECTOPASCAL->KILOPASCAL',                   scaleBy(1/10)],
  ['HECTOPASCAL->MILLIBAR',                     scaleBy(1)],
  ['HECTOPASCAL->POUNDS_PER_SQUARE_INCH',       scaleBy(1/68.9476)],
  ['INCHES_OF_MERCURY->HECTOPASCAL',            scaleBy(33.8638)],
  ['INCHES_OF_MERCURY->KILOPASCAL',             scaleBy(3.38638)],
  ['INCHES_OF_MERCURY->MILLIBAR',               scaleBy(33.8638)],
  ['INCHES_OF_MERCURY->POUNDS_PER_SQUARE_INCH', scaleBy(1/2.03602)],
  ['KILOPASCAL->HECTOPASCAL',                   scaleBy(10)],
  ['KILOPASCAL->INCHES_OF_MERCURY',             scaleBy(1/3.38638)],
  ['KILOPASCAL->MILLIBAR',                      scaleBy(10)],
  ['KILOPASCAL->POUNDS_PER_SQUARE_INCH',        scaleBy(1/6.89476)],
  ['MILLIBAR->HECTOPASCAL',                     scaleBy(1)],
  ['MILLIBAR->INCHES_OF_MERCURY',               scaleBy(1/33.8638)],
  ['MILLIBAR->KILOPASCAL',                      scaleBy(1/10)],
  ['MILLIBAR->POUNDS_PER_SQUARE_INCH',          scaleBy(1/68.9476)],
  ['POUNDS_PER_SQUARE_INCH->HECTOPASCAL',       scaleBy(68.9476)],
  ['POUNDS_PER_SQUARE_INCH->INCHES_OF_MERCURY', scaleBy(2.03602)],
  ['POUNDS_PER_SQUARE_INCH->KILOPASCAL',        scaleBy(6.89476)],
  ['POUNDS_PER_SQUARE_INCH->MILLIBAR',          scaleBy(68.9476)],

  // FUEL
  ['GALLON->LITER', scaleBy(3.785411784)],
  ['LITER->GALLON', scaleBy(1/3.785411784)],
  // These are only approximations for 100LL.
  ['GALLON->POUNDS', scaleBy(6.0175)],
  ['POUNDS->GALLON', scaleBy(1/6.0175)],
  ['GALLON->KILOGRAMS', scaleBy(2.7293)],
  ['KILOGRAMS->GALLON', scaleBy(1/2.7293)],
  ['POUNDS->LITER', scaleBy(0.629067185)],
  ['LITER->POUNDS', scaleBy(1/0.629067185)],
  ['KILOGRAMS->LITER', scaleBy(1/0.721)],
  ['LITER->KILOGRAMS', scaleBy(0.721)],
  //100LL
  ['GALLON->POUNDS/100LL', scaleBy(6.0175)],
  ['POUNDS->GALLON/100LL', scaleBy(1/6.0175)],
  ['GALLON->KILOGRAMS/100LL', scaleBy(2.7368)],
  ['KILOGRAMS->GALLON/100LL', scaleBy(1/2.7368)],
  ['POUNDS->LITER/100LL', scaleBy(0.629067185)],
  ['LITER->POUNDS/100LL', scaleBy(1/0.629067185)],
  ['KILOGRAMS->LITER/100LL', scaleBy(1/0.721)],
  ['LITER->KILOGRAMS/100LL', scaleBy(0.721)],
  //JET
  ['GALLON->POUNDS/JET', scaleBy(6.710)],
  ['POUNDS->GALLON/JET', scaleBy(1/6.710)],
  ['GALLON->KILOGRAMS/JET', scaleBy(3.044)],
  ['KILOGRAMS->GALLON/JET', scaleBy(1/3.044)],
  ['POUNDS->LITER/JET', scaleBy(0.564144826)],
  ['LITER->POUNDS/JET', scaleBy(1/0.564144826)],
  ['KILOGRAMS->LITER/JET', scaleBy(1/0.804)],
  ['LITER->KILOGRAMS/JET', scaleBy(0.804)],
  //MOGAS
  ['GALLON->POUNDS/MOGAS', scaleBy(6.301)],
  ['POUNDS->GALLON/MOGAS', scaleBy(1/6.301)],
  ['GALLON->KILOGRAMS/MOGAS', scaleBy(2.858)],
  ['KILOGRAMS->GALLON/MOGAS', scaleBy(1/2.858)],
  ['POUNDS->LITER/MOGAS', scaleBy(0.600763654)],
  ['LITER->POUNDS/MOGAS', scaleBy(1/0.600763654)],
  ['KILOGRAMS->LITER/MOGAS', scaleBy(1/0.755)],
  ['LITER->KILOGRAMS/MOGAS', scaleBy(0.755)],
  //DIESEL
  ['GALLON->POUNDS/DIESEL', scaleBy(6.943)],
  ['POUNDS->GALLON/DIESEL', scaleBy(1/6.943)],
  ['GALLON->KILOGRAMS/DIESEL', scaleBy(3.149)],
  ['KILOGRAMS->GALLON/DIESEL', scaleBy(1/3.149)],
  ['POUNDS->LITER/DIESEL', scaleBy(0.545212701)],
  ['LITER->POUNDS/DIESEL', scaleBy(1/0.545212701)],
  ['KILOGRAMS->LITER/DIESEL', scaleBy(1/0.832)],
  ['LITER->KILOGRAMS/DIESEL', scaleBy(0.832)],

  // RATE
  ['GALLONS_PER_HOUR->LITERS_PER_HOUR', scaleBy(3.785411784)],
  ['LITERS_PER_HOUR->GALLONS_PER_HOUR', scaleBy(1/3.785411784)],
  ['POUNDS_PER_HOUR->KILOGRAMS_PER_HOUR', scaleBy(1/2.2046226218)],
  ['KILOGRAMS_PER_HOUR->POUNDS_PER_HOUR', scaleBy(2.2046226218)],
  //100LL
  ['GALLONS_PER_HOUR->POUNDS_PER_HOUR/100LL', scaleBy(6.0175)],
  ['GALLONS_PER_HOUR->KILOGRAMS_PER_HOUR/100LL', scaleBy(2.7368)],
  ['LITERS_PER_HOUR->POUNDS_PER_HOUR/100LL', scaleBy(1/0.629067185)],
  ['LITERS_PER_HOUR->KILOGRAMS_PER_HOUR/100LL', scaleBy(0.721)],
  ['POUNDS_PER_HOUR->GALLONS_PER_HOUR/100LL', scaleBy(1/6.0175)],
  ['POUNDS_PER_HOUR->LITERS_PER_HOUR/100LL', scaleBy(0.629067185)],
  ['KILOGRAMS_PER_HOUR->GALLONS_PER_HOUR/100LL', scaleBy(1/2.7368)],
  ['KILOGRAMS_PER_HOUR->LITERS_PER_HOUR/100LL', scaleBy(1/0.721)],
  //JET
  ['GALLONS_PER_HOUR->POUNDS_PER_HOUR/JET', scaleBy(6.710)],
  ['GALLONS_PER_HOUR->KILOGRAMS_PER_HOUR/JET', scaleBy(3.044)],
  ['LITERS_PER_HOUR->POUNDS_PER_HOUR/JET', scaleBy(1/0.564144826)],
  ['LITERS_PER_HOUR->KILOGRAMS_PER_HOUR/JET', scaleBy(0.804)],
  ['POUNDS_PER_HOUR->GALLONS_PER_HOUR/JET', scaleBy(1/6.710)],
  ['POUNDS_PER_HOUR->LITERS_PER_HOUR/JET', scaleBy(0.564144826)],
  ['KILOGRAMS_PER_HOUR->GALLONS_PER_HOUR/JET', scaleBy(1/3.044)],
  ['KILOGRAMS_PER_HOUR->LITERS_PER_HOUR/JET', scaleBy(1/0.804)],
  //MOGAS
  ['GALLONS_PER_HOUR->POUNDS_PER_HOUR/MOGAS', scaleBy(6.301)],
  ['GALLONS_PER_HOUR->KILOGRAMS_PER_HOUR/MOGAS', scaleBy(2.858)],
  ['LITERS_PER_HOUR->POUNDS_PER_HOUR/MOGAS', scaleBy(1/0.600763654)],
  ['LITERS_PER_HOUR->KILOGRAMS_PER_HOUR/MOGAS', scaleBy(0.755)],
  ['POUNDS_PER_HOUR->GALLONS_PER_HOUR/MOGAS', scaleBy(1/6.301)],
  ['POUNDS_PER_HOUR->LITERS_PER_HOUR/MOGAS', scaleBy(0.600763654)],
  ['KILOGRAMS_PER_HOUR->GALLONS_PER_HOUR/MOGAS', scaleBy(1/2.858)],
  ['KILOGRAMS_PER_HOUR->LITERS_PER_HOUR/MOGAS', scaleBy(1/0.755)],
  //DIESEL
  ['GALLONS_PER_HOUR->POUNDS_PER_HOUR/DIESEL', scaleBy(6.943)],
  ['GALLONS_PER_HOUR->KILOGRAMS_PER_HOUR/DIESEL', scaleBy(3.149)],
  ['LITERS_PER_HOUR->POUNDS_PER_HOUR/DIESEL', scaleBy(1/0.545212701)],
  ['LITERS_PER_HOUR->KILOGRAMS_PER_HOUR/DIESEL', scaleBy(0.832)],
  ['POUNDS_PER_HOUR->GALLONS_PER_HOUR/DIESEL', scaleBy(1/6.943)],
  ['POUNDS_PER_HOUR->LITERS_PER_HOUR/DIESEL', scaleBy(0.545212701)],
  ['KILOGRAMS_PER_HOUR->GALLONS_PER_HOUR/DIESEL', scaleBy(1/3.149)],
  ['KILOGRAMS_PER_HOUR->LITERS_PER_HOUR/DIESEL', scaleBy(1/0.832)],

  // SPEED
  ['KILOMETERS_PER_HOUR->KNOTS',                scaleBy(250/463)],
  ['KILOMETERS_PER_HOUR->METERS_PER_SECOND',    scaleBy(5/18)],
  ['KILOMETERS_PER_HOUR->MILES_PER_HOUR',       scaleBy(1/1.609344)],
  ['KNOTS->KILOMETERS_PER_HOUR',                scaleBy(463/250)],
  ['KNOTS->METERS_PER_SECOND',                  scaleBy(463/900)],
  ['KNOTS->MILES_PER_HOUR',                     scaleBy(1.15078)],
  ['METERS_PER_SECOND->KILOMETERS_PER_HOUR',    scaleBy(18/5)],
  ['METERS_PER_SECOND->KNOTS',                  scaleBy(900/463)],
  ['METERS_PER_SECOND->MILES_PER_HOUR',         scaleBy(2.23694)],
  ['MILES_PER_HOUR->KILOMETERS_PER_HOUR',       scaleBy(1.609344)],
  ['MILES_PER_HOUR->KNOTS',                     scaleBy(1/1.15078)],
  ['MILES_PER_HOUR->METERS_PER_SECOND',         scaleBy(1/2.23694)],
  ['FEET_PER_MINUTE->METERS_PER_MINUTE',        scaleBy(1/3.280839895014)],
  ['METERS_PER_MINUTE->FEET_PER_MINUTE',        scaleBy(3.280839895014)],
  ['FEET_PER_MINUTE->METERS_PER_SECOND',        scaleBy(1/3.280839895014/60)],
  ['METERS_PER_SECOND->FEET_PER_MINUTE',        scaleBy(3.280839895014*60)],
  ['METERS_PER_MINUTE->METERS_PER_SECOND',      scaleBy(1/60)],
  ['METERS_PER_SECOND->METERS_PER_MINUTE',      scaleBy(60)],

  // TEMPERATURE
  ['DEGREE_CELSIUS->DEGREE_FAHRENHEIT', toFahrenheit],
  ['DEGREE_FAHRENHEIT->DEGREE_CELSIUS', toCelsius],
  ['DEGREE_CELSIUS->DEGREE_FAHRENHEIT/ISA', toFahrenheitISA],
  ['DEGREE_FAHRENHEIT->DEGREE_CELSIUS/ISA', toCelsiusISA],

  // WEIGHT
  ['POUNDS->KILOGRAMS', (lbs: number): number => lbs * 0.45359237],
  ['KILOGRAMS->POUNDS', (kg: number): number => kg * 2.2046226218488],

  // DENSITY
  ['POUNDS_PER_GALLON->KILOGRAMS_PER_LITER',  scaleBy(0.119826)],
  ['KILOGRAMS_PER_LITER->POUNDS_PER_GALLON', scaleBy(1/0.119826)]
]);

const roundingFunctors: Map<string, (value: number) => number> = new Map<string, (value: number) => number>([
  // DISTANCE
  [Unit.STATUTE_MILE, round(1, 100)],
  [Unit.NAUTICAL_MILE, round(1, 100)],
  [Unit.KILOMETER, round(1, 100)],
  [Unit.FEET, Math.round],
  [Unit.METER, Math.round],

  // VELOCITY
  [Unit.KNOTS, Math.round],
  [Unit.MILES_PER_HOUR, Math.round],
  [Unit.KILOMETERS_PER_HOUR, Math.round],
  [Unit.METERS_PER_SECOND, Math.round],

  // FLUID VOLUME
  [Unit.GALLON, round(1, 100)],
  [Unit.LITER, Math.round],

  // WEIGHT
  [Unit.POUNDS, round(4)],
  [Unit.KILOGRAMS,round(4)],

  // ARM/CG
  [Unit.INCHES, round(4)],
  [Unit.MILLIMETER, Math.round],

  // TEMPERATURE
  [Unit.DEGREE_CELSIUS, Math.round],
  [Unit.DEGREE_FAHRENHEIT, Math.round],

  // PRESSURE
  [Unit.INCHES_OF_MERCURY, round(1)],
  [Unit.MILLIBAR, round(1)],
  [Unit.HECTOPASCAL, round(1)],
  [Unit.KILOPASCAL, round(2)],
  [Unit.POUNDS_PER_SQUARE_INCH, round(2)],

  // DENSITY
  [Unit.POUNDS_PER_GALLON, round(2)],
  [Unit.KILOGRAMS_PER_LITER, round(2)]
]);

@Pipe({
  name: 'flyUnitConverter'
})
export class UnitConverterPipe implements PipeTransform {

  transform(value: number, sourceUnit: Unit, destUnit: Unit, ignoreSigFig: boolean = false, optionalParameter: string = ''): number {
    // Support no-op unit conversions.
    if (sourceUnit === destUnit) {
      return value;
    }
    const key = `${sourceUnit}->${destUnit}`;
    let conversionFunctor = conversionFunctors.get(key);
    if (optionalParameter !== '') {
      const optionalKey = key.concat('/', optionalParameter.toLocaleUpperCase());
      const conversionFunctorOptional = conversionFunctors.get(optionalKey);
      if (conversionFunctorOptional !== undefined) {
        // use the conversionFunctorOptional function instead
        conversionFunctor = conversionFunctorOptional;
      }
    }
    if (conversionFunctor === undefined) {
      throw new Error(`Unsupported unit conversion: ${key}`);
    }
    if (ignoreSigFig) {
      return conversionFunctor(value);
    }
    const roundingFunctor = roundingFunctors.get(destUnit) ?? Math.round; // Default integer values
    return roundingFunctor(conversionFunctor(value));
  }
}
